import { useState, useEffect } from 'react';
import { getLinksOwners } from '../helpers/links';
import { Owners, UserResponse } from '../models/User';

const useAllUsers = () => {
  const [owners, setOwners] = useState<Owners[]>([]);
  const [loading, setLoading] = useState(false);

  const getOwners = () => {
    setLoading(true);
    getLinksOwners()
      .then(res => {
        const resp = res.map(item => {
          const userResp = item as UserResponse;
          return {
            id: item.id,
            username:
              userResp.first_name !== ''
                ? `${userResp.first_name} ${userResp.last_name}`
                : '',
          };
        });
        setOwners(resp);
      })
      .catch(err => setOwners([]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getOwners();
  }, []);

  return { owners, loading };
};

export default useAllUsers;
