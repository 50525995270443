import React, { useState } from 'react';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SettingsOptions from '../../../components/Settings/SettingsOptions';
import { FlexBox } from '../../../components/tableItems';
import SearchInput from '../../../components/Search/SearchInput';
import AliasTable from './AliasTable/AliasTable';
import DrawerLinks from '../../DefaultView/DrawerLinks';
import AliasFilter from './AliasFilter/AliasFilter';

const Alias = () => {
  const [drawerFilter, setDrawerFilter] = useState(false);
  const updateDrawerFilter = () => setDrawerFilter(!drawerFilter);

  return (
    <div className="grid_container_settings">
      <DrawerLinks
        openCloseDrawer={updateDrawerFilter}
        openDrawer={drawerFilter}
      >
        <AliasFilter updateDrawerFilter={updateDrawerFilter} />
      </DrawerLinks>
      <div className="left_columns">
        <SettingsOptions option={2} />
      </div>
      <div className="right_columns">
        <Box>
          <Toolbar />
          <Box sx={{ pt: 4 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
            >
              <FlexBox>
                <IconButton
                  aria-label="filter"
                  disabled={false}
                  id="long-button"
                  sx={{
                    border: 1,
                    borderRadius: '10%',
                    bgcolor: '',
                    '&.Mui-disabled': {
                      bgcolor: '',
                    },
                  }}
                  aria-haspopup="true"
                  onClick={() => updateDrawerFilter()}
                >
                  <FilterListIcon
                    sx={{
                      color: 'black',
                      '&:hover': { color: 'green' },
                    }}
                  />
                </IconButton>
                <Typography ml={1} fontSize={17} fontWeight="bold">
                  Filters
                </Typography>
              </FlexBox>
              <Typography fontSize={24} fontWeight={900}>
                Alias
              </Typography>
              <div />
            </Box>

            <Box mt={4}>
              <Box pl={3} pr={4}>
                <SearchInput
                  placeholder="Search by alias"
                  id="search_url"
                  filter={false}
                  onKeyDown={() => {}}
                  onClear={() => {}}
                  value=""
                  onChangeNormal={query => {}}
                  disabled={false}
                />
              </Box>

              <Box mt={4}>
                <AliasTable />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Alias;
