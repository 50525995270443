import { create } from 'zustand';
import { LinkFormSidebarInterface } from '../../models/LinkForm';

interface LinkFormSidebarStore {
  formSidebar: LinkFormSidebarInterface;
  available: { disabled: boolean; disabledSaveEmail: boolean };
  updateSidebar: (obj: LinkFormSidebarInterface) => void;
  updateAvailable: (obj: {
    disabled: boolean;
    disabledSaveEmail: boolean;
  }) => void;
}

const useSidebar = create<LinkFormSidebarStore>(set => ({
  formSidebar: { type: 'read', openClaim: false },
  available: { disabled: true, disabledSaveEmail: true },
  updateSidebar: obj =>
    set({ formSidebar: { type: obj.type, openClaim: obj.openClaim ?? false } }),
  updateAvailable: obj => set({ available: obj }),
}));

export default useSidebar;
