/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useContext } from 'react';

import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClaimsLinksInt, ClaimsLinksObj } from '../../../models/Links';
import { DefaultCheckbox, TextInputField } from '../../inputs';
import { validateUrlInputs } from '../../../helpers/validateInputs';
import { BasicSelect, TextFormField } from '../FormInputs';
import { deleteClaimTags, getClaimTags } from '../../../helpers/claims';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { IS_USER_ADMIN } from '../../../constants/user';
import AutoCompleteSelect from '../../Filters/AutoCompleteSelect';

interface TargetingComponentProps {
  handleUpdateTargets: (value: ClaimsLinksInt[]) => void;
  handleErrors: (value: boolean) => void;
  urlsEdit: ClaimsLinksInt[];
  disableInputs: boolean;
}

const urlTypeOptions = [
  { value: 'Homepage', label: 'HomePage' },
  { value: 'Backpage', label: 'Backpage' },
  { value: 'Guide', label: 'Guide' },
  { value: 'News/Blog', label: 'News/Blog' },
  { value: 'Landing Page', label: 'Landing Page' },
  { value: 'Target Page', label: 'Target Page' },
];

const followTypeOptions = [
  { value: 'DoFollow Link', label: 'DoFollow Link' },
  { value: 'NoFollow Link', label: 'NoFollow Link' },
];

const TargetingComponent = ({
  handleUpdateTargets,
  handleErrors,
  urlsEdit,
  disableInputs,
}: TargetingComponentProps) => {
  const [tagOptions, setTagOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [newTags, setNewTags] = useState<string[]>([]);
  const animatedComponents = makeAnimated();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { UserContext } = useContext(Context) as UserContextType;
  const [urls, setUrls] = useState<ClaimsLinksInt[]>(urlsEdit);
  const [errorsArr, setErrorsArr] = useState<number[][]>([]);

  const handleUpdate = (
    arrId: number,
    key: keyof typeof ClaimsLinksObj,
    value: string | boolean,
  ) => {
    const copyObj = [...urls];
    if (key === 'is_target_page')
      copyObj[arrId] = { ...copyObj[arrId], is_target_page: value as boolean };
    else copyObj[arrId] = { ...copyObj[arrId], [key]: value as string };
    setUrls(copyObj);
  };
  const hasError = (index: number, id: number) => errorsArr[index].includes(id);

  const handleTextImageChange = (arrId: number, value: 'text' | 'image') => {
    const copyObj = [...urls];
    if (value === 'text') {
      copyObj[arrId] = { ...copyObj[arrId], type: 'text', value: '' };
    } else {
      copyObj[arrId] = { ...copyObj[arrId], type: 'image', value: '' };
    }
    setUrls(copyObj);
  };

  const addUrl = () => {
    const copyObj = [...urls];
    const copyErrors = [...errorsArr];
    copyErrors.push([]);
    copyObj.push(ClaimsLinksObj);
    setUrls(copyObj);
    setErrorsArr(copyErrors);
  };

  const removeURL = (id: number) => {
    const copyObj = [...urls];
    const copyErrors = [...errorsArr];
    copyObj.splice(id, 1);
    copyErrors.splice(id, 1);
    setUrls(copyObj);
    setErrorsArr(copyErrors);
  };

  useEffect(() => {
    handleErrors(errorsArr.flat().length > 0);
  }, [errorsArr]);

  useEffect(() => {
    handleUpdateTargets(urls);
  }, [urls]);

  useEffect(() => {
    setUrls(urlsEdit);
  }, [urlsEdit]);

  const fetchTags = async () => {
    try {
      const tags = await getClaimTags();
      setTagOptions(
        tags.map(item => {
          return { value: item.name, label: item.name };
        }),
      );
    } catch (err) {
      setTagOptions([]);
    }
  };

  useEffect(() => {
    setErrorsArr(new Array(urlsEdit.length).fill([]));
    fetchTags();
  }, []);

  const handleDeleteTag = async (arrId: number, removeTag: string) => {
    setLoadingDelete(true);
    const oldOptions = [...tagOptions];

    if (newTags.includes(removeTag)) {
      setTagOptions(oldOptions.filter(item => item.value !== removeTag));
      const newTagsOld = [...newTags];
      setNewTags(newTagsOld.filter(item => item !== removeTag));
      setLoadingDelete(false);
      return;
    }

    try {
      await deleteClaimTags(removeTag);
      setTagOptions(oldOptions.filter(item => item.value !== removeTag));
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleCreateTag = (
    arrId: number,
    newTag: string,
    tagsFromUser: string[],
  ) => {
    if (
      tagOptions.filter(
        item => item.value.toLowerCase() === newTag.toLowerCase(),
      ).length > 0
    )
      return;

    setNewTags([...newTags, newTag]);

    const newOptions = [...tagOptions, { label: newTag, value: newTag }];
    setTagOptions(newOptions);
    const userTags = [...tagsFromUser, newTag];
    handleUpdate(arrId, 'url_tags', userTags.join(','));
  };

  const { Option } = components;

  return (
    <>
      {errorsArr.length === urls.length && (
        <section>
          {urls.map((url, index) => (
            <Box
              key={index}
              sx={{
                boxSizing: 'border-box',
                borderRadius: '4px',
                marginBottom: 2,
                backgroundColor: '#F6F6F6',
              }}
            >
              {index === 0 && (
                <Box sx={{ padding: '8px 20px' }}>
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color="rgba(0, 0, 0, 0.8)"
                  >
                    URL {index + 1}
                  </Typography>
                </Box>
              )}
              {index !== 0 && (
                <Box
                  sx={{
                    padding: '8px 20px 2px 20px',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color="rgba(0, 0, 0, 0.8)"
                  >
                    URL {index + 1}
                  </Typography>
                  <IconButton
                    size="small"
                    disabled={disableInputs}
                    onClick={() => removeURL(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
              <Divider />
              <Box
                sx={{ padding: '8px 20px', marginBottom: 2.8, marginTop: 2 }}
              >
                <div>
                  <InputLabel
                    htmlFor={`url-${index}`}
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    Destination URL{' '}
                    <span className="required-fields_dot">*</span>
                  </InputLabel>
                  <TextInputField
                    value={url.destination_url}
                    name={`url-${index}`}
                    disabled={disableInputs}
                    error={hasError(index, 0)}
                    size="small"
                    onBlur={e => {
                      const copyArr = [...errorsArr];
                      if (e.target.value === '') {
                        if (copyArr[index].includes(0)) return;
                        copyArr[index].push(0);
                        setErrorsArr(copyArr);
                        return;
                      }
                      const validate = validateUrlInputs(
                        e.target.value,
                        0,
                        copyArr[index],
                      );
                      copyArr.splice(index, 1, validate);
                      setErrorsArr(copyArr);
                    }}
                    onChange={e => {
                      let copyURL = e.target.value;
                      if (copyURL !== '' && !/(http(s?)):\/\//i.test(copyURL)) {
                        copyURL = `https://${copyURL}`;
                      }
                      handleUpdate(index, 'destination_url', copyURL);
                    }}
                    fullWidth
                    placeholder="URL"
                    id={`url-${index}`}
                    helperText={hasError(index, 0) ? 'Insert a valid URL' : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {hasError(index, 0) && (
                            <CancelIcon fontSize="inherit" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Box marginTop={1}>
                  <FormControlLabel
                    label="Is Target Page?"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: 14,
                        color: '#000000',
                      },
                    }}
                    control={
                      <DefaultCheckbox
                        disabled={disableInputs}
                        id={`is_target-${index}`}
                        name={`is_target-${index}`}
                        size="medium"
                        checked={url.is_target_page}
                        onClick={() =>
                          handleUpdate(
                            index,
                            'is_target_page',
                            !url.is_target_page,
                          )
                        }
                      />
                    }
                  />
                </Box>
                <Box marginTop={1}>
                  <TextFormField
                    nameInput={`target-page-name-${index}`}
                    disabled={disableInputs}
                    label="Target Page Name"
                    error={false}
                    value={url.name}
                    onChange={value => handleUpdate(index, 'name', value)}
                    errorText=""
                    placeholder="Target Page Name"
                  />
                </Box>
                <Box marginTop={2}>
                  <InputLabel
                    htmlFor={`anchor-text-${index}`}
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    Anchor Text or Image{' '}
                    <span className="required-fields_dot">*</span>
                  </InputLabel>
                  <FormControlLabel
                    label="Text"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: 12,
                        color: '#000000',
                        fontWeight: 500,
                      },
                    }}
                    control={
                      <Radio
                        name={`radio-buttons-${index}`}
                        id={`radio-buttons-${index}`}
                        size="small"
                        value="text"
                        disabled={disableInputs}
                        checked={url.type === 'text'}
                        onChange={() => handleTextImageChange(index, 'text')}
                        color="default"
                      />
                    }
                  />
                  <FormControlLabel
                    label="Image"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: 12,
                        color: '#000000',
                        fontWeight: 500,
                      },
                    }}
                    control={
                      <Radio
                        name={`radio-buttons-${index}`}
                        id={`radio-buttons-${index}`}
                        size="small"
                        value="image"
                        checked={url.type === 'image'}
                        disabled={disableInputs}
                        onChange={() => handleTextImageChange(index, 'image')}
                        color="default"
                      />
                    }
                  />
                  <TextInputField
                    value={url.value}
                    name={`anchor-text-${index}`}
                    disabled={disableInputs}
                    error={hasError(index, 1)}
                    onBlur={e => {
                      const copyArr = [...errorsArr];
                      if (e.target.value === '') {
                        if (copyArr[index].includes(1)) return;
                        copyArr[index].push(1);
                        setErrorsArr(copyArr);
                      } else if (copyArr[index].includes(1)) {
                        copyArr[index] = copyArr[index].filter(
                          item => item !== 1,
                        );
                        setErrorsArr(copyArr);
                      }
                    }}
                    size="small"
                    onChange={e => handleUpdate(index, 'value', e.target.value)}
                    fullWidth
                    placeholder={
                      url.type === 'image' ? 'Image URL' : 'Anchor Text'
                    }
                    id={`anchor-text-${index}`}
                  />
                </Box>
                <Box marginTop={2}>
                  <BasicSelect
                    label="URL Type"
                    required
                    options={urlTypeOptions}
                    error={hasError(index, 2)}
                    nameInput="link-type"
                    onBlur={value => {
                      const copyArr = [...errorsArr];
                      if (value === '') {
                        if (copyArr[index].includes(2)) return;
                        copyArr[index].push(2);
                        setErrorsArr(copyArr);
                      } else if (copyArr[index].includes(2)) {
                        copyArr[index] = copyArr[index].filter(
                          item => item !== 2,
                        );
                        setErrorsArr(copyArr);
                      }
                    }}
                    disabled={disableInputs}
                    value={url.url_type}
                    onChange={(value: string) =>
                      handleUpdate(index, 'url_type', value)
                    }
                  />
                </Box>
                <Box marginTop={2}>
                  <InputLabel
                    htmlFor="select-tags"
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    URL Tags <span className="required-fields_dot">*</span>
                  </InputLabel>
                  {IS_USER_ADMIN.includes(UserContext.profile.role) && (
                    <CreatableSelect
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#D5D5D5',
                          primary: '#D5D5D5',
                          primary50: '#D5D5D5',
                        },
                      })}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: state.isFocused
                            ? '2px solid #D7D7D7'
                            : '2px solid #D7D7D7',
                          background: 'none',
                          color: '#373737',
                          cursor: 'pointer',
                          borderRadius: '5px',
                        }),
                      }}
                      isDisabled={loadingDelete || disableInputs}
                      options={tagOptions}
                      closeMenuOnSelect={false}
                      value={
                        url.url_tags && url.url_tags.length > 0
                          ? url.url_tags.split(',').map(item => {
                              return { value: item, label: item };
                            })
                          : []
                      }
                      components={{
                        Option: props => {
                          return (
                            <Box position="relative">
                              {!props.data.label.includes('Create') && (
                                <IconButton
                                  size="small"
                                  sx={{
                                    top: 5,
                                    position: 'absolute',
                                    right: 0,
                                  }}
                                  disabled={disableInputs}
                                  onClick={e => {
                                    e.preventDefault();
                                    handleDeleteTag(index, props.data.label);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                              <Option {...props}>
                                <Typography fontSize={15} ml={1}>
                                  {props.data.label}
                                </Typography>
                              </Option>
                            </Box>
                          );
                        },
                      }}
                      isMulti
                      isClearable
                      onChange={newValue => {
                        handleUpdate(
                          index,
                          'url_tags',
                          newValue
                            .map(item => {
                              return item.value;
                            })
                            .join(','),
                        );
                      }}
                      onCreateOption={inputValue =>
                        handleCreateTag(
                          index,
                          inputValue,
                          url.url_tags && url.url_tags.length > 0
                            ? url.url_tags.split(',')
                            : [],
                        )
                      }
                    />
                  )}
                  {!IS_USER_ADMIN.includes(UserContext.profile.role) && (
                    <AutoCompleteSelect
                      options={tagOptions.map(item => {
                        return { id: item.value, name: item.value };
                      })}
                      id="tags"
                      updateFiltersValue={(id: string, value: string) => {
                        handleUpdate(index, 'url_tags', value);
                      }}
                      clearAll={false}
                      maxTags={3}
                      disabledInput={disableInputs}
                      placeholder="Select tags"
                      value={
                        url.url_tags
                          ? url.url_tags
                              .split(',')
                              .filter(tag => tag.trim() !== '') // Handle empty or invalid tags
                              .map(tag => {
                                return { id: tag.trim(), name: tag.trim() };
                              })
                          : []
                      }
                    />
                    // <Select
                    //   theme={theme => ({
                    //     ...theme,
                    //     borderRadius: 0,
                    //     colors: {
                    //       ...theme.colors,
                    //       primary25: '#D5D5D5',
                    //       primary: '#D5D5D5',
                    //       primary50: '#D5D5D5',
                    //     },
                    //   })}
                    //   styles={{
                    //     control: (baseStyles, state) => ({
                    //       ...baseStyles,
                    //       border: state.isFocused
                    //         ? '2px solid #D7D7D7'
                    //         : '2px solid #D7D7D7',
                    //       background: 'none',
                    //       color: '#373737',
                    //       cursor: 'pointer',
                    //       borderRadius: '5px',
                    //     }),
                    //   }}
                    //   options={tagOptions}
                    //   closeMenuOnSelect={false}
                    //   value={
                    //     url.url_tags
                    //       ? url.url_tags
                    //           .split(',')
                    //           .filter(tag => tag.trim() !== '') // Handle empty or invalid tags
                    //           .map(tag => {
                    //             return { value: tag.trim(), label: tag.trim() };
                    //           })
                    //       : []
                    //   }
                    //   components={animatedComponents}
                    //   isMulti
                    //   isClearable
                    //   onChange={newValue => {
                    //     handleUpdate(
                    //       index,
                    //       'url_tags',
                    //       newValue
                    //         .map(item => {
                    //           return item.value;
                    //         })
                    //         .join(','),
                    //     );
                    //   }}
                    // />
                  )}
                </Box>
                <Box marginTop={2}>
                  <BasicSelect
                    label="Follow Type"
                    required
                    options={followTypeOptions}
                    error={hasError(index, 4)}
                    nameInput="follow-type"
                    onBlur={value => {
                      const copyArr = [...errorsArr];
                      if (value === '') {
                        if (copyArr[index].includes(4)) return;
                        copyArr[index].push(4);
                        setErrorsArr(copyArr);
                      } else if (copyArr[index].includes(4)) {
                        copyArr[index] = copyArr[index].filter(
                          item => item !== 4,
                        );
                        setErrorsArr(copyArr);
                      }
                    }}
                    disabled={disableInputs}
                    value={url.follow_type}
                    onChange={(value: string) =>
                      handleUpdate(index, 'follow_type', value)
                    }
                  />
                </Box>
                <Box marginTop={1}>
                  <FormControlLabel
                    label="Link Redirect"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: 14,
                        color: '#000000',
                      },
                    }}
                    control={
                      <DefaultCheckbox
                        disabled={disableInputs}
                        id={`link_redirect-${index}`}
                        name={`link_redirect-${index}`}
                        size="medium"
                        checked={url.is_link_redirect}
                        onClick={() =>
                          handleUpdate(
                            index,
                            'is_link_redirect',
                            !url.is_link_redirect,
                          )
                        }
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
          <IconButton
            disabled={disableInputs}
            onClick={addUrl}
            sx={{
              borderRadius: 3,
              border: 1,
              borderColor: '#6EBD0C',
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: '#BAF372',
              },
              '&:disabled': {
                backgroundColor: '#E9E9E9',
                borderColor: 'white',
              },
            }}
          >
            <AddIcon
              sx={{ color: disableInputs ? 'rgba(0, 0, 0, 0.8)' : '#6EBD0C' }}
            />
            <Typography
              color="rgba(0, 0, 0, 0.8)"
              fontWeight={600}
              fontSize={14}
              marginLeft={0.7}
            >
              Add URL
            </Typography>
          </IconButton>
        </section>
      )}
    </>
  );
};

export default TargetingComponent;
