/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  InputLabel,
  InputAdornment,
  CircularProgress,
  Typography,
  FormHelperText,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
  IconButton,
} from '@mui/material';
import relativetime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import dayjs from 'dayjs';
import React, { useState, useContext, useEffect, useRef } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import TabPanel from '../../Tab/TabPanel';
import TabParent from '../../Tab/TabParent';
import {
  OwnersSelectLinkFormField,
  SelectFormInput,
  StatusSelectLinkFormField,
} from '../FormInputs';
import { TemplateAudience, TemplatesRequest } from '../../../models/Templates';
import {
  fetchTemplatesFilter,
  getTemplateTranslate,
} from '../../../helpers/templates';
import useSites from '../../../customHooks/useSites';
import { isURL, validateUrlInputs } from '../../../helpers/validateInputs';
import BoxAddSettings from '../BoxAddSettings';
import {
  ContactsFormLink,
  ObjectLinks,
  TemplateLink,
} from '../../../models/Forms';
import {
  createLink,
  fetchAllContacts,
  rulesCannibalization,
  sendErrorSubmit,
  updateLink,
  validateLink,
} from '../../../helpers/links';
import { BasicArray, MessagesInt, SeverityType } from '../../../models/General';
import { TextInputField } from '../../inputs';
import TemplateTab from './TemplateTab';
import useLinkStatus from '../../../customHooks/useLinkStatus';
import StatusModal from '../StatusModal';
import useNotes from '../../../customHooks/useNotes';
import FormNotes from '../FormNotes';
import FeedbackMessage from '../../FeedbackMessage';
import useHistoryTab from '../../../customHooks/useHistoryTab';
import FormStatus from '../FormStatus';
import ConfirmEmail from './ConfirmEmail';
import ContactsComponent from './ContactsComponent';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import { IS_USER_ADMIN, ROLES_LINKS } from '../../../constants/user';
import useOwners from '../../../customHooks/useOwners';
import ClaimTab from './ClaimTab';
import {
  ClaimPostPutInt,
  LinkObject,
  PendingList,
  claimPostObjDefault,
} from '../../../models/Links';
import { createClaimLink } from '../../../helpers/claims';
import { GlobalContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import Spinner from '../../Loading/Spinner';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import DateInput from '../DateInput';
import {
  ContactsValuesType,
  contactValueDefault,
  contactValueDefaultError,
} from '../../../helpers/contacts';
import StatusClosed from './StatusClosed/StatusClosed';
import { FlexBox } from '../../tableItems';
import { LinkStatuses } from '../../../models/Status';
import AddNewContactModal from './AddNewContactModal';
import useSiteStore from '../../../stores/sites/sites';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'add' | 'edit';
  objEdit?: ObjectLinks;
  showMessage: (message: string, severity: SeverityType) => void;
  fetchLinks: () => void;
  tempsLinks: TemplateLink;
  siteTemp?: { id: number; name: string };
  audienceTemporal?: TemplateAudience;
  affinityTemporal?: TemplateAudience;
  modified: string;
  tab?: number;
  siteProspect?: number;
  userProspect?: number;
  audienceProspect?: number;
  affinityProspect?: number;
  linkProspect?: string;
  prospectId?: number;
  templateProspect?: number;
  openModalNewContact?: boolean;
  is_older_than_ninety_days?: boolean;
  is_older_than_thirty_days?: boolean;
  is_unsubscribed?: boolean;
  unsubscribed_date?: string;
}

const historyBlocked = ['linkbuilder', 'contractor'];
const historyAllowed = [
  'strategist',
  'manager',
  'administrator',
  'dev admin',
  'trainer',
];
const linksDuplicated = ['administrator', 'dev admin'];
const AddLink = ({
  setOpenDrawer,
  type,
  objEdit,
  showMessage,
  fetchLinks,
  modified,
  audienceTemporal,
  affinityTemporal,
  tempsLinks,
  tab,
  siteProspect,
  userProspect,
  audienceProspect,
  affinityProspect,
  linkProspect,
  prospectId,
  siteTemp,
  templateProspect,
  openModalNewContact,
  is_older_than_ninety_days,
  is_older_than_thirty_days,
  unsubscribed_date,
  is_unsubscribed,
}: Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativetime);
  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);

  const [openNewContact, setOpenNewContact] = useState(
    openModalNewContact ?? false,
  );
  const [allowOpenNewModalContact, setAllowOpenNewModalContact] = useState(
    openModalNewContact ?? false,
  );
  const openNewContactModal = () => setOpenNewContact(true);
  const closeNewContact = () => setOpenNewContact(false);
  const [doNotDeleteContact, setDoNotDeleteContact] = useState('');

  const [disabledSaveEmail, setDisabledSaveEmail] = useState(false);

  const [typeTab, setTypeTab] = useState(type);
  const [tabs, setTabs] = useState([
    { id: 0, label: 'Details' },
    { id: 1, label: 'Notes' },
    { id: 2, label: 'History' },
    { id: 3, label: 'Template', disabled: true },
    {
      id: 4,
      label: 'Claim',
    },
  ]);
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const { userSite, updateUserSite } = useSiteStore();

  const { UserContext } = useContext(Context) as UserContextType;
  const [disableLinkEdit, setDisableLinkEdit] = useState(false);
  const [assignedToThisLink, setAssignedToThisLink] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [renderTemplates, setRenderTemplates] = useState(0);
  const [cannibalizedId, setCannibalizedId] = useState<null | number>(null);
  const [messagesProps, setMessagesProps] = useState<MessagesInt>({
    message: '',
    severity: 'error',
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalOutreach, setOpenModalOutreach] = useState(false);
  const [ownerFromLink, setOwnerFromLink] = useState(
    objEdit ? objEdit.owner : userProspect ?? UserContext.id,
  );
  const [linkForm, setLinkForm] = useState<ObjectLinks>(
    objEdit ?? {
      url: linkProspect ?? '',
      status: 'pending',
      is_cannibalization: false,
      owner: userProspect ?? UserContext.id,
      assignee: userProspect ?? UserContext.id,
      site: siteProspect ?? userSite ?? 0,
      affinity: affinityProspect ?? 0,
      audience: audienceProspect ?? 0,
      template: templateProspect ?? 0,
      is_assigned_by_manager: false,
      contacts: [
        {
          name: '',
          email: '',
          is_form: false,
          status: 'open',
          is_default: true,
          validation_status: 'active',
        },
      ],
    },
  );
  const [contactsValues, setContactsValues] = useState<ContactsValuesType[]>(
    new Array(linkForm.contacts.length).fill(
      type === 'add' ? contactValueDefaultError : contactValueDefault,
    ),
  );
  const [errorsArr, setErrorsArr] = useState<number[]>(() => {
    const arr = [];
    if (objEdit) {
      if (objEdit.audience === 0) arr.push(4);
      if (objEdit.affinity === 0) arr.push(5);
      if (objEdit.template === 0) arr.push(6);
      return arr;
    }
    if (type === 'add' && prospectId) {
      if (audienceProspect && audienceProspect === 0) arr.push(4);
      if (affinityProspect && affinityProspect === 0) arr.push(5);
      if (templateProspect && templateProspect === 0) arr.push(6);
      return arr;
    }
    return [4, 5, 6];
  });
  const [tempUrl, setTempUrl] = useState(objEdit ? objEdit.url : '');

  const [audienceInput, setAudienceInput] = useState('');
  const updateAudienceInput = (value: string) => setAudienceInput(value);

  const [affinityInput, setAffinityInput] = useState('');
  const updateAffinityInput = (value: string) => setAffinityInput(value);

  const [templateInput, setTemplateInput] = useState('');
  const updateTemplateInput = (value: string) => {
    setTemplateInput(value);
  };

  const [loading, setLoading] = useState(false);
  const { statusOptions, loadingStatusOptions } = useLinkStatus();
  const [linkStatus, setLinkStatus] = useState<LinkStatuses[]>([]);
  const [errorLinkText, setErrorLinkText] = useState('');
  const [tabDrawer, setTabDrawer] = useState(tab ?? 0);
  const [originalState, setOriginalState] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [disabledContactButton, setDisabledContactButton] = useState(true);
  const [loadingLink, setLoadingLink] = useState(false);
  const [loadingSubmitClaimLink, setloadingSubmitClaimLink] = useState(false);
  const [validLink, setValidLink] = useState<boolean | string>('');
  const { owners, loading: loadingOwners } = useOwners({ withFields: true });

  const { sites: sitesOptions } = useSites({
    userId: UserContext.id,
    minimal: true,
  });
  const [loadingSites, setLoadingSites] = useState(true);

  const [sitesWithAll, setSitesWithAll] = useState<
    { id: number; name: string; status: string }[]
  >([]);
  const [pendingListObject, setPendingListObject] = useState<PendingList>();
  const [pendingListContacts, setPendingListContacts] = useState<
    ContactsFormLink[]
  >([]);
  const [loadingCannibalizationChange, setLoadingCannibalizationChange] =
    useState(false);

  const [audienceOptions, setAudienceOptions] = useState<TemplateAudience[]>(
    [],
  );
  const [affinityOptions, setAffinityOptions] = useState<TemplateAudience[]>(
    [],
  );
  const [templatesOpt, setTemplatesOpt] = useState<
    { id: number; name: string }[]
  >([]);

  const { notes, loadingNotes, refreshNotes } = useNotes({
    id: objEdit ? objEdit.id ?? 0 : 0,
    type: 'link',
  });
  const {
    history,
    isLoading: historyLoading,
    refreshHistory,
  } = useHistoryTab({
    id: objEdit ? objEdit.id ?? 0 : 0,
    type: 'link',
  });

  const [disabledStatus, setDisabledStatus] = useState(false);

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [render, setRender] = useState(0);

  const [disabledClaimSave, setDisabledClaimSave] = useState(true);
  const [claimObj, setClaimObj] =
    useState<ClaimPostPutInt>(claimPostObjDefault);

  const hasError = (id: number) => errorsArr.indexOf(id) !== -1;

  const updateContactsValues = (copyContactsValues: ContactsValuesType[]) =>
    setContactsValues(copyContactsValues);

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const fetchingAudiences = async () => {
    if (linkForm.site === 0) return [];
    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        linkForm.site,
        abortControllerAudience.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const fetchingAffinities = async () => {
    if (linkForm.site === 0 || linkForm.audience === 0) return [];
    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        linkForm.audience,
        linkForm.site,
        abortControllerAffinity.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const getAudiences = async () => {
    setLoadingAud(true);
    setAudienceOptions([]);
    setAffinityOptions([]);
    setTemplatesOpt([]);
    if (render !== 0 && linkForm.affinity === 0 && linkForm.template === 0) {
      cancelRequestAud();
      cancelRequestAff();
      cancelRequestTemplate();
    }

    const resp = await fetchingAudiences();

    if (
      audienceTemporal &&
      audienceTemporal.id !== 0 &&
      linkForm.audience !== 0 &&
      resp.findIndex(item => item.id === linkForm.audience) === -1
    ) {
      setAudienceOptions([...resp, audienceTemporal]);
    } else {
      setAudienceOptions(resp);
    }
    setLoadingAud(false);
  };

  const getAffinities = async () => {
    setLoadingAff(true);
    setAffinityOptions([]);
    setTemplatesOpt([]);
    cancelRequestAff();
    cancelRequestTemplate();

    const resp = await fetchingAffinities();

    if (
      affinityTemporal &&
      affinityTemporal.id !== 0 &&
      linkForm.affinity !== 0 &&
      resp.findIndex(item => item.id === linkForm.affinity) === -1
    ) {
      setAffinityOptions([...resp, affinityTemporal]);
    } else {
      setAffinityOptions(resp);
    }
    setLoadingAff(false);
  };

  useEffect(() => {
    const copyTabs = [...tabs];
    copyTabs[3].disabled = linkForm.template === 0 ?? false;
    setTabs(copyTabs);
  }, [linkForm.template, templatesOpt]);

  const getTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const resp = await fetchTemplatesFilter(
        linkForm.audience,
        linkForm.affinity,
        linkForm.site,
      );

      const tempsFilter = resp.filter(template => template.status === 'active');
      if (
        renderTemplates === 0 &&
        tempsLinks &&
        tempsLinks.id !== 0 &&
        !tempsFilter.find(item => item.id === tempsLinks.id)
      ) {
        tempsFilter.push(tempsLinks);
      }
      if (
        linkForm.template !== 0 &&
        !tempsFilter.find(item => item.id === linkForm.template) &&
        !errorsArr.includes(6)
      ) {
        const copyErrors = [...errorsArr];
        copyErrors.push(6);
        setErrorsArr(copyErrors);
      }
      setTemplatesOpt(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
      setRenderTemplates(1);
    } catch (err) {
      setTemplatesOpt([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const changingEntry = async (object: PendingList) => {
    const copyObj = [...errorsArr];
    const copyErrors = new Set(copyObj);
    setLoadingCannibalizationChange(true);
    setPendingListObject(object);
    const newObject = {
      audience: object.audience.id,
      affinity: object.affinity.id,
      template: object.template.id,
      site: object.site.id,
    };

    newObject.site = userSite;
    newObject.affinity = 0;
    newObject.audience = 0;
    newObject.template = 0;

    fetchAllContacts([object.id]).then(resp => {
      const contactsFromResponse = resp[0].contacts_links
        ? resp[0].contacts_links.map(item => {
            const copyObjItem = { ...item };
            if (copyObjItem.close_reason === null)
              delete copyObjItem.close_reason;
            return {
              ...copyObjItem,
              is_default:
                item.validation_status === 'inactive' ||
                item.status === 'site_closed' ||
                item.is_form
                  ? false
                  : item.is_default ?? false,
            };
          })
        : [];
      setLinkForm({
        ...linkForm,
        contacts: contactsFromResponse,
        status: object.status,
        owner: UserContext.id,
        assignee: UserContext.id,
        id: object.id,
        site: newObject.site,
        affinity: newObject.affinity,
        is_cannibalization: true,
        audience: newObject.audience,
        template: newObject.template,
      });
      setPendingListContacts(contactsFromResponse);
    });
    setTypeTab('edit');

    if (newObject.audience === 0) copyErrors.add(4);
    if (newObject.audience !== 0 && copyErrors.has(4)) copyErrors.delete(4);

    if (newObject.affinity === 0) copyErrors.add(5);
    if (newObject.affinity !== 0 && copyErrors.has(5)) copyErrors.delete(5);

    if (newObject.template === 0) copyErrors.add(6);
    if (newObject.template !== 0 && copyErrors.has(6)) copyErrors.delete(6);

    copyErrors.delete(0);

    setErrorsArr(Array.from(copyErrors));

    refreshNotes(object.id);
    refreshHistory(object.id);
    setTimeout(() => setLoadingCannibalizationChange(false), 1500);
  };

  useEffect(() => {
    if (
      linkForm.audience !== 0 &&
      linkForm.affinity !== 0 &&
      audienceOptions.length > 0 &&
      affinityOptions.length > 0
    )
      getTemplates();
  }, [linkForm.audience, linkForm.affinity, audienceOptions, affinityOptions]);

  const validateRulesCannibalization = async (value: string) => {
    if (value === '') {
      setErrorLinkText('');
      setValidLink(false);
      return;
    }
    if (!loadingLink) setLoadingLink(true);
    const copyErrors = [...errorsArr];
    try {
      const resp = await rulesCannibalization(
        linkForm.site,
        encodeURIComponent(value),
        UserContext.id,
        type === 'edit' ? linkForm.id : undefined,
      );
      if (resp.cannibalized_link_id !== null)
        setCannibalizedId(resp.cannibalized_link_id);
      else if (resp.cannibalized_link_id === null) setCannibalizedId(null);
      if (resp.valid) {
        setValidLink(true);
        if (
          resp.pending_list.length > 0 &&
          !pendingListObject &&
          type === 'add'
        ) {
          changingEntry(resp.pending_list[0]);
        } else if (copyErrors.includes(0)) {
          setErrorsArr(copyErrors.filter(item => item !== 0));
        }
        if (resp.message !== '') setErrorLinkText(resp.message);
        else setErrorLinkText('');
      } else if (linksDuplicated.includes(UserContext.profile.role)) {
        setErrorLinkText(resp.message);
        setValidLink(true);
      } else {
        setValidLink(false);
        setErrorLinkText(resp.message);
        setErrorsArr([...copyErrors, 0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLink(false);
    }
  };

  const validateUrl = async (value: string, link_id?: number) => {
    if (value === '') {
      setErrorLinkText('');
      setValidLink(false);
      return;
    }
    try {
      setLoadingLink(true);
      const resp = await validateLink(encodeURIComponent(value), link_id);
      if (resp.valid) {
        if (linksDuplicated.includes(UserContext.profile.role)) {
          setErrorLinkText(resp.message);
        } else setErrorLinkText('');
        setValidLink(true);
        setLoadingLink(false);
      } else {
        if (
          (UserContext.profile.role === 'linkbuilder' ||
            linksDuplicated.includes(UserContext.profile.role)) &&
          typeTab === 'add'
        ) {
          validateRulesCannibalization(value);
          return;
        }
        setErrorsArr([...errorsArr, 0]);
        setErrorLinkText(resp.message);
        setLoadingLink(false);
        setValidLink(false);
      }
    } catch (err) {
      setLoadingLink(false);
      console.log(err);
    }
  };

  const validateOnEdit = async (value: string, link_id: number) => {
    setLoadingLink(true);
    try {
      const resp = await validateLink(encodeURIComponent(value), link_id);
      if (!resp.valid) {
        setErrorsArr([...errorsArr, 0]);
        setErrorLinkText(resp.message);
        setValidLink(false);
      } else {
        setValidLink(true);
        setLoadingLink(false);
        if (linksDuplicated.includes(UserContext.profile.role))
          setErrorLinkText(resp.message);
        else setErrorLinkText('');
      }
      return resp.valid;
    } catch (err) {
      return true;
    } finally {
      setLoadingLink(false);
    }
  };

  const updateStatus = (note: string) => {
    if (linkForm.text_note) {
      const linkClosedIndex = linkForm.text_note.indexOf('Link Close');
      const contactClosedIndex = linkForm.text_note.indexOf('Contact Close');
      const noteIndex = linkForm.text_note.indexOf('Note:');
      if (
        (linkClosedIndex !== -1 && contactClosedIndex !== 1) ||
        (linkClosedIndex === -1 && contactClosedIndex !== -1)
      ) {
        const newNote = `${linkForm.text_note.slice(
          contactClosedIndex,
          linkClosedIndex === -1 ? noteIndex : linkClosedIndex,
        )}\nNote: ${note}`;
        setLinkForm({ ...linkForm, text_note: newNote, status: newStatus });
        setOpenModal(false);
        return;
      }
      if (linkClosedIndex !== -1 && contactClosedIndex === -1) {
        const newNote = `Note: ${note}`;
        setLinkForm({ ...linkForm, text_note: newNote, status: newStatus });
        setOpenModal(false);
        return;
      }
      if (linkClosedIndex === -1 && contactClosedIndex === -1) {
        setLinkForm({ ...linkForm, text_note: note, status: newStatus });
        setOpenModal(false);
        return;
      }
    }
    setLinkForm({ ...linkForm, text_note: note, status: newStatus });
    setOpenModal(false);
  };

  const canEditLinks = [
    'administrator',
    'dev admin',
    'strategist',
    'manager',
    'account manager',
  ];

  const checkError = (value: string, id: number) => {
    const copyArr = [...errorsArr];
    if (id === 4 && !copyArr.includes(5) && linkForm.affinity === 0)
      copyArr.push(5);
    if (id === 4 && !copyArr.includes(6) && linkForm.template === 0)
      copyArr.push(6);
    if (id === 5 && !copyArr.includes(6) && linkForm.template === 0)
      copyArr.push(6);
    if (value === '') {
      if (copyArr.includes(id)) return;
      copyArr.push(id);
      setErrorsArr(copyArr);
      return;
    }
    const newArr = copyArr.filter(item => item !== id);

    setErrorsArr(newArr);
  };

  const closeTypes = [
    'site_closed',
    'project_closed',
    'global_closed',
    'invalid_contact',
  ];

  const tooltipInformation = () => {
    return (
      <>
        <Box>
          {linkForm.status === closeTypes[0] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Site Close: No one from this site should reach out to this
                website again, but other sites have the ability to.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `Your content is out of date.`
              </Typography>
            </>
          )}{' '}
          {linkForm.status === closeTypes[1] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Project Close: No one from this project should reach out to this
                website again, but other projects have the ability to.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                *i.e If a contact is marked as a Project Close on
                ConsumerNotice, no one from any of the Mass Torts projects will
                be able to reach out to them.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `We can only share information from government
                agencies or non-profits.`
              </Typography>
            </>
          )}
          {linkForm.status === closeTypes[2] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Global Close: No one from Launch That Linkbuilding should reach
                out again to this website.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `If you email us again, we will report you for
                harassment and pursue legal action.`
              </Typography>
            </>
          )}
          {linkForm.status === closeTypes[3] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Invalid Contact: The contacts for this entry are not valid.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `An “Email Bounced” error message.“XYZ no
                longer works at this company.”“I am not responsible for this,
                XYZ is the director of this program.”`
              </Typography>
            </>
          )}
        </Box>
      </>
    );
  };

  const avoidStatus = [
    'project_closed',
    'site_closed',
    'global_closed',
    'invalid_contact',
    'unsubscribed',
  ];
  const statusExcluded = {
    project_closed: 'Project Closed',
    site_closed: 'Site Closed',
    global_closed: 'Global Closed',
    invalid_contact: 'Invalid Contact',
    unsubscribed: 'Unsubscribed',
  };

  useEffect(() => {
    if (statusOptions.length > 0) {
      const removedStatus = statusOptions.filter(
        item => !avoidStatus.includes(item.value),
      );

      if (!removedStatus.find(item => item.value === linkForm.status)) {
        const key = linkForm.status as keyof typeof statusExcluded;
        removedStatus.push({
          group: 'Enabled',
          value: key,
          name: statusExcluded[key],
        });
      }
      setLinkStatus(removedStatus);
    }
  }, [linkForm.status, statusOptions]);

  const inputsArr = [
    {
      id: 0,
      value: linkForm.url,
      fullSpace: true,
      required: true,
      validate: true,
      validation: validateUrlInputs(linkForm.url, 0, errorsArr),
      body: (
        <>
          <InputLabel
            htmlFor="url"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
            }}
          >
            URL <span className="required-fields_dot">*</span>
          </InputLabel>
          <TextInputField
            value={linkForm.url}
            name="url"
            disabled={disableLinkEdit}
            error={errorsArr.includes(0)}
            size="small"
            onBlur={e => {
              if (e.target.value === '') {
                const copyArr = [...errorsArr];
                copyArr.push(0);
                setErrorsArr(copyArr);
              }
              if (e.target.value === tempUrl) return;
              setTempUrl(e.target.value);
              let copyURL = linkForm.url;
              if (copyURL !== '' && !/(http(s?)):\/\//i.test(copyURL)) {
                copyURL = `https://${copyURL}`;
                setLinkForm({ ...linkForm, url: copyURL });
              }
              const validate = validateUrlInputs(copyURL, 0, errorsArr);
              setErrorsArr(validate);
              if (!validate.includes(0)) {
                if (objEdit && objEdit.url === copyURL) {
                  setValidLink(true);
                  setErrorLinkText('');
                  return;
                }
                if (typeTab === 'edit') validateUrl(copyURL, linkForm.id ?? 0);
                if (typeTab === 'add') validateRulesCannibalization(copyURL);
              }
            }}
            onChange={e => setLinkForm({ ...linkForm, url: e.target.value })}
            fullWidth
            placeholder="URL"
            id="url"
            InputProps={
              loadingLink
                ? {
                    endAdornment: (
                      <InputAdornment position="start">
                        <CircularProgress sx={{ color: '#ABABAB' }} size={16} />
                      </InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          {validLink === true && (
                            <CheckCircleIcon fontSize="inherit" />
                          )}
                          {validLink === false && (
                            <CancelIcon fontSize="inherit" />
                          )}
                        </>
                      </InputAdornment>
                    ),
                  }
            }
            helperText={errorLinkText}
          />
        </>
      ),
    },
    {
      id: 1,
      value: linkForm.site === 0 ? '' : 'true',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <>
          <SelectFormInput
            clearIcon
            value={
              sitesWithAll.find(item => item.id === linkForm.site) ?? {
                id: 0,
                name: '',
              }
            }
            options={sitesWithAll}
            disabled={sitesWithAll.length === 0 || disableLinkEdit}
            disabledId={
              pendingListObject
                ? !sitesOptions.find(
                    item => item.id === pendingListObject.site.id,
                  )
                  ? pendingListObject.site.id
                  : undefined
                : undefined
            }
            placeholder="Sites"
            loading={loadingSites}
            required
            onChange={(value: BasicArray | null) => {
              if (value === null) return;
              setAudienceOptions([]);
              setAffinityOptions([]);
              setTemplatesOpt([]);
              setAffinityInput('');
              setTemplateInput('');
              setAudienceInput('');
              setLinkForm({
                ...linkForm,
                site: value ? (value.id as number) : 0,
                template: 0,
                affinity: 0,
                audience: 0,
              });
            }}
          />
          {pendingListObject && pendingListObject.site.id !== linkForm.site && (
            <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
              Previous site: {pendingListObject?.site.name}
            </FormHelperText>
          )}

          {!pendingListObject &&
            type === 'edit' &&
            siteTemp &&
            linkForm.site !== siteTemp.id && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous site: {siteTemp.name}
              </FormHelperText>
            )}
        </>
      ),
    },
    {
      id: 2,
      value: linkForm.status,
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <FlexBox>
          <Box sx={{ flexGrow: 1 }}>
            <StatusSelectLinkFormField
              error={hasError(2)}
              value={linkForm.status ?? ''}
              loading={loadingStatusOptions}
              onChangeFunction={(value: string) => {
                if (value !== originalState && typeTab === 'edit') {
                  setNewStatus(value);
                  setOpenModal(true);
                } else setLinkForm({ ...linkForm, status: value });
              }}
              required
              nameInput="status"
              options={linkStatus}
              label="Status"
              disabled={disableLinkEdit || disabledStatus}
            />
          </Box>
          {closeTypes.includes(linkForm.status) && (
            <HtmlTooltip title={tooltipInformation()}>
              <IconButton sx={{ fontSize: 16 }}>
                <HelpRoundedIcon fontSize="inherit" />
              </IconButton>
            </HtmlTooltip>
          )}
        </FlexBox>
      ),
    },
    {
      id: 3,
      value: ownerFromLink === 0 ? '' : 'true',
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <OwnersSelectLinkFormField
          required
          loading={loadingOwners}
          error={hasError(3)}
          errorText={
            pendingListObject && pendingListObject.owner.id !== UserContext.id
              ? `Previous owner: ${pendingListObject.owner.username}`
              : type === 'edit' && objEdit && objEdit.owner !== linkForm.owner
              ? `Previous owner: ${
                  owners.find(item => item.id === objEdit.owner)?.username
                }`
              : ''
          }
          value={ownerFromLink}
          onChangeFunction={(value: number) => {
            setLinkForm({ ...linkForm, owner: value });
            setOwnerFromLink(value);
          }}
          options={owners}
          nameInput="owner"
          label="Owner"
          disabled={
            owners.length === 0 ||
            !sendBackObject(UserContext.profile.role, ROLES_LINKS).assignee
          }
        />
      ),
    },
    // {
    //   id: 4,
    //   value: linkForm.assignee === 0 ? '' : 'true',
    //   fullSpace: true,
    //   required: true,
    //   validate: false,
    //   validation: [],
    //   body: (
    //     <SelectFormField
    //       error={hasError(4)}
    //       errorText={hasError(4) ? 'Select an Assignee' : ''}
    //       value={linkForm.assignee}
    //       onChangeString={() => {}}
    //       onChangeNumber={(value: number) =>
    //         setLinkForm({ ...linkForm, assignee: value })
    //       }
    //       optionsOwners={RequestsContext.owners}
    //       nameInput="assignee"
    //       label="Assignee"
    //       disabled={
    //         !sendBackObject(UserContext.profile.role, ROLES_LINKS).assignee
    //       }
    //     />
    //   ),
    // },
    {
      id: 4,
      value: linkForm.audience === 0 ? '' : 'true',
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <>
          <SelectFormInput
            clearIcon
            loading={loadingAud}
            error={errorsArr.includes(4)}
            options={audienceOptions}
            updateInput={updateAudienceInput}
            onBlur={value => {
              if (
                value !==
                audienceOptions.find(item => item.id === linkForm.audience)
                  ?.name
              ) {
                setLinkForm({
                  ...linkForm,
                  audience: 0,
                  affinity: 0,
                  template: 0,
                });
                setAffinityOptions([]);
                setAffinityInput('');
                setTemplateInput('');
                setTemplatesOpt([]);
                checkError('', 4);
              } else {
                checkError(value, 4);
              }
            }}
            required
            disabled={audienceOptions.length === 0 || disableLinkEdit}
            placeholder="Audience"
            value={
              audienceOptions.find(item => item.id === linkForm.audience) ?? {
                id: 0,
                name: audienceInput,
              }
            }
            onChange={(value: BasicArray | null) => {
              setLinkForm({
                ...linkForm,
                audience: value ? (value.id as number) : 0,
                affinity: 0,
                template: 0,
              });
              setAffinityOptions([]);
              setAffinityInput('');
              setTemplateInput('');
              setTemplatesOpt([]);
            }}
          />
          {pendingListObject &&
            !sitesOptions.find(
              item => item.id === pendingListObject.site.id,
            ) && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous audience: {pendingListObject?.audience.name}
              </FormHelperText>
            )}
          {objEdit &&
            audienceTemporal &&
            audienceTemporal.id !== linkForm.audience && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous audience: {audienceTemporal.name}
              </FormHelperText>
            )}
        </>
      ),
    },
    {
      id: 5,
      value: linkForm.affinity === 0 ? '' : 'true',
      fullSpace: false,
      required: true,
      validate: false,
      validation: [],
      body: (
        <>
          <SelectFormInput
            loading={loadingAff}
            required
            clearIcon
            error={errorsArr.includes(5)}
            onBlur={value => {
              if (
                value !==
                affinityOptions.find(item => item.id === linkForm.affinity)
                  ?.name
              ) {
                setLinkForm({
                  ...linkForm,
                  affinity: 0,
                  template: 0,
                });
                setTemplatesOpt([]);
                setTemplateInput('');
                checkError('', 5);
              } else {
                checkError(value, 5);
              }
            }}
            updateInput={updateAffinityInput}
            options={affinityOptions}
            disabled={affinityOptions.length === 0 || disableLinkEdit}
            placeholder="Affinity"
            value={
              affinityOptions.find(item => item.id === linkForm.affinity) ?? {
                id: 0,
                name: affinityInput,
              }
            }
            onChange={(value: BasicArray | null) => {
              setLinkForm({
                ...linkForm,
                affinity: value ? (value.id as number) : 0,
                template: 0,
              });
              setTemplatesOpt([]);
              setTemplateInput('');
            }}
          />
          {pendingListObject &&
            (!sitesOptions.find(
              item => item.id === pendingListObject.site.id,
            ) ||
              !affinityOptions.find(
                item => item.id === pendingListObject.affinity.id,
              )) && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous affinity: {pendingListObject?.affinity.name}
              </FormHelperText>
            )}
          {objEdit &&
            affinityTemporal &&
            (affinityTemporal.id !== linkForm.affinity ||
              linkForm.audience === 0) && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous affinity: {affinityTemporal.name}
              </FormHelperText>
            )}
        </>
      ),
    },

    {
      id: 6,
      value: linkForm.template === 0 ? '' : 'true',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <>
          <SelectFormInput
            loading={loadingTemplates}
            required
            clearIcon
            error={errorsArr.includes(6)}
            onBlur={value => {
              const copyArr = [...errorsArr];

              if (
                value !==
                templatesOpt.find(item => item.id === linkForm.template)?.name
              ) {
                setLinkForm({
                  ...linkForm,
                  template: 0,
                });
                if (!copyArr.includes(6)) {
                  copyArr.push(6);
                  setErrorsArr(copyArr);
                }
              } else {
                const newArr = copyArr.filter(item => item !== 6);
                setErrorsArr(newArr);
              }
            }}
            updateInput={updateTemplateInput}
            options={templatesOpt}
            disabled={templatesOpt.length === 0 || disableLinkEdit}
            placeholder="Template"
            value={
              templatesOpt.find(item => item.id === linkForm.template) ?? {
                id: 0,
                name: templateInput,
              }
            }
            onChange={(value: BasicArray | null) => {
              setLinkForm({
                ...linkForm,
                template: value ? (value.id as number) : 0,
              });
            }}
          />

          {pendingListObject &&
            !sitesOptions.find(
              item => item.id === pendingListObject.site.id,
            ) && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous template: {pendingListObject?.template.name}
              </FormHelperText>
            )}
          {objEdit &&
            (tempsLinks.id !== linkForm.template ||
              linkForm.audience === 0) && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous template: {tempsLinks.title}
              </FormHelperText>
            )}
        </>
      ),
    },
  ];

  const translateTemplate = async () => {
    const gettingContacts = linkForm.contacts
      .filter(item => item.is_default && item.name !== '' && !item.is_form)
      .map(item => {
        return item.name;
      })
      .join(',');
    try {
      const resp = await getTemplateTranslate(
        linkForm.template,
        encodeURIComponent(linkForm.url),
        linkForm.id ?? 0,
        encodeURIComponent(gettingContacts ?? ''),
      );

      return resp;
    } catch (err) {
      return { title: '', subject: '', body: '' };
    }
  };

  const changeBody = (message: string) => encodeURIComponent(message);

  const sendEmail = (
    subject: string,
    message: string,
    templateId: number,
    linkURL: string,
    urlId: number,
  ) => {
    const listOfContacts = linkForm.contacts
      .filter(user => user.email !== '' && user.is_default && !user.is_form)
      .map(item => {
        return { email: item.email, name: item.name };
      });

    // const email = {
    //   toList: encodeURIComponent(listOfContacts),
    //   subject: changeBody(subject),
    //   body: changeBody(message),
    // };
    const mailGmail = `https://mail.google.com/mail/u/0/?fs=1&to=${encodeURIComponent(
      listOfContacts.map(item => item.email).join(','),
    )}&tf=cm&templateId=${templateId}&linkUrl=${encodeURIComponent(
      linkURL,
    )}&urlId=${urlId}&contactNames=${listOfContacts
      .filter(item => item.name !== '')
      .map(item => item.name)
      .join(',')}`;
    // const mailto = `mailto:${email.toList}?subject=${email.subject}&body=${email.body}`;
    window.open(mailGmail, '_blank');
  };

  const saveLink = async (email?: boolean, copyLinkForm?: ObjectLinks) => {
    const obj = copyLinkForm ? { ...copyLinkForm } : { ...linkForm };
    // if (
    //   !obj.contacts.find(item => item.is_default) &&
    //   obj.contacts.find(item => !item.is_form)
    // ) {
    //   const indexContact = obj.contacts.findIndex(item => !item.is_form);
    //   obj.contacts[indexContact].is_default = true;
    // }
    if (obj.owner !== ownerFromLink) obj.owner = ownerFromLink;
    obj.send_email = email ?? false;
    obj.text_note = typeTab === 'add' ? 'New Link' : obj.text_note;
    if (cannibalizedId !== null) obj.cannibalized_link_id = cannibalizedId;
    if (prospectId) obj.prospecting_assignment = prospectId;
    try {
      let linkID: number = 0;
      if (typeTab === 'add') {
        const response = await createLink(obj);
        linkID = response.id;
      }
      if (typeTab === 'edit') {
        if (obj.status === 'contact_not_found' && email) {
          obj.status = 'pending';
        }
        const response = await updateLink(obj, obj.id ?? 0);
        linkID = response.id;
      }

      showMessage(
        `Link ${typeTab === 'edit' ? 'updated' : 'created'} successfully`,
        'success',
      );
      fetchLinks();
      setOpenDrawer(false);
      if (email) {
        const templateInfo = await translateTemplate();
        sendEmail(
          templateInfo.subject,
          templateInfo.body,
          obj.template,
          obj.url,
          linkID,
        );
      }
    } catch (err) {
      const error = err as any;
      const url = `${typeTab === 'edit' ? 'PUT' : 'POST'} ${
        process.env.REACT_APP_API_BASE_URL
      }/api/v2/links/${typeTab === 'edit' ? `${obj.id ?? 0}` : ''}`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      showMessage(
        `Error ${typeTab === 'edit' ? 'updating' : 'creating'} link`,
        'error',
      );
      setOpenModalOutreach(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (email?: boolean) => {
    setLoading(true);
    const check = inputsArr.filter(item => item.value === '' && item.required);

    if (check.length > 0) {
      setLoading(false);
      setDisabledButtons(true);
      return;
    }
    saveLink(email);
  };

  const checkInputs = () => {
    const check = inputsArr.filter(item => item.value === '' && item.required);

    if (check.length > 0) setDisabledButtons(true);
    else setDisabledButtons(false);
  };

  const handleEmailSent = () => {
    if (linkForm.contacts.filter(item => item.is_default).length === 0) {
      setOpenMessage(true);
      setMessagesProps({
        severity: 'error',
        message: 'At least one contact included in template is required',
      });
      return;
    }
    if (linkForm.contacts.filter(item => item.is_default).length > 1) {
      setOpenMessage(true);
      setMessagesProps({
        severity: 'error',
        message: 'You can only have one contact included in template.',
      });
      return;
    }
    handleSubmit(true);
  };

  useEffect(() => {
    checkInputs();
  }, [linkForm]);

  useEffect(() => {
    if (ownerFromLink !== linkForm.owner)
      setLinkForm({
        ...linkForm,
        owner: ownerFromLink,
      });
  }, [ownerFromLink]);

  const validateLinkEdit = async () => {
    if (!objEdit) return;
    if (IS_USER_ADMIN.includes(UserContext.profile.role)) return;

    const RoleEdit = sendBackObject(UserContext.profile.role, ROLES_LINKS).edit;
    if (!RoleEdit) {
      setDisableLinkEdit(true);
      return;
    }

    const instantDisabled = ['project_closed', 'site_closed', 'global_closed'];

    if (
      instantDisabled.includes(objEdit.status) &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      setDisableLinkEdit(true);
      return;
    }

    const dateNow = dayjs();
    if (is_unsubscribed && unsubscribed_date) {
      const dateUnsub = dayjs(unsubscribed_date);
      const is15DaysAfter = dateNow.isAfter(dateUnsub.add(15, 'day'));

      if (!is15DaysAfter) {
        setDisabledSaveEmail(true);
        setMessagesProps({
          message:
            'Save+Email function currently disabled due to a recent Unsubscribe',
          severity: 'error',
        });
        setOpenMessage(true);
      }

      if (objEdit.status === 'contact_not_found' && is15DaysAfter) return;
    }

    if (objEdit.status === 'contact_not_found' && !is_older_than_ninety_days) {
      setDisableLinkEdit(true);
      return;
    }

    if (
      objEdit.status === 'hold' &&
      !is_older_than_ninety_days &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      setDisableLinkEdit(true);
      return;
    }

    if (
      objEdit.status === 'invalid_contact' &&
      !is_older_than_thirty_days &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      setDisableLinkEdit(true);
      return;
    }

    if (
      objEdit.status === 'invalid_contact' &&
      is_older_than_thirty_days &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      setDisableLinkEdit(true);
      setAllowOpenNewModalContact(true);
      return;
    }

    if (!is_older_than_ninety_days) {
      if (
        objEdit.owner !== UserContext.id &&
        !canEditLinks.includes(UserContext.profile.role)
      )
        setDisableLinkEdit(true);
    } else if (!linksDuplicated.includes(UserContext.profile.role)) {
      if (UserContext.id !== objEdit.owner) {
        const resp = await validateOnEdit(objEdit.url, objEdit.id ?? 0);
        if (resp) {
          setOwnerFromLink(UserContext.id);
          setAssignedToThisLink(true);
        } else {
          setDisableLinkEdit(true);
        }
      }
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      setOriginalState(objEdit?.status ?? '');
      validateLinkEdit();
    } else if (
      linkForm.owner === UserContext.id &&
      linkForm.url !== '' &&
      type === 'add'
    )
      validateRulesCannibalization(linkForm.url);
  }, []);

  useEffect(() => {
    if (linkForm.audience !== 0) getAffinities();
  }, [linkForm.audience]);

  useEffect(() => {
    if (render < 1) setRender(prev => prev + 1);
    if (
      render === 1 &&
      linkForm.site !== 0 &&
      linkForm.url !== '' &&
      !loadingCannibalizationChange
    )
      validateRulesCannibalization(linkForm.url);

    if (linkForm.site !== 0 || siteProspect !== 0) {
      getAudiences();
    }
  }, [linkForm.site, siteProspect]);

  useEffect(() => {
    if (sitesOptions.length > 0) {
      if (!sitesOptions.find(item => item.id === linkForm.site)) {
        const copyArr: Array<{ id: number; name: string; status: string }> = [
          ...sitesOptions,
        ];
        if (siteTemp)
          copyArr.push({ id: siteTemp.id, name: siteTemp.name, status: '' });
        setSitesWithAll(copyArr);
        setLoadingSites(false);
      } else {
        setSitesWithAll(sitesOptions);
        setLoadingSites(false);
      }
    }
  }, [sitesOptions]);

  const updateLinkToPending = () =>
    setLinkForm({ ...linkForm, status: 'pending' });

  const handleDisableButtonContact = (value: boolean) =>
    setDisabledContactButton(value);

  const updateContacts = (contacts: ContactsFormLink[]) => {
    if (loadingCannibalizationChange) return;
    setLinkForm({ ...linkForm, contacts });
  };

  const handleClaimDisabled = (value: boolean) => setDisabledClaimSave(value);

  const saveClaimLink = async (obj?: ClaimPostPutInt) => {
    setloadingSubmitClaimLink(true);
    try {
      const resp = await createClaimLink(obj ?? claimObj);
      showMessage(`Claim link created successfully`, 'success');
      fetchLinks();
      setOpenDrawer(false);
      console.log(resp);
    } catch (err) {
      const error = err as any;
      const url = `POST ${process.env.REACT_APP_API_BASE_URL}/api/v2/link-claims/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj ?? claimObj),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      if (
        error.data.targets &&
        error.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        showMessage('Malformed link for Destination Url', 'error');
      } else {
        console.log(error);
        showMessage('Error creating claim link', 'error');
      }
    } finally {
      setloadingSubmitClaimLink(false);
    }
  };

  const handleUpdateClaimObj = (obj: ClaimPostPutInt) => setClaimObj(obj);

  const disabledEdit = () => {
    const firstResp = [
      'manager',
      'account manager',
      'administrator',
      'dev admin',
      'strategist',
    ].includes(UserContext.profile.role);
    if (firstResp) return false;
    if (claimObj.owner === UserContext.id) return false;
    return true;
  };

  const updateLinkClosed = (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => {
    setLinkForm({
      ...linkForm,
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    });

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);
  };

  const updateLinkClosedAndSave = (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => {
    setLoading(true);

    const copyObj = {
      ...linkForm,
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    };

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);

    setLinkForm({
      ...linkForm,
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    });

    saveLink(undefined, copyObj);
  };

  const updateContactsClosed = (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => {
    const copyContacts = [...linkForm.contacts].map((item, index) => {
      if (contactIds.includes(index)) {
        if (item.is_form && status === 'invalid_contact')
          return {
            ...item,
            status,
            close_reason: close_reason ?? '',
            name: item.name === '' ? 'Invalid Legacy Form Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
          };
        return {
          ...item,
          status,
          close_reason: close_reason ?? '',
          is_default:
            status === 'site_closed' ? false : item.is_default ?? false,
        };
      }
      return item;
    });
    const copyObject = { ...linkForm };
    if (editLinkToo) {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
      if (
        !copyContacts.find(item => item.status === 'open' && !item.is_form) &&
        status === 'invalid_contact'
      )
        copyObject.status = 'invalid_contact';

      copyObject.close_reason = 'All Contacts Closed';
      setLinkForm(copyObject);
    } else
      setLinkForm({
        ...linkForm,
        contacts: copyContacts,
        text_note: newNote,
      });
  };

  const updateContactsClosedAndSave = (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => {
    setLoading(true);
    const copyContacts = [...linkForm.contacts].map((item, index) => {
      if (contactIds.includes(index)) {
        if (item.is_form && status === 'invalid_contact')
          return {
            ...item,
            status,
            close_reason: close_reason ?? '',
            name: item.name === '' ? 'Invalid Legacy Form Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
          };
        return {
          ...item,
          status,
          close_reason: close_reason ?? '',
          is_default:
            status === 'site_closed' ? false : item.is_default ?? false,
        };
      }
      return item;
    });
    const copyObject = { ...linkForm };
    if (editLinkToo) {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
      if (
        !copyContacts.find(item => item.status === 'open' && !item.is_form) &&
        status === 'invalid_contact'
      )
        copyObject.status = 'invalid_contact';
      copyObject.close_reason = 'All Contacts Closed';
    } else {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
    }

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);

    setLinkForm(copyObject);

    saveLink(undefined, copyObject);
  };

  const getStatusFromLink = () => {
    if (linkForm.text_note) {
      const findIndex = linkForm.text_note.indexOf('Note: ');
      if (findIndex !== -1)
        return linkForm.text_note.slice(
          findIndex + 5,
          linkForm.text_note.length,
        );
      return '';
    }
    return '';
  };

  const updateNewContact = (newContact: ContactsFormLink) => {
    const copyContacts = [...linkForm.contacts];
    copyContacts.push(newContact);
    setLinkForm({ ...linkForm, contacts: copyContacts });
  };

  const updateNewContactAndAllowEdit = (newContact: ContactsFormLink) => {
    const copyContacts = [...linkForm.contacts];
    copyContacts.push(newContact);
    setLinkForm({
      ...linkForm,
      contacts: copyContacts,
      owner: UserContext.id,
      status: 'pending',
    });
    setOwnerFromLink(UserContext.id);
    setDisableLinkEdit(false);
    setDoNotDeleteContact(newContact.email);
    closeNewContact();
  };

  const closeButtonContacts = () => {
    const indexesOfContacts = linkForm.contacts
      .map((item, index) => {
        return { ...item, id: index };
      })
      .filter(item => item.status === 'open');

    return indexesOfContacts.filter(item => item.email !== '' || item.is_form);
  };

  const sendMessage = (message: string, severity?: SeverityType) => {
    setOpenMessage(true);
    setMessagesProps({ severity: severity ?? 'error', message });
  };

  const addTextNote = (message: string) => {
    navigator.clipboard.writeText(message);
    setLinkForm({
      ...linkForm,
      text_note: `Form sent:\n${message}`,
    });
  };

  return (
    <>
      <HeaderDrawer
        saveFunction={tabDrawer === 4 ? saveClaimLink : handleSubmit}
        closeButton={
          typeTab === 'edit'
            ? {
                disabled: loading || false || disableLinkEdit,
                contacts: closeButtonContacts(),
                allContacts: linkForm.contacts,
                updateNewContact,
                contactsValidations: contactsValues,
                site: linkForm.site,
                updateContactsClosed,
                updateLinkClosed,
                updateLinkClosedAndSave,
                updateContactsClosedAndSave,
                disabledSave:
                  !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
                  disableLinkEdit ||
                  disabledButtons ||
                  owners.length === 0 ||
                  errorsArr.length > 0 ||
                  loadingLink ||
                  (audienceOptions.length === 0 &&
                    affinityOptions.length === 0 &&
                    templatesOpt.length === 0),
              }
            : undefined
        }
        saveFunctionEmail={() =>
          cannibalizedId === null
            ? handleEmailSent()
            : setOpenModalOutreach(true)
        }
        title={`${typeTab} Link`}
        threeButtons={!(tabDrawer === 4)}
        loading={tabDrawer === 4 ? loadingSubmitClaimLink : loading}
        disabledEmailSave={
          tabDrawer === 4
            ? disabledClaimSave || disabledEdit()
            : !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
              disableLinkEdit ||
              linkForm.contacts.filter(item => item.is_default).length === 0 ||
              disabledSaveEmail ||
              linkForm.contacts.filter(
                item => item.status !== 'invalid_contact',
              ).length === 0 ||
              disabledButtons ||
              owners.length === 0 ||
              disabledContactButton ||
              errorsArr.length > 0 ||
              loadingLink ||
              (audienceOptions.length === 0 &&
                affinityOptions.length === 0 &&
                templatesOpt.length === 0)
        }
        disabled={
          tabDrawer === 4
            ? disabledClaimSave || disabledEdit()
            : !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
              disableLinkEdit ||
              disabledButtons ||
              owners.length === 0 ||
              disabledContactButton ||
              errorsArr.length > 0 ||
              loadingLink ||
              (audienceOptions.length === 0 &&
                affinityOptions.length === 0 &&
                templatesOpt.length === 0)
        }
        closeDrawer={setOpenDrawer}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: 1.3,
          marginLeft: 3,
          marginRight: 4,
          marginBottom: 2.6,
        }}
      >
        <TabParent
          valueTab={tabDrawer}
          setTab={setTabDrawer}
          tabs={tabs}
          center
        />
      </Box>
      <TabPanel value={tabDrawer} index={0}>
        <BoxAddSettings title="Link" mb={0}>
          <div className="form-grids">
            {inputsArr.slice(0, 4).map(input => (
              <div
                key={input.id}
                className={input.fullSpace ? 'whole-grid' : ''}
              >
                {input.body}
              </div>
            ))}
            {/* {typeTab === 'edit' && (
              <div>
                <StatusClosed
                  disabled={loading || false}
                  contacts={linkForm.contacts}
                  updateContactsClosed={updateContactsClosed}
                  updateLinkClosed={updateLinkClosed}
                />
              </div>
            )} */}
            {pendingListObject && (
              <>
                <Box>
                  <InputLabel
                    htmlFor="created_date"
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                      marginTop: '-10px',
                    }}
                  >
                    Created Date
                  </InputLabel>
                  <DateInput
                    value={pendingListObject.created}
                    fullWidth
                    disabled
                    handleChangeDate={(date: string) => {}}
                  />
                </Box>

                <Box>
                  <InputLabel
                    htmlFor="modified_date"
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                      marginTop: '-10px',
                    }}
                  >
                    Modified Date
                  </InputLabel>
                  <DateInput
                    value={pendingListObject.modified}
                    fullWidth
                    disabled
                    handleChangeDate={(date: string) => {}}
                  />
                </Box>
              </>
            )}
          </div>
        </BoxAddSettings>

        <BoxAddSettings title="Targeting" mb={0}>
          <div className="form-grids">
            {inputsArr.slice(4).map(input => (
              <div
                key={input.id}
                className={input.fullSpace ? 'whole-grid' : ''}
              >
                {input.body}
              </div>
            ))}
          </div>
        </BoxAddSettings>
        <BoxAddSettings title="Contact" mb={4}>
          <ContactsComponent
            addTextNote={addTextNote}
            sendMessage={sendMessage}
            updateLinkToPending={updateLinkToPending}
            allowOpenNewModalContact={allowOpenNewModalContact}
            openNewContactModal={openNewContactModal}
            type={typeTab}
            doNotDeleteContact={doNotDeleteContact}
            loadingCannibalizationChange={loadingCannibalizationChange}
            pendingId={
              linkForm.id && linkForm.id !== 0 ? linkForm.id : undefined
            }
            linkStatus={linkForm.status}
            updateContacts={updateContacts}
            templateInfo={{
              template_id: linkForm.template,
              link_id: linkForm.id ?? 0,
              link_url: linkForm.url,
            }}
            originalContacts={
              objEdit
                ? objEdit.contacts
                : pendingListContacts.length > 0
                ? pendingListContacts
                : undefined
            }
            handleDisableButtonContact={handleDisableButtonContact}
            disabledContactButton={disabledContactButton}
            contacts={linkForm.contacts}
            updateContactsValues={updateContactsValues}
            contactsValuesOriginal={contactsValues}
            site={linkForm.site}
            disableEditButton={disableLinkEdit}
          />
        </BoxAddSettings>
      </TabPanel>
      {typeTab === 'edit' && (
        <>
          <TabPanel value={tabDrawer} index={1}>
            <BoxAddSettings>
              <FormNotes
                getNotes={refreshNotes}
                loadingNotes={loadingNotes}
                type="link"
                notes={notes}
                id={objEdit ? objEdit.id ?? 0 : 0}
                setMessage={setMessagesProps}
                openMessage={setOpenMessage}
              />
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={2}>
            <BoxAddSettings>
              <FormStatus history={history} />
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={4}>
            {objEdit &&
            (linkForm.owner === UserContext.id ||
              historyAllowed.includes(UserContext.profile.role)) &&
            objEdit?.status === 'active' &&
            sitesOptions.find(item => item.id === linkForm.site)
              ?.link_claims_allowed ? (
              <ClaimTab
                owners={owners}
                handleSaveForm={saveClaimLink}
                objEdit={objEdit}
                handleClaimDisabled={handleClaimDisabled}
                sites={sitesWithAll}
                handleUpdateClaimObj={handleUpdateClaimObj}
                affinityTemporal={affinityTemporal}
                audienceTemporal={audienceTemporal}
                tempsLinks={tempsLinks}
                siteTemp={siteTemp}
                readMode={UserContext.profile.role === 'trainer'}
              />
            ) : (
              <Box>
                <Typography fontSize={14} textAlign="center" fontWeight={500}>
                  {(sitesOptions.length === 0 || historyLoading) && <Spinner />}
                  {sitesOptions.length > 0 &&
                    !sitesOptions.find(item => item.id === linkForm.site) &&
                    'You are not assigned to this site.'}
                  {sitesOptions.length > 0 &&
                    !historyLoading &&
                    objEdit?.status !== 'active' &&
                    'Cannot create claim on a link that is not active.'}
                  {sitesOptions.length > 0 &&
                    !historyLoading &&
                    sitesOptions.find(item => item.id === linkForm.site)
                      ?.link_claims_allowed === false &&
                    ' This site does not allow claims.'}
                  {!historyLoading &&
                    sitesOptions.length > 0 &&
                    !history.find(item => item.user?.id === UserContext.id) &&
                    historyBlocked.includes(UserContext.profile.role) &&
                    ' You have not edited this link.'}
                </Typography>
              </Box>
            )}
          </TabPanel>
        </>
      )}
      <TabPanel value={tabDrawer} index={3}>
        <TemplateTab
          templateId={linkForm.template}
          url={linkForm.url}
          link_id={linkForm.id ?? 0}
          contact_name={linkForm.contacts
            .filter(
              item => item.is_default && item.name !== '' && !item.is_form,
            )
            .map(item => {
              return item.name;
            })
            .join(',')}
        />
      </TabPanel>
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <StatusModal
        open={openModal}
        closeModal={setOpenModal}
        linkNote={getStatusFromLink()}
        updateStatus={updateStatus}
      />

      <ConfirmEmail
        loading={loading}
        open={openModalOutreach}
        closeModal={setOpenModalOutreach}
        contacts={linkForm.contacts}
        handleOk={handleEmailSent}
      />

      <AddNewContactModal
        contacts={linkForm.contacts.filter(item => item.email !== '')}
        open={openNewContact}
        updateNewContactAndAllowEdit={updateNewContactAndAllowEdit}
        closeModal={closeNewContact}
        site={linkForm.site}
      />
    </>
  );
};

export default AddLink;
