import { create } from 'zustand';
import { ProfileProjects, ProfileSites } from '../../models/User';
import { fetchUserAlias } from '../../helpers/user';

interface UserProfileStore {
  siteList: ProfileSites[];
  updateSiteList: (projects: ProfileProjects[], sites: ProfileSites[]) => void;
  baseEmail: string;
  updateBaseEmail: (userEmail: string) => void;
  getUserEmailAlias: (siteId: number) => Promise<string | null>;
}

const useUserProfile = create<UserProfileStore>((set, get) => ({
  siteList: [],
  updateSiteList: (projects, sites) => {
    const projectsToSites = projects.flatMap(project => project.sites);
    const sitesUser = [...sites];
    const combinedSites = [...projectsToSites, ...sitesUser];
    const uniqueSites = Array.from(
      new Map(combinedSites.map(site => [site.id, site])).values(),
    );
    set({ siteList: uniqueSites });
  },

  baseEmail: '',
  updateBaseEmail: userEmail => set({ baseEmail: userEmail }),

  getUserEmailAlias: async siteId => {
    // const { siteList, baseEmail } = get();
    // const findSite = siteList.find(site => site.id === siteId);
    // if (findSite) return findSite.email_alias ?? baseEmail;
    // return baseEmail;

    try {
      const resp = await fetchUserAlias(siteId);
      return resp.email_alias;
    } catch (err) {
      return null;
    }
  },
}));

export default useUserProfile;
