import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import GlobalNavigation from '../components/GlobalNavigation/GlobalNavigation';
import IdleTimer from '../components/IdleTimer/IdleTimer';
import Context from '../context/UserContext';
import GeneralContext from '../context/GeneralActions';
import { UserResponse, DefaultAuth } from '../models/User';
import routesNames from './customRoutes';
import {
  GeneralContextInterface,
  GeneralContextObj,
  LoadingContextInterface,
  RequestContextInterface,
  RequestContextObj,
} from '../models/Context';
import RequestContext from '../context/RequestContext';
import LoadingContext from '../context/LoadingContext';
import { ENVIRONMENTS, ROLES_USER_VALUES } from '../constants/user';
import { isUserRouteValid, sendRoute } from '../helpers/helpersfunctions';
import Spinner from '../components/Loading/Spinner';
import { getProfileInfo } from '../helpers/user';
import useUserProfile from '../stores/user/useUserProfile';

const adminRoles = ['administrator', 'dev admin'];

const PrivateRoute = () => {
  const location = useLocation();
  const [loadingUser, setLoadingUser] = useState(false);
  // const { updateSiteList, updateBaseEmail } = useUserProfile();
  const [isLoadingContext, setIsLoading] = useState<LoadingContextInterface>({
    loading: false,
  });
  const [UserContext, setUserContext] = useState<UserResponse>(DefaultAuth);
  const [GlobalContext, setGlobalContext] =
    useState<GeneralContextInterface>(GeneralContextObj);

  const [RequestsContext, setRequestsContext] =
    useState<RequestContextInterface>(RequestContextObj);

  const isLogged: UserResponse | false = JSON.parse(
    localStorage.getItem('backlink.user') ?? 'false',
  );
  const value: UserResponse | false =
    isLogged || JSON.parse(sessionStorage.getItem('backlink.user') ?? 'false');

  const getInfoOfUser = async () => {
    const valueRole: string = JSON.parse(
      localStorage.getItem('backlink.roletemp') ?? JSON.stringify(''),
    );
    setLoadingUser(true);
    try {
      const currentUser = await getProfileInfo();
      // updateSiteList(currentUser.profile.projects, currentUser.profile.sites);
      // updateBaseEmail(currentUser.email);
      const userValue = value as UserResponse;
      const validationRole =
        valueRole === '' ? currentUser.profile.role : valueRole;
      setUserContext({
        ...userValue,
        avatar: currentUser.profile.avatar,
        real_role: currentUser.profile.role,
        profile: {
          ...userValue.profile,
          training_env_allowed: currentUser.profile.training_env_allowed,
          role:
            adminRoles.includes(currentUser.profile.role) ||
            (currentUser.profile.training_env_allowed &&
              ROLES_USER_VALUES.includes(valueRole) &&
              ENVIRONMENTS.includes(process.env.REACT_APP_ENV ?? ''))
              ? validationRole
              : currentUser.profile.role,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    if (value) {
      getInfoOfUser();
    }
  }, []);

  const UserContextMemo = React.useMemo(
    () => ({ UserContext, setUserContext }),
    [UserContext],
  );

  const GeneralContextMemo = React.useMemo(
    () => ({ GlobalContext, setGlobalContext }),
    [GlobalContext],
  );

  const RequestContextMemo = React.useMemo(
    () => ({ RequestsContext, setRequestsContext }),
    [RequestsContext],
  );

  const LoadingContextMemo = React.useMemo(
    () => ({ isLoadingContext, setIsLoading }),
    [isLoadingContext],
  );

  return (
    <>
      {!value && <Navigate to={routesNames.login} />}
      {loadingUser && <Spinner />}
      {value && UserContext.id !== 0 && !loadingUser && (
        <>
          {UserContext.is_superuser ? (
            <Context.Provider value={UserContextMemo}>
              <GeneralContext.Provider value={GeneralContextMemo}>
                <RequestContext.Provider value={RequestContextMemo}>
                  <LoadingContext.Provider value={LoadingContextMemo}>
                    <IdleTimer>
                      <GlobalNavigation>
                        <Outlet />
                      </GlobalNavigation>
                    </IdleTimer>
                  </LoadingContext.Provider>
                </RequestContext.Provider>
              </GeneralContext.Provider>
            </Context.Provider>
          ) : (
            <>
              {!isUserRouteValid(
                UserContext.profile.role,
                location.pathname,
              ) && <Navigate to={sendRoute(UserContext.profile.role)} />}
              {isUserRouteValid(
                UserContext.profile.role,
                location.pathname,
              ) && (
                <Context.Provider value={UserContextMemo}>
                  <GeneralContext.Provider value={GeneralContextMemo}>
                    <RequestContext.Provider value={RequestContextMemo}>
                      <LoadingContext.Provider value={LoadingContextMemo}>
                        <IdleTimer>
                          <GlobalNavigation>
                            <Outlet />
                          </GlobalNavigation>
                        </IdleTimer>
                      </LoadingContext.Provider>
                    </RequestContext.Provider>
                  </GeneralContext.Provider>
                </Context.Provider>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PrivateRoute;
