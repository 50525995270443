import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { SeverityType } from '../models/General';

type VerticalType = 'top' | 'bottom';
type HorizontalType = 'left' | 'center' | 'right';

interface FeedbackProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  vertical: VerticalType;
  horizontal: HorizontalType;
  severity: SeverityType;
  message: string;
}

const FeedbackMessage = ({
  open,
  setOpen,
  vertical,
  horizontal,
  severity,
  message,
}: FeedbackProps) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity={severity}
        sx={{ marginTop: '55px' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FeedbackMessage;
