import {
  Box,
  FormControlLabel,
  IconButton,
  InputLabel,
  Modal,
  Typography,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, useContext, useRef } from 'react';
import Context from '../../../context/UserContext';
import { createTemplate, updateTemplate } from '../../../helpers/templates';
import { GlobalContextType } from '../../../models/Context';
import { PropsTemplateForm } from '../../../models/Forms';
import {
  BasicArray,
  SeverityType,
  statusOptions,
} from '../../../models/General';
import { CreateTemplateInt } from '../../../models/Templates';
import { UserContextType } from '../../../models/User';
import { ApproveRejectButton, MainButton } from '../../buttons';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import FeedbackMessage from '../../FeedbackMessage';
import TabPanel from '../../Tab/TabPanel';
import TabParent from '../../Tab/TabParent';
import { FlexBox, FlexBoxBetween } from '../../tableItems';
import BoxAddSettings from '../BoxAddSettings';
import {
  BasicSelect,
  OwnersSelectLinkFormField,
  SelectFormInput,
  TextFormField,
} from '../FormInputs';
import FormNotes from '../FormNotes';
import StatusModal from '../StatusModal';
import { DefaultCheckbox, TextInputField } from '../../inputs';
import useOwners from '../../../customHooks/useOwners';
import useNotes from '../../../customHooks/useNotes';
import useSites from '../../../customHooks/useSites';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import {
  IS_USER_ADMIN_AND_STRAT,
  ROLES_TEMPLATES,
} from '../../../constants/user';
import GeneralContext from '../../../context/GeneralActions';
import { fetchSitesAudiencesAffinities } from '../../../helpers/sites';
import CustomToolbar from '../../../pages/DefaultView/LinkForm/CustomToolBar';
import useSiteStore from '../../../stores/sites/sites';

const tabs = [
  { id: 0, label: 'Details' },
  { id: 1, label: 'Notes' },
];

const optionsMail = [
  // { id: 0, label: 'C First Name', value: '[FIRST NAME]' },
  // { id: 1, label: 'C Last Name', value: '[LAST NAME]' },
  { id: 2, label: 'Outreach URL', value: '[URL]' },
  { id: 3, label: 'U First Name', value: '[USER FIRST NAME]' },
  { id: 4, label: 'U Last Name', value: '[USER LAST NAME]' },
  // { id: 5, label: 'Unsubscribe URL', value: '[UNSUBSCRIBE]' },
];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 3,
  pb: 2,
};
const allSites = ['manager', 'account manager', 'administrator', 'dev admin'];
const audAffEdit = [
  'linkbuilder',
  'manager',
  'strategist',
  'administrator',
  'dev admin',
];

const AddTemplate = ({
  setOpenDrawer,
  type,
  templateObj,
  tabNumber,
  showMessage,
  review,
}: PropsTemplateForm) => {
  const quillRef = useRef<ReactQuill | null>(null);
  const { userSite, updateUserSite } = useSiteStore();

  const abortController = useRef<AbortController | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;

  const [openModal, setOpenModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loadingAudAff, setLoadingAudAff] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const { UserContext } = useContext(Context) as UserContextType;

  const [tabDrawer, setTabDrawer] = useState(tabNumber ?? 0);

  const [messagesProps, setMessagesProps] = useState<{
    message: string;
    severity: SeverityType;
  }>({
    message: '',
    severity: 'error',
  });
  const [openMessage, setOpenMessage] = useState(false);

  const { sites } = useSites(
    type === 'edit'
      ? { minimal: true }
      : { userId: UserContext.id, minimal: true },
  );
  const { owners } = useOwners({ withFields: true });
  const [affinities, setAffinities] = useState<BasicArray[]>([]);
  const [audiences, setAudiences] = useState<BasicArray[]>([]);

  const transformContent = (content: string) => {
    return content.replace(/\n/g, '<br>');
  };

  const sanitizeQuillContent = (content: string) => {
    return content.replace(/<p><br><\/p>/g, '\n');
  };

  const [templateForm, setTemplateForm] = useState<CreateTemplateInt>(
    templateObj
      ? { ...templateObj, body: transformContent(templateObj.body) }
      : {
          title: '',
          site: userSite ?? 0,
          owner: UserContext.id ?? 0,
          status: 'in review',
          affinity: 0,
          audience: 0,
          greetings: '',
          signoff: '',
          subject: '',
          automatic_greeting: true,
          body: '',
        },
  );
  const { notes, loadingNotes, refreshNotes } = useNotes({
    id: templateForm.id ?? 0,
    type: 'template',
  });

  const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const handleUndo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.undo();
    }
  };

  const handleRedo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.redo();
    }
  };

  const inputsArr = [
    {
      id: 0,
      value: templateForm.title,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={
            type === 'view' ||
            (type === 'edit' &&
              !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role))
          }
          nameInput="name"
          label="Name"
          error={false}
          value={templateForm.title}
          errorText=""
          placeholder="Name"
          onChange={(value: string) =>
            setTemplateForm({ ...templateForm, title: value })
          }
        />
      ),
    },
    {
      id: 1,
      value: templateForm.site === 0 ? '' : 'true',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          options={sites}
          disabled={
            type === 'view' ||
            (type === 'edit' &&
              !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role))
          }
          loading
          placeholder="Sites"
          value={
            sites.find(item => item.id === templateForm.site) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setTemplateForm({
              ...templateForm,
              site: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 2,
      value: templateForm.owner === 0 ? '' : 'true',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <OwnersSelectLinkFormField
          error={false}
          required
          errorText=""
          value={templateForm.owner}
          onChangeFunction={(value: number) =>
            setTemplateForm({ ...templateForm, owner: value })
          }
          options={owners}
          nameInput="owner"
          label="Author"
          disabled={
            type === 'view' ||
            (type === 'edit' &&
              templateForm.owner !== 0 &&
              !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role)) ||
            owners.length === 0
          }
        />
      ),
    },
    {
      id: 3,
      value: templateForm.audience === 0 ? '' : 'true',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          loading={loadingAudAff}
          options={audiences}
          disabled={
            audiences.length === 0 ||
            type === 'view' ||
            !audAffEdit.includes(UserContext.profile.role)
          }
          placeholder="Audience"
          value={
            audiences.find(item => item.id === templateForm.audience) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setTemplateForm({
              ...templateForm,
              audience: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 4,
      value: templateForm.affinity === 0 ? '' : 'true',
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          loading={loadingAudAff}
          options={affinities}
          disabled={
            affinities.length === 0 ||
            type === 'view' ||
            !audAffEdit.includes(UserContext.profile.role)
          }
          placeholder="Affinity"
          value={
            affinities.find(item => item.id === templateForm.affinity) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setTemplateForm({
              ...templateForm,
              affinity: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
  ];

  if (type === 'edit' || type === 'view') {
    const statusOp = [
      ...statusOptions,
      { label: 'In Review', value: 'in review' },
    ];
    inputsArr.splice(3, 0, {
      id: 7,
      value: templateForm.status,
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <BasicSelect
          label="Status"
          required
          nameInput="status"
          disabled={
            !sendBackObject(UserContext.profile.role, ROLES_TEMPLATES).edit
          }
          value={templateForm.status}
          options={statusOp}
          onChange={(value: string) => {
            setTemplateForm({ ...templateForm, status: value });
            // if (value !== originalState) setOpenModal(true);
            // else {
            //   setTemplateForm({
            //     ...templateForm,
            //     status: value,
            //   });
            // }
          }}
        />
      ),
    });
    inputsArr.splice(6, 0, {
      id: 5,
      value: templateForm.subject,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={
            !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) ||
            type === 'view'
          }
          nameInput="subject"
          label="Subject"
          error={false}
          value={templateForm.subject}
          errorText=""
          placeholder=""
          onChange={(value: string) =>
            setTemplateForm({ ...templateForm, subject: value })
          }
        />
      ),
    });
    inputsArr.splice(7, 0, {
      id: 6,
      value: templateForm.body,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <>
          <CustomToolbar onRedo={handleRedo} onUndo={handleUndo} />
          <ReactQuill
            theme="snow"
            style={{ minHeight: '200px', fontSize: '13px' }}
            className={`my-custom-editor ${
              !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role)
                ? 'disabled-custom-editor'
                : ''
            }`}
            readOnly={
              !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role)
            }
            ref={quillRef}
            value={templateForm.body}
            onChange={e =>
              setTemplateForm({
                ...templateForm,
                body: e,
              })
            }
            placeholder="Compose your message..."
            modules={{
              toolbar: {
                container: '#custom-toolbar',
                // handlers: customHandlers,
              },

              history: {
                delay: 1000,
                maxStack: 50,
                userOnly: true,
              },
            }}
          />
        </>
        // <TextFormField
        //   required
        //   multiline
        //   disabled={
        //     !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) ||
        //     type === 'view'
        //   }
        //   nameInput="body"
        //   label="Body"
        //   error={false}
        //   rows={12}
        //   value={stripHtml(templateForm.body)}
        //   errorText=""
        //   placeholder=""
        //   onChange={(value: string) =>
        //     setTemplateForm({ ...templateForm, body: value })
        //   }
        // />
      ),
    });
  }

  const updateStatus = (note: string) => {
    const newStatus = templateForm.status === 'active' ? 'inactive' : 'active';
    setTemplateForm({ ...templateForm, status: newStatus });
    setOpenModal(false);
  };

  const saveTemplate = async (status?: string) => {
    setLoadingSave(true);
    try {
      const obj = templateForm;
      const rawContent = quillRef.current?.getEditor().root.innerHTML || '';
      const sanitizedContent = sanitizeQuillContent(rawContent);
      obj.body = sanitizedContent;
      if (status) obj.status = status;
      if (type !== 'edit') {
        await createTemplate(templateForm);
        setOpenDrawer(false);
        showMessage(
          'This template has been submitted for review',
          'success',
          'add',
        );
        return;
      }
      await updateTemplate(obj, templateForm.id ?? 0);
      setOpenDrawer(false);
      const messg = status === 'active' ? 'approved' : 'rejected';
      showMessage(
        `Template has been successfully ${status ? messg : 'edited'}`,
        'success',
        'edit',
      );
    } catch (err) {
      const error = err as any;
      if (error.data.name) {
        setMessagesProps({
          severity: 'error',
          message: error.data.name[0],
        });
      } else {
        setMessagesProps({
          severity: 'error',
          message: 'There was an error, please try again',
        });
      }
      setOpenMessage(true);
    } finally {
      setLoadingSave(false);
    }
  };

  const getAudiencesAndAffinities = async (siteId: number) => {
    if (siteId === 0) return;

    setLoadingAudAff(true);
    abortController.current = new AbortController();
    try {
      const resp = await fetchSitesAudiencesAffinities(
        siteId,
        abortController.current.signal,
      );
      setAudiences(resp.audiences ?? []);
      setAffinities(resp.affinities ?? []);
      setLoadingAudAff(false);
    } catch (err) {
      if (err) {
        setAudiences([]);
        setAffinities([]);
        setLoadingAudAff(false);
      }
    }
  };

  const cancelRequest = () =>
    abortController.current && abortController.current.abort();

  const getOptions = () => {
    if (type === 'add')
      setTemplateForm({ ...templateForm, affinity: 0, audience: 0 });
    const site = sites.find(item => item.id === templateForm.site);
    getAudiencesAndAffinities(site?.id ?? 0);
  };

  useEffect(() => {
    if (templateForm.site !== 0) {
      setAudiences([]);
      setAffinities([]);
      cancelRequest();
      getOptions();
    }
  }, [templateForm.site, sites]);

  // useEffect(() => {
  //   if (sites.length > 0 && type !== 'add') {
  //     cancelRequest();
  //     getOptions();
  //   }
  // }, [sites]);

  useEffect(() => {
    if (
      type === 'view' &&
      !IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role)
    ) {
      setDisableButton(true);
      return;
    }
    const errors = inputsArr.filter(
      item => item.value.trim() === '' && item.required,
    );
    if (
      errors.length > 0 ||
      templateForm.subject === '' ||
      templateForm.body === '' ||
      templateForm.signoff === ''
    )
      setDisableButton(true);
    else setDisableButton(false);
  }, [templateForm]);

  const disa = type === 'view' || type === 'edit';

  const addMailMergeBody = (value: string) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      const cursorPosition = range ? range.index : 0;

      editor.insertText(cursorPosition, ` ${value} `);

      const updatedContent = editor.root.innerHTML;
      setTemplateForm({ ...templateForm, body: updatedContent });

      editor.setSelection(cursorPosition + value.length + 2);
    }
  };

  const addMailMerge = (
    variable: 'subject' | 'body' | 'signoff' | 'greetings',
    value: string,
  ) => {
    const input = document.getElementById(variable) as HTMLInputElement;
    const cursorPosition = input.selectionStart;
    const text = `${templateForm[variable].slice(
      0,
      cursorPosition ?? 0,
    )} ${value} ${templateForm[variable].slice(cursorPosition ?? 0)}`;
    setTemplateForm({ ...templateForm, [variable]: text });
  };

  return (
    <>
      <HeaderDrawer
        title={type !== 'clone' ? `${type} Template` : templateForm.title}
        threeButtons={false}
        loading={loadingSave}
        closeDrawer={setOpenDrawer}
        disabled={disableButton}
        closeConfirmation={
          type === 'clone' ? () => setOpenConfirmModal(true) : undefined
        }
        saveFunction={saveTemplate}
      />
      {(type === 'add' || type === 'clone') && (
        <>
          <BoxAddSettings title="General Info" mb={0}>
            <div className="form-grids">
              {inputsArr.slice(0, 3).map(input => (
                <div
                  key={input.id}
                  className={`mb-2 ${
                    input.fullSpace ? 'whole-grid_three' : ''
                  }`}
                >
                  {input.body}
                </div>
              ))}
            </div>
          </BoxAddSettings>
          <BoxAddSettings title="Audience & Affinity" mb={0} pt={3}>
            <div className="form-grids">
              {inputsArr.slice(3, 5).map(input => (
                <div
                  key={input.id}
                  className={`mb-2 ${
                    input.fullSpace ? 'whole-grid_three' : ''
                  }`}
                >
                  {input.body}
                </div>
              ))}
            </div>
          </BoxAddSettings>
          <BoxAddSettings title="Content" pt={3}>
            <div className="form-grids">
              <div className="mb-2 whole-grid_three">
                <InputLabel
                  htmlFor="subject"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Subject *
                </InputLabel>
                <Box marginBottom={1}>
                  {optionsMail.map(item => (
                    <button
                      key={item.id}
                      type="button"
                      disabled={disa}
                      className="mail-merge"
                      onKeyDown={e => e.preventDefault()}
                      onClick={() => addMailMerge('subject', item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </Box>
                <TextInputField
                  value={templateForm.subject}
                  name="subject"
                  disabled={disa}
                  size="small"
                  required
                  onChange={e =>
                    setTemplateForm({
                      ...templateForm,
                      subject: e.target.value,
                    })
                  }
                  fullWidth
                  type="text"
                  id="subject"
                />
              </div>

              <div className="mb-2 whole-grid_three">
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: 14,
                      color: '#000000',
                    },
                  }}
                  control={
                    <DefaultCheckbox
                      disabled={false}
                      sx={{ ml: 1 }}
                      size="medium"
                      checked={templateForm.automatic_greeting}
                      onClick={() =>
                        setTemplateForm({
                          ...templateForm,
                          automatic_greeting: !templateForm.automatic_greeting,
                        })
                      }
                    />
                  }
                  label="Generate Automatic Greeting"
                />
              </div>
              <div className="mb-2 whole-grid_three">
                <InputLabel
                  htmlFor="greetings"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Greetings *
                </InputLabel>
                <Box marginBottom={1}>
                  {optionsMail.map(item => (
                    <button
                      key={item.id}
                      type="button"
                      disabled={disa || templateForm.automatic_greeting}
                      className="mail-merge"
                      onKeyDown={e => e.preventDefault()}
                      onClick={() => addMailMerge('greetings', item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </Box>
                <TextInputField
                  value={templateForm.greetings}
                  name="greetings"
                  disabled={disa || templateForm.automatic_greeting}
                  size="small"
                  rows={2}
                  multiline
                  required
                  onChange={e =>
                    setTemplateForm({
                      ...templateForm,
                      greetings: e.target.value,
                    })
                  }
                  fullWidth
                  type="text"
                  id="greetings"
                />
              </div>
              <div className="mb-2 whole-grid_three">
                <InputLabel
                  htmlFor="body"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Body *
                </InputLabel>
                <Box marginBottom={1}>
                  {optionsMail.map(item => (
                    <button
                      key={item.id}
                      disabled={disa}
                      type="button"
                      className="mail-merge"
                      onKeyDown={e => e.preventDefault()}
                      onClick={() => addMailMergeBody(item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </Box>
                <Box mt={3}>
                  <CustomToolbar onRedo={handleRedo} onUndo={handleUndo} />
                  <ReactQuill
                    theme="snow"
                    style={{ minHeight: '200px', fontSize: '13px' }}
                    className="my-custom-editor"
                    readOnly={disa}
                    ref={quillRef}
                    value={templateForm.body}
                    onChange={e =>
                      setTemplateForm({
                        ...templateForm,
                        body: e,
                      })
                    }
                    placeholder="Compose your message..."
                    modules={{
                      toolbar: {
                        container: '#custom-toolbar',
                        // handlers: customHandlers,
                      },

                      history: {
                        delay: 1000,
                        maxStack: 50,
                        userOnly: true,
                      },
                    }}
                  />
                </Box>
                {/* <TextInputField
                  value={templateForm.body}
                  name="body"
                  disabled={disa}
                  size="small"
                  rows={12}
                  multiline
                  required
                  onChange={e =>
                    setTemplateForm({ ...templateForm, body: e.target.value })
                  }
                  fullWidth
                  type="text"
                  id="body"
                /> */}
              </div>
              <div className="mb-2 whole-grid_three">
                <InputLabel
                  htmlFor="signoff"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Sign Off *
                </InputLabel>
                <Box marginBottom={1}>
                  {optionsMail.map(item => (
                    <button
                      key={item.id}
                      type="button"
                      disabled={disa}
                      className="mail-merge"
                      onKeyDown={e => e.preventDefault()}
                      onClick={() => addMailMerge('signoff', item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </Box>
                <TextInputField
                  value={templateForm.signoff}
                  name="signoff"
                  disabled={disa}
                  size="small"
                  rows={2}
                  multiline
                  required
                  onChange={e =>
                    setTemplateForm({
                      ...templateForm,
                      signoff: e.target.value,
                    })
                  }
                  fullWidth
                  type="text"
                  id="signoff"
                />
              </div>
            </div>
          </BoxAddSettings>
        </>
      )}
      {(type === 'view' || type === 'edit') && (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginTop: 1.3,
              marginLeft: 3,
              marginRight: 4,
            }}
          >
            <TabParent
              valueTab={tabDrawer}
              setTab={setTabDrawer}
              tabs={tabs}
              center
            />
          </Box>
          <TabPanel index={0} value={tabDrawer}>
            <BoxAddSettings title="General Info" mb={0}>
              <div className="form-grids">
                {inputsArr.slice(0, 4).map(input => (
                  <div
                    key={input.id}
                    className={`mb-2 ${
                      input.fullSpace ? 'whole-grid_three' : ''
                    }`}
                  >
                    {input.body}
                  </div>
                ))}
              </div>
            </BoxAddSettings>
            <BoxAddSettings title="Audience & Affinity" mb={0} pt={3}>
              <div className="form-grids">
                {inputsArr.slice(4, 6).map(input => (
                  <div
                    key={input.id}
                    className={`mb-2 ${
                      input.fullSpace ? 'whole-grid_three' : ''
                    }`}
                  >
                    {input.body}
                  </div>
                ))}
              </div>
            </BoxAddSettings>
            {type === 'edit' &&
              IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) && (
                <BoxAddSettings title="Content" pt={3} mb={6}>
                  <div className="form-grids">
                    <div className="mb-2 whole-grid_three">
                      <InputLabel
                        htmlFor="subject"
                        sx={{
                          fontSize: 12,
                          fontWeight: '700',
                          color: 'rgba(0, 0, 0, 0.8)',
                          marginBottom: 1,
                        }}
                      >
                        Subject *
                      </InputLabel>
                      <Box marginBottom={1}>
                        {optionsMail.map(item => (
                          <button
                            key={item.id}
                            type="button"
                            disabled={
                              !IS_USER_ADMIN_AND_STRAT.includes(
                                UserContext.profile.role,
                              )
                            }
                            className="mail-merge"
                            onKeyDown={e => e.preventDefault()}
                            onClick={() => addMailMerge('subject', item.value)}
                          >
                            {item.label}
                          </button>
                        ))}
                      </Box>
                      <TextInputField
                        value={templateForm.subject}
                        name="subject"
                        disabled={
                          !IS_USER_ADMIN_AND_STRAT.includes(
                            UserContext.profile.role,
                          )
                        }
                        size="small"
                        required
                        onChange={e =>
                          setTemplateForm({
                            ...templateForm,
                            subject: e.target.value,
                          })
                        }
                        fullWidth
                        type="text"
                        id="subject"
                      />
                    </div>
                    <div className="mb-2 whole-grid_three">
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            color: '#000000',
                          },
                        }}
                        control={
                          <DefaultCheckbox
                            disabled={
                              !IS_USER_ADMIN_AND_STRAT.includes(
                                UserContext.profile.role,
                              )
                            }
                            sx={{ ml: 1 }}
                            size="medium"
                            checked={templateForm.automatic_greeting}
                            onClick={() =>
                              setTemplateForm({
                                ...templateForm,
                                automatic_greeting:
                                  !templateForm.automatic_greeting,
                              })
                            }
                          />
                        }
                        label="Generate Automatic Greeting"
                      />
                    </div>
                    <div className="mb-2 whole-grid_three">
                      <InputLabel
                        htmlFor="greetings"
                        sx={{
                          fontSize: 12,
                          fontWeight: '700',
                          color: 'rgba(0, 0, 0, 0.8)',
                          marginBottom: 1,
                        }}
                      >
                        Greetings *
                      </InputLabel>
                      <Box marginBottom={1}>
                        {optionsMail.map(item => (
                          <button
                            key={item.id}
                            type="button"
                            disabled={
                              !IS_USER_ADMIN_AND_STRAT.includes(
                                UserContext.profile.role,
                              ) || templateForm.automatic_greeting
                            }
                            className="mail-merge"
                            onKeyDown={e => e.preventDefault()}
                            onClick={() =>
                              addMailMerge('greetings', item.value)
                            }
                          >
                            {item.label}
                          </button>
                        ))}
                      </Box>

                      <TextInputField
                        value={templateForm.greetings}
                        name="greetings"
                        disabled={
                          !IS_USER_ADMIN_AND_STRAT.includes(
                            UserContext.profile.role,
                          ) || templateForm.automatic_greeting
                        }
                        size="small"
                        rows={2}
                        multiline
                        required
                        onChange={e =>
                          setTemplateForm({
                            ...templateForm,
                            greetings: e.target.value,
                          })
                        }
                        fullWidth
                        type="text"
                        id="greetings"
                      />
                    </div>
                    <div className="mb-2 whole-grid_three">
                      <InputLabel
                        htmlFor="body"
                        sx={{
                          fontSize: 12,
                          fontWeight: '700',
                          color: 'rgba(0, 0, 0, 0.8)',
                          marginBottom: 1,
                        }}
                      >
                        Body *
                      </InputLabel>
                      <Box marginBottom={1}>
                        {optionsMail.map(item => (
                          <button
                            key={item.id}
                            type="button"
                            disabled={
                              !IS_USER_ADMIN_AND_STRAT.includes(
                                UserContext.profile.role,
                              )
                            }
                            className="mail-merge"
                            onKeyDown={e => e.preventDefault()}
                            onClick={() => addMailMergeBody(item.value)}
                          >
                            {item.label}
                          </button>
                        ))}
                      </Box>
                      <Box mt={3}>
                        <CustomToolbar
                          onRedo={handleRedo}
                          onUndo={handleUndo}
                        />
                        <ReactQuill
                          theme="snow"
                          style={{ minHeight: '200px', fontSize: '13px' }}
                          className="my-custom-editor"
                          readOnly={
                            !IS_USER_ADMIN_AND_STRAT.includes(
                              UserContext.profile.role,
                            )
                          }
                          ref={quillRef}
                          value={templateForm.body}
                          onChange={e =>
                            setTemplateForm({
                              ...templateForm,
                              body: e,
                            })
                          }
                          placeholder="Compose your message..."
                          modules={{
                            toolbar: {
                              container: '#custom-toolbar',
                              // handlers: customHandlers,
                            },

                            history: {
                              delay: 1000,
                              maxStack: 50,
                              userOnly: true,
                            },
                          }}
                        />
                      </Box>
                      {/* <TextInputField
                        value={templateForm.body}
                        name="body"
                        disabled={
                          !IS_USER_ADMIN_AND_STRAT.includes(
                            UserContext.profile.role,
                          )
                        }
                        size="small"
                        rows={12}
                        multiline
                        required
                        onChange={e =>
                          setTemplateForm({
                            ...templateForm,
                            body: e.target.value,
                          })
                        }
                        fullWidth
                        type="text"
                        id="body"
                      /> */}
                    </div>
                    <div className="mb-2 whole-grid_three">
                      <InputLabel
                        htmlFor="signoff"
                        sx={{
                          fontSize: 12,
                          fontWeight: '700',
                          color: 'rgba(0, 0, 0, 0.8)',
                          marginBottom: 1,
                        }}
                      >
                        Sign Off *
                      </InputLabel>
                      <Box marginBottom={1}>
                        {optionsMail.map(item => (
                          <button
                            key={item.id}
                            type="button"
                            disabled={
                              !IS_USER_ADMIN_AND_STRAT.includes(
                                UserContext.profile.role,
                              )
                            }
                            className="mail-merge"
                            onKeyDown={e => e.preventDefault()}
                            onClick={() => addMailMerge('signoff', item.value)}
                          >
                            {item.label}
                          </button>
                        ))}
                      </Box>
                      <TextInputField
                        value={templateForm.signoff}
                        name="signoff"
                        disabled={
                          !IS_USER_ADMIN_AND_STRAT.includes(
                            UserContext.profile.role,
                          )
                        }
                        size="small"
                        rows={2}
                        multiline
                        required
                        onChange={e =>
                          setTemplateForm({
                            ...templateForm,
                            signoff: e.target.value,
                          })
                        }
                        fullWidth
                        type="text"
                        id="signoff"
                      />
                    </div>
                  </div>
                </BoxAddSettings>
              )}
            {!IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) && (
              <BoxAddSettings
                title="Content"
                pt={3}
                mb={
                  IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) &&
                  type === 'edit'
                    ? 6
                    : undefined
                }
              >
                <div className="form-grids">
                  {inputsArr.slice(6).map(input => (
                    <div
                      key={input.id}
                      className={`mb-2 ${
                        input.fullSpace ? 'whole-grid_three' : ''
                      }`}
                    >
                      {input.body}
                    </div>
                  ))}
                </div>
                <div className="mb-2 whole-grid_three">
                  <InputLabel
                    htmlFor="signoff"
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    Sign Off *
                  </InputLabel>
                  <Box marginBottom={1}>
                    {optionsMail.map(item => (
                      <button
                        key={item.id}
                        type="button"
                        disabled={disa}
                        className="mail-merge"
                        onKeyDown={e => e.preventDefault()}
                        onClick={() => addMailMerge('signoff', item.value)}
                      >
                        {item.label}
                      </button>
                    ))}
                  </Box>
                  <TextInputField
                    value={templateForm.signoff}
                    name="signoff"
                    disabled={disa}
                    size="small"
                    rows={2}
                    multiline
                    required
                    onChange={e =>
                      setTemplateForm({
                        ...templateForm,
                        signoff: e.target.value,
                      })
                    }
                    fullWidth
                    type="text"
                    id="signoff"
                  />
                </div>
              </BoxAddSettings>
            )}
          </TabPanel>
          <TabPanel value={tabDrawer} index={1}>
            <BoxAddSettings>
              <FormNotes
                getNotes={refreshNotes}
                loadingNotes={loadingNotes}
                type="template"
                notes={notes}
                id={templateForm.id ?? 0}
                setMessage={setMessagesProps}
                openMessage={setOpenMessage}
              />
            </BoxAddSettings>
          </TabPanel>
        </>
      )}
      <StatusModal
        open={openModal}
        closeModal={setOpenModal}
        updateStatus={updateStatus}
      />
      <Modal open={openConfirmModal}>
        <Box sx={style}>
          <FlexBoxBetween>
            <Typography fontWeight={700} fontSize={17}>
              Warning Template
            </Typography>
            <IconButton onClick={() => setOpenConfirmModal(false)}>
              <CloseIcon />
            </IconButton>
          </FlexBoxBetween>
          <Typography color="#373737" fontSize={14}>
            This template will not be cloned, all unsaved changes will be
            discarded
          </Typography>
          <Box marginTop={3}>
            <FlexBox marginTop={3} justifyContent="end">
              <MainButton
                onClick={() => setOpenConfirmModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                sx={{ marginX: 1.5 }}
                disabled={false}
                onClick={() => {
                  setOpenConfirmModal(false);
                  setOpenDrawer(false);
                }}
              >
                Okay
              </MainButton>
            </FlexBox>
          </Box>
        </Box>
      </Modal>
      {sendBackObject(UserContext.profile.role, ROLES_TEMPLATES).approve &&
        type === 'edit' &&
        review && (
          <Box
            className="drawer-width"
            sx={{
              background: 'white',
              position: 'fixed',
              bottom: 0,
              paddingY: 1,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <FlexBox>
                <ApproveRejectButton
                  onClick={() => saveTemplate('rejected')}
                  sx={{
                    background: '#E10000',
                    marginRight: 2,
                    '&:hover': { background: '#F40F0F' },
                  }}
                >
                  Reject
                </ApproveRejectButton>
                <ApproveRejectButton
                  onClick={() => saveTemplate('active')}
                  sx={{
                    background: '#5CB85C',
                    '&:hover': { background: '#69C969' },
                  }}
                >
                  Approve
                </ApproveRejectButton>
              </FlexBox>
            </Box>
          </Box>
        )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
    </>
  );
};

export default AddTemplate;
