/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React, { useContext } from 'react';
import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import { FormButtons, MainButton } from '../../../../components/buttons';
import useLinkForm from '../../../../stores/links/useLinkForm';
import LoadingContext from '../../../../context/LoadingContext';
import { LoadingContextType } from '../../../../models/Context';

const steps = [
  {
    label: 'Link Details',
  },
  { label: 'Contacts' },
];

interface AddFormHeaderProps {
  activeStep: number;
  saveLink: () => void;
  updateStep: (step: number) => void;
  loadingForm: boolean;
  stepOneFormValid: boolean;
  stepTwoFormValid: boolean;
  updateOpenWarning: (value: boolean) => void;
  validateClear: boolean;
  handleEmailSent: () => void;
}

const AddFormHeader: React.FC<AddFormHeaderProps> = ({
  activeStep,
  saveLink,
  updateStep,
  loadingForm,
  stepOneFormValid,
  stepTwoFormValid,
  updateOpenWarning,
  validateClear,
  handleEmailSent,
}) => {
  const { linkForm } = useLinkForm();
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      saveLink();
      return;
    }

    updateStep(activeStep + 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};

          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption" fontSize={11}>
          //       Optional
          //     </Typography>
          //   );
          // }

          return (
            <Step key={index} {...stepProps}>
              <StepLabel
                {...labelProps}
                sx={{
                  '.MuiStepLabel-label': {
                    fontSize: 12,
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box mt={1} mb={2}>
        <FlexBoxBetween>
          <FlexBox>
            <FormButtons
              loading={false}
              variant="contained"
              sx={{
                marginX: 1,
                fontSize: 11,

                backgroundColor: '#FFFFFF',
                color: 'black',
                borderColor: 'grey',
                border: 1,
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },

                '@media (min-width: 1900px)': {
                  fontSize: 14,
                },
              }}
              disabled={activeStep === 0 || loadingForm}
              onClick={() => updateStep(activeStep - 1)}
            >
              Back
            </FormButtons>
            <MainButton
              onClick={() => updateOpenWarning(true)}
              disabled={validateClear}
              variant="contained"
              sx={{
                marginRight: '',

                fontSize: 11,
                backgroundColor: '#FFFFFF',
                color: 'black',
                border: '1px solid grey',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },

                '@media (min-width: 1900px)': {
                  fontSize: 14,
                },
              }}
            >
              Cancel
            </MainButton>
          </FlexBox>
          <FlexBox>
            <FormButtons
              loading={loadingForm}
              variant="contained"
              sx={{
                marginX: 1,
                background: '#BAF372',
                fontSize: 11,
                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },

                '@media (min-width: 1900px)': {
                  fontSize: 14,
                },
              }}
              disabled={
                activeStep === 0
                  ? !stepOneFormValid
                  : stepTwoFormValid || loadingForm || isLoadingContext.loading
              }
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Save' : 'Next'}
            </FormButtons>
            {activeStep === steps.length - 1 && (
              <FormButtons
                loading={loadingForm}
                variant="contained"
                sx={{
                  fontSize: 11,
                  backgroundColor: '#84D0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#84D0F0',
                  },

                  '@media (min-width: 1900px)': {
                    fontSize: 14,
                  },
                }}
                disabled={
                  stepTwoFormValid ||
                  isLoadingContext.loading ||
                  loadingForm ||
                  linkForm.contacts.filter(item => item.is_default).length ===
                    0 ||
                  linkForm.contacts.filter(
                    item => item.status !== 'invalid_contact',
                  ).length === 0
                }
                onClick={handleEmailSent}
              >
                Save + Email
              </FormButtons>
            )}
          </FlexBox>
        </FlexBoxBetween>
      </Box>
    </Box>
  );
};

export default AddFormHeader;
