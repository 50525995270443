/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { SelectFormInput } from '../../../components/Forms/FormInputs';
import { BasicArray, SeverityType } from '../../../models/General';
import { FormButtons, MainButton } from '../../../components/buttons';
import { TextInputField } from '../../../components/inputs';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import {
  fetchTemplatesFilter,
  getTemplateTranslateEmail,
} from '../../../helpers/templates';
import {
  checkLimitOutreach,
  getTimeFrames,
  loadDraftFromDraftId,
  scheduleSendEmail,
} from '../../../helpers/links';
import useLinkForm from '../../../stores/links/useLinkForm';
import useSidebar from '../../../stores/links/useSidebar';
import ScheduleSend from '../../DefaultView/LinkForm/ScheduleSend';
import PreviewEmail from '../../DefaultView/LinkForm/PreviewEmail';
import CustomToolbar from '../../DefaultView/LinkForm/CustomToolBar';
import useUserProfile from '../../../stores/user/useUserProfile';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: { lg: 'translate(-60%, -50%)', xl: 'translate(-50%, -50%)' },
  width: { xs: '90%', sm: 600, md: 800, lg: 800, xl: 900 },
  maxHeight: { xs: '80vh', sm: '70vh', lg: '80vh', xl: '100vh' },
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 2,
  pt: 2,
  pb: 3,
  zIndex: 1100,
};

type ContactsEmail = {
  name: string;
  id: number;
  email: string;
};

interface LinkEmailSaveProps {
  open: boolean;
  closeModal: () => void;
  contactDraft: ContactsEmail;
  url: string;
  linkId: number;
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  draftId: number;
  fetchLogsResponse: () => Promise<void>;
}

type ArrayOptions = {
  name: string;
  id: number;
};

const sanitizeQuillContent = (content: string) => {
  return content.replace(/<p><br><\/p>/g, '\n');
};

const DraftSend: React.FC<LinkEmailSaveProps> = ({
  open,
  closeModal,
  contactDraft,
  url,
  linkId,
  handleFeedbackMessage,
  draftId,
  fetchLogsResponse,
}) => {
  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);
  const { getUserEmailAlias } = useUserProfile();

  const [siteId, setSiteId] = useState(0);
  const [userAlias, setUserAlias] = useState<string | null>(null);

  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const closeModalPreview = () => setOpenPreviewEmail(false);
  const openModalPreview = () => setOpenPreviewEmail(true);

  const isFirstRender = useRef(true);

  const quillRef = useRef<ReactQuill | null>(null);

  const [loadingSend, setLoadingSend] = useState(false);

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState<ArrayOptions[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<ArrayOptions[]>([]);
  const [templateOptions, setTemplateOptions] = useState<ArrayOptions[]>([]);

  const { UserContext } = useContext(Context) as UserContextType;
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [unsubscribeHtml, setUnsubscribeHtml] = useState('');
  const [signature, setSignature] = useState('');
  const [pixelTracker, setPixelTracker] = useState('');

  const [audienceSelected, setAudienceSelected] = useState(0);
  const [affinitySelected, setAffinitySelected] = useState(0);
  const [templateSelected, setTemplateSelected] = useState(0);

  const [loadingBody, setLoadingBody] = useState(false);

  const [scheduleSendModal, setScheduleSendModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimeBlock, setSelectedTimeBlock] = useState('');
  const [timeFrames, setTimeFrames] = useState<
    {
      block_title: string;
      block_start: string;
      scheduled_messages: number;
      is_full: false;
    }[]
  >([]);
  const [snoozedUntil, setSnoozedUntil] = useState(7);

  const [loadAllDraft, setLoadAllDraft] = useState(true);

  const [maxCapReached, setMaxCapReached] = useState(false);
  const [loadingMaxCap, setLoadingMaxCap] = useState(false);

  const handleBodyChange = (content: string) => setBody(content);

  const getBlockTimes = async () => {
    setTimeFrames([]);
    setSelectedTimeBlock('');
    try {
      const dateNow = dayjs(selectedDate).format('YYYY-MM-DD');
      const resp = await getTimeFrames(dateNow);
      setTimeFrames(resp.blocks);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedDate === '') {
      setTimeFrames([]);
      setSelectedTimeBlock('');
    } else getBlockTimes();
  }, [selectedDate]);

  const transformContent = (content: string) => {
    return content.replace(/\n/g, '<br>');
  };

  const getBodyTemplate = async () => {
    setLoadingBody(true);
    try {
      const resp = await getTemplateTranslateEmail(
        templateSelected,
        encodeURIComponent(url),
        linkId,
        encodeURIComponent(contactDraft.name),
        encodeURIComponent(contactDraft.email),
      );
      setBody(transformContent(`${resp.body}`));
      setUnsubscribeHtml(resp.unsubscribe_html);
      setSignature(resp.signature_html);
      setPixelTracker(resp.pixel_tracker_url);
      setSubject(resp.subject);
    } catch (err) {
      setBody('');
    } finally {
      setLoadingBody(false);
    }
  };

  useEffect(() => {
    if (templateSelected !== 0 && !loadAllDraft) getBodyTemplate();
  }, [templateSelected]);

  useEffect(() => {
    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
    };
  }, []);

  const handleUndo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.undo();
    }
  };

  const handleRedo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.redo();
    }
  };

  const handleSend = async (scheduled_mail?: boolean) => {
    setLoadingSend(true);
    const rawContent = quillRef.current?.getEditor().root.innerHTML || '';
    const sanitizedContent = sanitizeQuillContent(rawContent);
    const object: any = {
      link_id: linkId,
      contact_id: contactDraft.id,
      audience_id: audienceSelected,
      affinity_id: affinitySelected,
      template_id: templateSelected,
      site_id: siteId,
      is_sender_user: true,
      body: sanitizedContent,
      subject,
      signature_html: signature,
      unsubscribe_html: unsubscribeHtml,
      pixel_tracker_url: pixelTracker,
      send_status: 'queued',
    };
    if (draftId !== 0) object.draft_id = draftId;
    const dateTransformed = dayjs(selectedDate).format('YYYY-MM-DD');
    if (scheduled_mail) {
      const findTitleBlock = timeFrames.find(
        item => item.block_start === selectedTimeBlock,
      );

      const currentHour = dayjs().format('HH:mm:ss');
      object.send_status = 'scheduled';
      object.scheduled_date = selectedTimeBlock
        ? `${dateTransformed} ${selectedTimeBlock}`
        : `${dateTransformed} ${currentHour}`;

      if (findTitleBlock)
        object.scheduled_range = `${dateTransformed} ${findTitleBlock.block_title}`;
    }
    if (snoozedUntil) object.snoozed_days = snoozedUntil;

    try {
      await scheduleSendEmail(object);
      handleFeedbackMessage(
        `Email ${
          selectedTimeBlock ? 'scheduled successfully' : 'sent successfully'
        }`,
        'success',
      );
      setScheduleSendModal(false);
      fetchLogsResponse();

      closeModal();
    } catch (err) {
      handleFeedbackMessage('Error sending email', 'error');
    } finally {
      setLoadingSend(false);
    }
  };

  const fetchDraftAud = async (site_id: number) => {
    setLoadingAud(true);

    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        site_id,
        abortControllerAudience.current.signal,
      );
      setAudienceOptions(resp);
    } catch (err) {
      setAudienceOptions([]);
    } finally {
      setLoadingAud(false);
    }
  };

  const fetchDraftAff = async (audId: number, site_id: number) => {
    setLoadingAff(true);

    if (!isFirstRender.current) {
      console.log('entro aca?');
      setAffinityOptions([]);
      setTemplateOptions([]);
      setAffinitySelected(0);
      setTemplateSelected(0);
      cancelRequestAff();
      cancelRequestTemplate();
    }

    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        audId,
        site_id,
        abortControllerAffinity.current.signal,
      );
      setAffinityOptions(resp);
    } catch (err) {
      setAffinityOptions([]);
    } finally {
      setLoadingAff(false);
    }
  };

  const fetchDraftTemp = async (
    audId: number,
    affId: number,
    site_id: number,
  ) => {
    setLoadingTemplates(true);

    if (!isFirstRender.current) {
      setTemplateSelected(0);
      setTemplateOptions([]);
      cancelRequestTemplate();
    }

    try {
      const resp = await fetchTemplatesFilter(audId, affId, site_id);

      const tempsFilter = resp.filter(template => template.status === 'active');

      setTemplateOptions(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
    } catch (err) {
      setTemplateOptions([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (
      !isFirstRender.current &&
      affinitySelected !== 0 &&
      audienceSelected !== 0
    )
      fetchDraftTemp(audienceSelected, affinitySelected, siteId);
  }, [affinitySelected]);

  useEffect(() => {
    if (!isFirstRender.current && audienceSelected !== 0)
      fetchDraftAff(audienceSelected, siteId);
  }, [audienceSelected]);

  const fetchDraftInformation = async () => {
    try {
      const resp = await loadDraftFromDraftId(draftId);
      if (resp.site_id) {
        setSiteId(resp.site_id);
        const respAlias = await getUserEmailAlias(resp.site_id);
        setUserAlias(respAlias);
        setAudienceSelected(resp.audience_id);
        setAffinitySelected(resp.affinity_id);
        setTemplateSelected(resp.template_id);
        await Promise.all([
          fetchDraftAud(resp.site_id),
          fetchDraftAff(resp.audience_id, resp.site_id),
          fetchDraftTemp(resp.audience_id, resp.affinity_id, resp.site_id),
        ]);

        setBody(transformContent(resp.email_body));
        setSubject(resp.email_subject);
        setUnsubscribeHtml(resp.unsubscribe_html);
        setSignature(resp.signature_html);
        setPixelTracker(resp.pixel_tracker_url);
        if (isFirstRender.current) isFirstRender.current = false;
      } else throw new Error('Error while loading draft');
    } catch (err) {
      console.log(err);
    } finally {
      setLoadAllDraft(false);
    }
  };

  const checkUsersOutreach = async () => {
    setLoadingMaxCap(true);
    try {
      const resp = await checkLimitOutreach();
      setMaxCapReached(resp.outreach_limit_reached);
      if (resp.outreach_limit_reached)
        handleFeedbackMessage(
          'Maximum outreach limit for this timeframe has been met. Please select an available timeslot to proceed with outreach.',
          'error',
        );
    } catch (err) {
      handleFeedbackMessage('Error checking users hourly cap', 'error');
      setMaxCapReached(true);
    } finally {
      setLoadingMaxCap(false);
    }
  };

  useEffect(() => {
    if (open) {
      setSiteId(0);
      setUserAlias(null);
      setOpenPreviewEmail(false);
      setLoadingSend(false);
      setLoadingAud(false);
      setLoadingAff(false);
      setLoadingTemplates(false);
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
      setSubject('');
      setBody('');
      setUnsubscribeHtml('');
      setSignature('');
      setPixelTracker('');
      setAudienceSelected(0);
      setAffinitySelected(0);
      setTemplateSelected(0);
      setLoadingBody(false);
      setSelectedDate('');
      setSelectedTimeBlock('');
      setTimeFrames([]);
      setSnoozedUntil(7);
      setLoadAllDraft(true);
      setMaxCapReached(false);
      if (draftId !== 0) {
        const dateToday = dayjs().format('MM/DD/YYYY');
        setSelectedDate(dateToday);
        Promise.all([fetchDraftInformation(), checkUsersOutreach()]);
      }
    } else {
      isFirstRender.current = true;
    }
  }, [open, draftId]);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      disableRestoreFocus
    >
      <Box sx={style}>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: 8000,
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          open={loadAllDraft}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FlexBoxBetween>
          <Typography fontWeight={600} fontSize={17} textTransform="capitalize">
            Initial Outreach Attempt
          </Typography>
          <IconButton
            onClick={() => {
              closeModal();
            }}
            sx={{ fontSize: 22, border: 1 }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </FlexBoxBetween>
        <Divider sx={{ mb: 2, mt: 1 }} />

        <FlexBox gap={1}>
          <Typography fontSize={15} fontWeight={600}>
            Opportunity:
          </Typography>
          <Link
            href={url}
            target="_blank"
            color="#373737"
            underline="hover"
            fontSize={14}
          >
            {url}
          </Link>
        </FlexBox>

        <Box my={2}>
          <FlexBox>
            <Typography fontSize={16} mr={1}>
              From:
            </Typography>
            <Chip
              sx={{
                color: !userAlias ? 'red' : '',
                fontWeight: 700,
              }}
              label={
                userAlias ??
                'No email configuration found, please contact your administrator!'
              }
              variant="outlined"
            />
          </FlexBox>
          <FlexBox mt={2} gap={1}>
            <Typography fontSize={16} mr={1}>
              To:
            </Typography>
            <FormControl fullWidth>
              <SelectFormInput
                loading={false}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={[contactDraft]}
                disabled
                placeholder=""
                value={{
                  id: contactDraft.id,
                  name:
                    contactDraft.email !== ''
                      ? contactDraft.email
                      : contactDraft.name,
                }}
                onChange={(value: BasicArray | null) => {}}
              />
            </FormControl>
          </FlexBox>

          <FlexBox mt={2.5} pr={2}>
            <Typography fontSize={16} mr={1}>
              Subject
            </Typography>
            <TextInputField
              value={subject}
              name="subject"
              disabled={false}
              error={false}
              onChange={e => setSubject(e.target.value)}
              size="small"
              placeholder="Subject"
              id="url"
              helperText={false}
              fullWidth
            />
          </FlexBox>
          <Grid container spacing={2} width="100%" mt={1}>
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAud}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={audienceOptions}
                disabled={loadingAud || audienceOptions.length === 0}
                placeholder="Audience"
                value={
                  audienceOptions.find(
                    item => item.id === audienceSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAudienceSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAff}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={affinityOptions}
                disabled={loadingAff || affinityOptions.length === 0}
                placeholder="Affinity"
                value={
                  affinityOptions.find(
                    item => item.id === affinitySelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAffinitySelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingTemplates}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={templateOptions}
                disabled={templateOptions.length === 0 || loadingTemplates}
                placeholder="Template"
                value={
                  templateOptions.find(
                    item => item.id === templateSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setTemplateSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <CustomToolbar onRedo={handleRedo} onUndo={handleUndo} />
            <ReactQuill
              theme="snow"
              style={{ minHeight: '200px', fontSize: '13px' }}
              className="my-custom-editor"
              readOnly={loadingBody}
              ref={quillRef}
              value={body}
              onChange={handleBodyChange}
              placeholder="Compose your message..."
              modules={{
                toolbar: {
                  container: '#custom-toolbar',
                  // handlers: customHandlers,
                },

                history: {
                  delay: 1000,
                  maxStack: 50,
                  userOnly: true,
                },
              }}
            />
          </Box>
          <MainButton
            variant="contained"
            onClick={openModalPreview}
            disabled={body === ''}
            loading={false}
            sx={{ mt: 2 }}
            startIcon={<RemoveRedEyeIcon />}
          >
            Preview
          </MainButton>

          <FlexBox mt={3} gap={2}>
            <FormButtons
              variant="contained"
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                subject === '' ||
                !userAlias
              }
              loading={loadingSend}
              sx={{
                backgroundColor: '#84D0F0',
                py: '5px',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#84D0F0',
                },
              }}
              onClick={() => setScheduleSendModal(true)}
            >
              Schedule Send
            </FormButtons>
            <FormButtons
              loading={loadingSend}
              variant="contained"
              sx={{
                background: '#BAF372',
                padding: '5px 20px',

                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
              }}
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                subject === '' ||
                !userAlias ||
                loadingMaxCap ||
                maxCapReached
              }
              onClick={() => handleSend()}
            >
              Send
            </FormButtons>
          </FlexBox>
        </Box>

        <ScheduleSend
          open={scheduleSendModal}
          send={handleSend}
          loading={loadingSend}
          timeFrames={timeFrames}
          closeModal={(value: boolean) => setScheduleSendModal(value)}
          selectedDate={selectedDate}
          selectedTimeBlock={selectedTimeBlock}
          updateSelectedTime={(value: string) => setSelectedTimeBlock(value)}
          updateSelectedDate={(value: string) => setSelectedDate(value)}
        />
        <PreviewEmail
          open={openPreviewEmail}
          closeModal={closeModalPreview}
          quillRef={quillRef}
          body={`${body}<br>${signature}<br>${unsubscribeHtml}`}
        />
      </Box>
    </Modal>
  );
};

export default DraftSend;
