import { GenericAbortSignal } from 'axios';
import { ObjectLinks, RequestContacts } from '../models/Forms';
import { LinkFetch, PendingList, ScanStatusLink } from '../models/Links';
import { HistoryReq, NotesReq } from '../models/Notes';
import { Owners } from '../models/User';
import APIClient from '../services/APIClient';

export const fetchAllLinks = async (
  order: string,
  page: number,
  page_size: number,
  queryFilters: string,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<LinkFetch>(
    `/v2/links/?ordering=${order}&page=${page}&page_size=${page_size}${queryFilters}`,
    { signal },
  );
  return linksResponse.data;
};

export const fetchAllStatus = async (ids: number[]) => {
  const clientAPI = new APIClient();
  const statusResponse = await clientAPI.client.get<ScanStatusLink[]>(
    `/v2/links/last-status/?links=${ids.join(',')}`,
  );
  return statusResponse.data;
};

export const fetchAllContacts = async (ids: number[]) => {
  const clientAPI = new APIClient();
  const statusResponse = await clientAPI.client.get<RequestContacts[]>(
    `/v2/links/contacts/?links=${ids.join(',')}`,
  );
  return statusResponse.data;
};

export const fetchOutreach = async (ids: number[]) => {
  const clientAPI = new APIClient();
  const statusResponse = await clientAPI.client.put(
    `/v2/links/update-last-outreach/?contacts_ids=${ids.join(',')}`,
  );
  return statusResponse.data;
};

export const getLinksOwners = async () => {
  const clientAPI = new APIClient();
  const ownersResponse = await clientAPI.client.get<Owners[]>(
    `/users/?ordering=username&minimal=true`,
  );
  return ownersResponse.data;
};

export const createLink = async (obj: ObjectLinks) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.post('/v2/links/', obj);
  return linksResponse.data;
};

export const updateLink = async (obj: ObjectLinks, id: number) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.put(`/v2/links/${id}/`, obj);
  return linksResponse.data;
};

export const sendErrorSubmit = async (obj: {
  request_url: string;
  request_payload: string;
  response: string;
  token: string;
}) => {
  const clientAPI = new APIClient();
  const exceptionResponse = await clientAPI.client.post(
    `/exception-interceptor/`,
    obj,
  );
  return exceptionResponse.data;
};

export const validateLink = async (url: string, link_id?: number) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<{
    valid: boolean;
    message: string;
  }>(`/v2/links/validate_url/?url=${url}${link_id ? `&id=${link_id}` : ''}`);
  return linksResponse.data;
};

export const rulesCannibalization = async (
  site_id: number,
  url: string,
  user_id: number,
  link_id?: number,
) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<{
    valid: boolean;
    message: string;
    pending_list: PendingList[];
    cannibalized_link_id: null | number;
  }>(
    `/v2/links/rules-cannibalization-allowed/?site=${site_id}&user_id=${user_id}&url=${url}${
      link_id ? `&editing_link=${link_id}` : ''
    }`,
  );
  return linksResponse.data;
};

export const fetchLinkNotes = async (id: number) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.get<NotesReq[]>(
    `/notes/?link=${id}&type=note_general`,
  );
  return linkResponse.data;
};

export const fetchLinkHistory = async (id: number) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.get<HistoryReq[]>(
    `/v2/links/${id}/history/`,
  );
  return linkResponse.data;
};

export const addNoteLink = async (obj: {
  body: string;
  link: number;
  user: number;
}) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.post('/notes/', obj);
  return linkResponse.data;
};

export const deleteLink = async (id: number) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.delete(`/v2/links/${id}/`);
  return linkResponse.data;
};

export const countLink = async (site_id: number, user_id: number) => {
  const clientAPI = new APIClient();
  const response = await clientAPI.client.get<{
    site: number;
    user: number;
    previous_month_total: number;
    counter_links_dupulicated_current_month: number;
    current_percentage: number;
    site_default_percentage: number;
    user_percentage_allowed: null | number;
    amount_remaining: number;
    percentage_remaining: number;
  }>(
    `/v2/links/cannibalization-percentage/?site_id=${site_id}&user_id=${user_id}`,
  );
  return response.data;
};

export const countBuildOutreach = async (user_id?: number) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.get<{
    send_events: number;
    open_events: number;
    open_rate: number;
  }>(`/v2/links/build-outreach-kpis/${user_id ? `?owner=${user_id}` : ''}`);
  return linkResponse.data;
};

export const scheduleSendEmail = async (obj: any) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.post(
    '/v2/outreach-queue/schedule-message/',
    obj,
  );
  return linkResponse.data;
};

export const loadDraftForEmail = async (
  contact_id: number,
  link_id: number,
  site_id: number,
) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.get<{
    id: number;
    email_subject: string;
    email_body: string;
    template_id: number;
    affinity_id: number;
    audience_id: number;
    site_id: number;
    thread_parent_id: number | null;
    link_id: number;
    unsubscribe_html: string;
    signature_html: string;
    pixel_tracker_url: string;
    contact_id: number;
  }>(
    `/v2/outreach-queue/get-draft/?contact_id=${contact_id}&link_id=${link_id}&site_id=${site_id}`,
  );
  return linkResponse.data;
};

export const loadDraftFromDraftId = async (draft_id: number) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.get<{
    id: number;
    email_subject: string;
    email_body: string;
    template_id: number;
    affinity_id: number;
    audience_id: number;
    site_id: number;
    thread_parent_id: number | null;
    link_id: number;
    unsubscribe_html: string;
    signature_html: string;
    pixel_tracker_url: string;
    contact_id: number;
  }>(`/v2/outreach-queue/get-draft/?draft_id=${draft_id}`);
  return linkResponse.data;
};

export const getTimeFrames = async (date: string) => {
  const clientAPI = new APIClient();
  const linkResponse = await clientAPI.client.get<{
    blocks: {
      block_title: string;
      block_start: string;
      scheduled_messages: number;
      is_full: false;
    }[];
  }>(`/v2/outreach-queue/get-schedule-availability/?date=${date}`);
  return linkResponse.data;
};

export const downloadAllLinks = async (
  order: string,
  queryFilters: string,
  page: number,
  pageSize: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<string>(
    `/v2/export-scheduler/links/?approach=links&ordering=${order}&page=${page}&page_size=${pageSize}${queryFilters}`,
    { signal },
  );
  return linksResponse.data;
};

export const checkLimitOutreach = async () => {
  const clientAPI = new APIClient();
  const outreachData = await clientAPI.client.get<{
    outreach_limit_reached: boolean;
  }>(`/v2/outreach-queue/get-user-hourly-cap-status/`);
  return outreachData.data;
};
