import React, { useState } from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Typography,
} from '@mui/material';
import { columnsAlias, dummyDataAlias } from '../AliasConstants';
import TableWrapper from '../../../../components/Table/TableWrapper';
import TableHeadComp from '../../../../components/Table/TableHead.tsx/TableHeadComp';
import { Order } from '../../../../models/General';
import { StyledTableRow } from '../../../../components/LinksTable/LinksTable';
import { TableCellCustom } from '../../../../components/tableItems';

const AliasTable = () => {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('alias');

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
  };

  return (
    <TableWrapper
      loading={false}
      error={false}
      type="user"
      totalCells={5}
      itemsLength={5}
      fetchInfo={() => {}}
      countItems={5}
      pageSize={5}
      page={1}
      handleChangePage={() => {}}
      handleChangeRowsPerPage={() => {}}
      HeadOfTable={
        <TableHead sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
          <TableRow>
            {columnsAlias.map(headCell => (
              <TableCell
                key={headCell.id}
                sx={{
                  minWidth: 80,
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  disabled={headCell.disableSort}
                  direction={order}
                  onClick={() => {
                    handleRequestSort(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      }
    >
      {dummyDataAlias.map((item, index) => {
        const labelId = `template-user-checkbox-${index}`;
        return (
          <StyledTableRow
            hover
            role="checkbox"
            key={item.id}
            selected={false}
            className="column-row"
          >
            <TableCell
              component="th"
              className="first_column"
              scope="row"
              sx={{ backgroundColor: index % 2 === 0 ? '#eeeeee' : '' }}
              padding="none"
              id={labelId}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingX: 1,
                  minWidth: 150,
                  maxWidth: 450,
                }}
              >
                <Typography
                  color="rgba(0, 0, 0, 0.4)"
                  fontWeight={500}
                  fontSize={16}
                  letterSpacing={0.5}
                  textAlign="center"
                >
                  {item.alias}
                </Typography>
              </Box>
            </TableCell>
            <TableCellCustom>{item.user}</TableCellCustom>
            <TableCellCustom>{item.site}</TableCellCustom>
            <TableCellCustom>{item.project}</TableCellCustom>
            <TableCellCustom>{item.status}</TableCellCustom>
          </StyledTableRow>
        );
      })}
    </TableWrapper>
  );
};

export default AliasTable;
