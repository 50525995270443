import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Box,
  FormControl,
  Select,
  InputBase,
  Tooltip,
  Menu,
  IconButton,
  MenuItem,
  Typography,
  styled,
  Avatar,
  Skeleton,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logos/logoMain.png';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';

import routesNames from '../../../routes/customRoutes';
import { RequestContextType } from '../../../models/Context';
import RequestContext from '../../../context/RequestContext';
import MyProfile from '../../Profile/MyProfile';
import useSites from '../../../customHooks/useSites';
import {
  ENVIRONMENTS,
  IS_USER_ADMIN,
  ROLES_USER,
} from '../../../constants/user';
import { FlexBox } from '../../tableItems';
import useBuildOutreach from '../../../customHooks/useBuildOutreach';
import { countLink } from '../../../helpers/links';
import useDomainCounter from '../../../customHooks/useDomainCounter';
import useUnsubscribeCount from '../../../customHooks/useUnsubscribeCount';
import useSiteStore from '../../../stores/sites/sites';

type CountObjectType = {
  amount_remaining: number;
  percentage_remaining: number;
  previous_month_total: number;
  counter_links_dupulicated_current_month: number;
  user_percentage_allowed: null | number;
  site_default_percentage: number;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 190;
interface Props {
  open: boolean;
}

const adminRoles = ['administrator', 'dev admin'];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  width: `calc(100% - 45px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open }: Props) => {
  dayjs.extend(relativetime);
  const { userSite, updateUserSite } = useSiteStore();
  const currentMonth = dayjs().format('MMMM');
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { UserContext, setUserContext } = useContext(
    Context,
  ) as UserContextType;

  const [openModalProfile, setOpenModalProfile] = useState(false);
  const { RequestsContext, setRequestsContext } = useContext(
    RequestContext,
  ) as RequestContextType;
  const navigate = useNavigate();
  const { sites, loading } = useSites({
    userId: UserContext.id,
    minimal: true,
  });

  const { resultsBuild, loading: loadingBuild } = useBuildOutreach();
  const [countObject, setCountObject] = useState<CountObjectType>({
    amount_remaining: 0,
    percentage_remaining: 0,
    previous_month_total: 0,
    counter_links_dupulicated_current_month: 0,
    user_percentage_allowed: null,
    site_default_percentage: 0,
  });
  const { countResult: countDomain, loading: loadingCountDomain } =
    useDomainCounter();
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const {
    countResult: countUnsubscribe,
    loading: loadingCountUnsubscribe,
    previousMonthResult,
  } = useUnsubscribeCount();

  const BootstrapInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      position: 'relative',
      border: 'none',
      fontSize: 12.2,
      padding: '5px 15px 5px 10px',
      '&:focus': {
        border: 'none',
      },

      '&:hover': {
        color: '#616161',
      },
    },
  }));

  const logOut = () => {
    localStorage.removeItem('backlink.user');
    sessionStorage.removeItem('backlink.user');
    sessionStorage.removeItem('backlink.roletemp');
    navigate(routesNames.login);
  };

  useEffect(() => {
    if (sites.length > 0 && userSite === 0 && !loading) {
      const localSite = parseInt(
        JSON.parse(
          localStorage.getItem('backlink.siteselected') ?? JSON.stringify('0'),
        ),
        10,
      );

      if (localSite !== 0 && sites.find(item => item.id === localSite)) {
        updateUserSite(localSite);
      } else {
        updateUserSite(sites[0].id);
      }
    }
    setRequestsContext({
      ...RequestsContext,
      sites: sites.sort((a, b) => (a.name < b.name ? -1 : 1)),
    });
  }, [sites]);

  const changeRole = (value: string) => {
    localStorage.setItem('backlink.roletemp', JSON.stringify(value));

    window.location.reload();
  };

  const getAllCounts = async () => {
    setIsLoadingCount(true);
    try {
      const resp = await countLink(userSite, UserContext.id);
      setCountObject({
        percentage_remaining: resp.percentage_remaining,
        amount_remaining: resp.amount_remaining,
        user_percentage_allowed: resp.user_percentage_allowed,
        site_default_percentage: resp.site_default_percentage,
        previous_month_total: resp.previous_month_total,
        counter_links_dupulicated_current_month:
          resp.counter_links_dupulicated_current_month,
      });
    } catch (err) {
      setCountObject({
        percentage_remaining: 0,
        amount_remaining: 0,
        user_percentage_allowed: null,
        site_default_percentage: 0,
        previous_month_total: 0,
        counter_links_dupulicated_current_month: 0,
      });
    } finally {
      setIsLoadingCount(false);
    }
  };

  useEffect(() => {
    if (userSite > 0) getAllCounts();
  }, [userSite]);

  const informationToolTip = () => {
    return (
      <>
        <Box>
          {IS_USER_ADMIN.includes(UserContext.profile.role) && (
            <Typography fontSize={13} fontWeight={450}>
              {currentMonth} Open Rate: {resultsBuild.open_rate}
            </Typography>
          )}
          <Typography fontSize={13} fontWeight={450}>
            Cannibalization Remaining: {countObject.percentage_remaining} %
          </Typography>
          <Typography fontSize={13} fontWeight={450}>
            Domain Exception Outreach Remaining: {countDomain}
          </Typography>
          <FlexBox>
            <Typography fontSize={13} fontWeight={450}>
              {currentMonth} Unsubscribed: {countUnsubscribe} (
              {previousMonthResult})
            </Typography>
            {countUnsubscribe > previousMonthResult && (
              <ArrowCircleUpIcon
                sx={{
                  color: 'green',
                  marginLeft: 0.5,
                  '&:hover': { color: 'purple' },
                }}
              />
            )}
            {countUnsubscribe < previousMonthResult && (
              <ArrowCircleDownIcon
                sx={{
                  color: 'red',
                  marginLeft: 0.5,
                  '&:hover': { color: 'purple' },
                }}
              />
            )}
          </FlexBox>
        </Box>
      </>
    );
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        }}
        position="fixed"
        open={open}
      >
        <Toolbar sx={{ ml: 0.5 }}>
          <img src={logo} alt="Backlink Logo" />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <FlexBox>
              <FormControl sx={{ my: 2, ml: 2, minWidth: 120 }}>
                <Select
                  defaultValue={sites.length > 0 ? sites[0].id : 0}
                  IconComponent={KeyboardArrowDownIcon}
                  value={userSite}
                  sx={{
                    color: '#373737',
                    fontWeight: 700,
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none',
                    },
                    '& .Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': { outline: 'none' },
                    },
                  }}
                  onChange={e => {
                    localStorage.setItem(
                      'backlink.siteselected',
                      JSON.stringify(e.target.value),
                    );
                    updateUserSite(e.target.value as number);
                  }}
                  input={<BootstrapInput />}
                >
                  <MenuItem value={0} disabled>
                    {loading && <em>Loading Sites</em>}
                    {sites.length === 0 && !loading && (
                      <em>No sites assigned</em>
                    )}
                    {sites.length > 0 && !loading && <em>Select a Site</em>}
                  </MenuItem>
                  {sites.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* {loadingBuild ||
              loadingCountDomain ||
              loadingCountUnsubscribe ||
              isLoadingCount ? (
                <Box sx={{ width: 100, ml: 2 }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <HtmlTooltip title={informationToolTip()}>
                  <FlexBox ml={2} sx={{ cursor: 'pointer' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                      border="1px solid black"
                      borderRadius={10}
                      p={1}
                      mr={0.5}
                    >
                      <EqualizerIcon
                        sx={{
                          color: 'black',
                          fontSize: 15,
                        }}
                      />
                    </Box>
                    <Typography fontSize={12.6} color="black" fontWeight="bold">
                      Monthly Stats
                    </Typography>
                  </FlexBox>
                </HtmlTooltip>
              )} */}
            </FlexBox>
          </Box>
          <Box sx={{ flexGrow: 0, alignItems: 'center', display: 'flex' }}>
            {(adminRoles.includes(
              UserContext.real_role ?? UserContext.profile.role,
            ) ||
              (UserContext.profile.training_env_allowed &&
                ENVIRONMENTS.includes(process.env.REACT_APP_ENV ?? ''))) && (
              <FormControl sx={{ my: 2, ml: 2, minWidth: 120 }}>
                <Select
                  defaultValue={sites.length > 0 ? sites[0].id : 0}
                  IconComponent={KeyboardArrowDownIcon}
                  value={UserContext.profile.role}
                  sx={{
                    color: '#373737',
                    fontWeight: 700,
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none',
                    },
                    '& .Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        outline: 'none',
                      },
                    },
                  }}
                  onChange={e => changeRole(e.target.value as string)}
                  input={<BootstrapInput />}
                >
                  {ROLES_USER.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Tooltip title="Open options">
              <IconButton
                size="large"
                sx={{ borderRadius: 20 }}
                onClick={event => setAnchorElUser(event.currentTarget)}
              >
                {UserContext.avatar === '' && <div className="navbar-user" />}
                {UserContext.avatar !== '' && (
                  <Avatar
                    alt="User Avatar"
                    src={UserContext.avatar}
                    sx={{ width: '24px', height: '24px' }}
                  />
                )}
                <Typography
                  textAlign="right"
                  sx={{
                    ml: 1,
                    mr: 0.2,
                    fontWeight: 400,
                    color: '#373737',
                    fontSize: '14px',
                  }}
                >
                  {UserContext.first_name !== ''
                    ? `${UserContext.first_name} ${UserContext.last_name}`
                    : 'User'}
                </Typography>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              <MenuItem
                onClick={() => {
                  setAnchorElUser(null);
                  setOpenModalProfile(true);
                }}
                sx={{ minWidth: 120 }}
              >
                <Typography textAlign="start">Edit My Profile</Typography>
              </MenuItem>
              <MenuItem onClick={() => logOut()} sx={{ minWidth: 120 }}>
                <Typography textAlign="start">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <MyProfile
        open={openModalProfile}
        closeModal={setOpenModalProfile}
        sitesTable={sites}
      />
    </>
  );
};

export default Navbar;
