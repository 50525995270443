import { useState, useEffect } from 'react';
import { getLinksOwners } from '../helpers/links';
import { Owners, UserResponse } from '../models/User';
import { getUsers, getUsersFilter } from '../helpers/user';
import { BasicArray } from '../models/General';

interface UseOwnersProps {
  withFields: boolean;
  filter?: boolean;
  role?: string;
  fullName?: boolean;
}

const useOwners = ({ withFields, filter, role, fullName }: UseOwnersProps) => {
  const [owners, setOwners] = useState<Owners[]>([]);
  const [users, setUsers] = useState<BasicArray[]>([]);
  const [loading, setLoading] = useState(false);

  const getOwners = () => {
    setLoading(true);
    getUsers()
      .then(res => {
        const resp = res.map(item => {
          const userResp = item as UserResponse;
          return {
            id: item.id,
            username:
              userResp.first_name !== ''
                ? `${userResp.first_name} ${userResp.last_name}`
                : userResp.username,
          };
        });
        setOwners(resp);
      })
      .catch(err => setOwners([]))
      .finally(() => setLoading(false));
  };

  const refreshUsers = () => {
    setLoading(true);
    getUsersFilter(role)
      .then(res => {
        if (!filter) {
          return setUsers(
            res.map(item => {
              const resp = item as UserResponse;
              return {
                id: item.id,
                name: fullName
                  ? `${resp.first_name} ${resp.last_name}`
                  : item.username,
              };
            }),
          );
        }
        return setUsers(
          res.map(item => {
            const resp = item as UserResponse;
            return {
              id: item.id,
              name: `${resp.first_name} ${resp.last_name}`,
            };
          }),
        );
      })
      .catch(err => setUsers([]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (withFields) getOwners();
    else refreshUsers();
  }, []);

  return { owners, loading, users };
};

export default useOwners;
