import { GenericAbortSignal } from 'axios';
import { ClaimPostPutInt, ClaimsInt, ClaimsResponseInt } from '../models/Links';
import APIClient from '../services/APIClient';

export const createClaimLink = async (obj: ClaimPostPutInt) => {
  const clientAPI = new APIClient();
  const claimResponse = await clientAPI.client.post('/v2/link-claims/', obj);
  return claimResponse.data;
};

export const claimCounts = async (query: string) => {
  const clientAPI = new APIClient();
  const claimResponse = await clientAPI.client.get<{
    total_points_sum: number;
    results_count: number;
  }>(`/v2/link-claims/points-count/${query}`);
  return claimResponse.data;
};

export const editClaimLink = async (obj: ClaimPostPutInt, id: number) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.put(
    `/v2/link-claims/${id}/`,
    obj,
  );
  return linksResponse.data;
};

export const getLinksClaims = async (linkId: number) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<ClaimsInt[]>(
    `/v2/link-claims/get-by-link-id?link_id=${linkId}`,
  );
  return linksResponse.data;
};

export const getAllClaims = async (
  order: string,
  queryFilters: string,
  page: number,
  pageSize: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<ClaimsResponseInt>(
    `/v2/link-claims/?ordering=${order}&page=${page}&page_size=${pageSize}${queryFilters}`,
    { signal },
  );
  return linksResponse.data;
};

export const getClaimInfo = async (id: number) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<ClaimsInt>(
    `/v2/link-claims/${id}/`,
  );
  return linksResponse.data;
};

export const getClaimTags = async () => {
  const clientAPI = new APIClient();
  const tagsResponse = await clientAPI.client.get<{ name: string }[]>(
    `/v2/link-claims/get-claim-tags/`,
  );
  return tagsResponse.data;
};

export const deleteClaimTags = async (tag: string) => {
  const clientAPI = new APIClient();
  const tagsResponse = await clientAPI.client.delete<{ name: string }[]>(
    `/v2/link-claims/delete-claim-tags/${tag}/`,
  );
  return tagsResponse.data;
};

export const downloadAllClaimsMaster = async (
  order: string,
  queryFilters: string,
  page: number,
  pageSize: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<string>(
    `/v2/export-scheduler/link-claims/?ordering=${order}&page=${page}&page_size=${pageSize}${queryFilters}`,
    { signal },
  );
  return linksResponse.data;
};

export const downloadAllClaims = async (
  order: string,
  queryFilters: string,
  page: number,
  pageSize: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const linksResponse = await clientAPI.client.get<string>(
    `/v2/export-scheduler/link-claims/?ordering=${order}&page=${page}&page_size=${pageSize}${queryFilters}&approach=claims`,
    { signal },
  );
  return linksResponse.data;
};

export const getIsClaimAvailable = async (id: number) => {
  const clientAPI = new APIClient();
  const isEnabled = await clientAPI.client.get<{ is_claim_enabled: boolean }>(
    `/v2/links/${id}/is_claim_enabled/`,
  );
  return isEnabled.data;
};
