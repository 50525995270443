import React, { useContext, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import DrawerLinks from '../DefaultView/DrawerLinks';
import LogsTable from './Logs/LogsTable';
import RightColumns from '../../components/Columns/RightColumns';
import { FlexBox, FlexBoxBetween } from '../../components/tableItems';
import TabParent from '../../components/Tab/TabParent';
import TabPanel from '../../components/Tab/TabPanel';
import FiltersLogs from './Logs/FiltersLogs';
import useLogStore from '../../stores/history/log';
import { IS_USER_ADMIN, IS_USER_ADMIN_AND_STRAT } from '../../constants/user';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import { MainButton } from '../../components/buttons';
import { downloadAllLinks } from '../../helpers/links';
import { exportHistory } from '../../helpers/historyHelper';
import FeedbackMessage from '../../components/FeedbackMessage';
import { SeverityType } from '../../models/General';
import SearchInput from '../../components/Search/SearchInput';
import useActiveUsers from '../../customHooks/useActiveUsers';

const tabsArr = [{ id: 0, label: 'Activity Log' }];

const History = () => {
  const {
    loading: loadingLogs,
    filtersValuesDefault,
    logObject,
    updateFiltersChange,
    updateFiltersDefault,
    filtersValuesChange,
  } = useLogStore();
  const { UserContext } = useContext(Context) as UserContextType;
  const [searchContacts, setSearchContacts] = useState(false);

  const [loadExport, setLoadExport] = useState(false);
  const [drawerForm, setDrawerForm] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { owners } = useActiveUsers();
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<{
    message: string;
    severity: SeverityType;
  }>({ message: '', severity: 'success' });

  const updateFormDrawer = () => setDrawerForm(!drawerForm);

  type EntriesObject = Record<string, any>;

  const hasValidValue = (obj: EntriesObject): boolean => {
    const copyObj = { ...obj };
    if (!IS_USER_ADMIN.includes(UserContext.profile.role)) delete copyObj.owner;

    return Object.values(copyObj).some(
      entry =>
        entry &&
        typeof entry === 'object' &&
        (typeof entry.value !== 'string' || entry.value.trim() !== '') &&
        entry.value !== null &&
        entry.value !== false,
    );
  };

  const downloadContentLogs = async () => {
    setLoadExport(true);
    try {
      await exportHistory(
        logObject.ordering,
        logObject.filters,
        logObject.page,
        logObject.page_size,
      );
      setFeedbackMessage({
        message: 'Export file will be sent to your email',
        severity: 'success',
      });
      setLoadExport(false);
    } catch (err) {
      const errMessage = err as any;
      setFeedbackMessage({
        message: errMessage ? errMessage.data : '',
        severity: 'error',
      });
    } finally {
      setLoadExport(false);
    }
  };

  return (
    <div>
      <DrawerLinks openDrawer={drawerForm} openCloseDrawer={updateFormDrawer}>
        <FiltersLogs owners={owners} updateFormDrawer={updateFormDrawer} />
      </DrawerLinks>

      <div className="right_columns">
        <RightColumns pr={0} pl={0}>
          <FlexBoxBetween px={2}>
            <FlexBox>
              <IconButton
                aria-label="filter"
                id="long-button"
                sx={{
                  border: 1,
                  borderRadius: '10%',
                  bgcolor: hasValidValue(filtersValuesDefault) ? '#BAF372' : '',
                  '&.Mui-disabled': {
                    bgcolor: hasValidValue(filtersValuesDefault)
                      ? '#BAF372'
                      : '',
                  },
                }}
                aria-haspopup="true"
                onClick={updateFormDrawer}
                disabled={loadingLogs}
              >
                <FilterListIcon
                  sx={{
                    color: 'black',
                    '&:hover': { color: 'green' },
                  }}
                />
              </IconButton>
              <Typography ml={1} fontSize={17} fontWeight="bold">
                Filters
              </Typography>
            </FlexBox>
            <Typography fontSize={24} fontWeight={900}>
              History
            </Typography>
            {!IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) && (
              <div />
            )}
            {IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) && (
              <MainButton
                disabled={loadingLogs}
                variant="contained"
                endIcon={<DownloadIcon />}
                type="submit"
                loading={loadExport}
                sx={{
                  mr: 2,
                  backgroundColor: '#84D0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#84D0F0',
                  },
                }}
                onClick={downloadContentLogs}
              >
                Export
              </MainButton>
            )}
          </FlexBoxBetween>
          <Box my={2} px={3}>
            <SearchInput
              placeholder={
                searchContacts ? 'Search by Contact Email' : 'Search by URL'
              }
              id="search_url"
              dropdown={{
                value: searchContacts ? 'contacts' : 'url',
                onChange: (value: string) => {
                  if (value === 'url') {
                    setSearchContacts(false);
                    updateFiltersChange({
                      search_email: '',
                    });
                  } else {
                    setSearchContacts(true);
                    updateFiltersChange({
                      search_url: '',
                    });
                  }
                },
              }}
              filter={false}
              onKeyDown={() => {
                updateFiltersDefault(filtersValuesChange);
              }}
              onClear={() => {
                if (searchContacts)
                  updateFiltersChange({
                    search_email: '',
                  });
                else
                  updateFiltersChange({
                    search_url: '',
                  });
              }}
              value={
                searchContacts
                  ? filtersValuesChange.search_email
                  : filtersValuesChange.search_url
              }
              onChangeNormal={query => {
                if (searchContacts)
                  updateFiltersChange({
                    search_email: query,
                  });
                else
                  updateFiltersChange({
                    search_url: query,
                  });
              }}
              disabled={loadingLogs || loadExport}
            />
          </Box>

          <Box mt={2}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginTop: 3,
              }}
            >
              <TabParent
                valueTab={activeTab}
                setTab={setActiveTab}
                tabs={tabsArr}
                center={false}
              />
            </Box>
          </Box>
          <TabPanel value={activeTab} index={0}>
            <LogsTable />
          </TabPanel>
        </RightColumns>
      </div>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="center"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
    </div>
  );
};

export default History;
