/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { Box, Toolbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TitleButton from '../../../components/Basic/TitleButton';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import FeedbackMessage from '../../../components/FeedbackMessage';
import AddCategory from '../../../components/Forms/Category/AddCategory';
import AddClient from '../../../components/Forms/Client/AddClient';
import AddProject from '../../../components/Forms/Project/AddProject';
import AddSite from '../../../components/Forms/Site/AddSite';
import DeleteModal from '../../../components/Modals/DeleteModal';
import SettingsOptions from '../../../components/Settings/SettingsOptions';
import TabPanel from '../../../components/Tab/TabPanel';
import TabParent from '../../../components/Tab/TabParent';
import {
  deleteCategory,
  updateCategoryStatus,
} from '../../../helpers/category';
import {
  deleteClient,
  fetchClients,
  fetchSingleClient,
  updateClientStatus,
} from '../../../helpers/clients';
import {
  deleteProject,
  fetchProjects,
  fetchSingleProject,
  updateProjectStatus,
} from '../../../helpers/projects';
import { deleteSite, updateSiteStatus } from '../../../helpers/sites';
import { Category, ClientsForm, ClientsRequest } from '../../../models/Clients';
import { SeverityType } from '../../../models/General';
import { ProjectsRequest, ProjectsSitesProps } from '../../../models/Projects';
import ColumnsProjects from './Sections/ColumnsProjects';
import SiteStatusChange from '../../../components/Modals/SiteStatusChange';

interface Order {
  client: 'asc' | 'desc';
  category: 'asc' | 'desc';
  projects: 'asc' | 'desc';
  sites: 'asc' | 'desc';
}

const tabsArr = [
  { id: 0, label: 'Active' },
  { id: 1, label: 'All' },
];

interface EditObj {
  clientObj: ClientsForm | null;
  category: number;
  project: number;
  site: number;
}

const ProjectSites = () => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openStatusChange, setOpenStatusChange] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [objDelete, setObjDelete] = useState({
    id: 0,
    title: '',
    type: '',
    impact: '',
    option: '',
  });
  const [orderSort, setOrderSort] = useState<Order>({
    client: 'asc',
    category: 'asc',
    projects: 'asc',
    sites: 'asc',
  });
  const [tab, setTab] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<{
    message: string;
    severity: SeverityType;
  }>({ message: '', severity: 'success' });
  const [drawerOption, setDrawerOption] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedProject, setSelectedProject] = useState(0);
  const [selectedSite, setSelectedSite] = useState(0);
  const [editObj, setEditObj] = useState<EditObj>({
    clientObj: null,
    category: 0,
    project: 0,
    site: 0,
  });
  const [projects, setProjects] = useState<ProjectsRequest[]>([]);
  const [sites, setSites] = useState<
    { id: number; name: string; status: string }[]
  >([]);
  const [clientsArr, setClientsArr] = useState<ClientsRequest[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState({
    clients: false,
    category: false,
    projects: false,
    sites: false,
  });

  const sortResults = (
    order: 'asc' | 'desc',
    type: 'client' | 'category' | 'projects' | 'sites',
  ) => {
    const copyArr =
      type === 'client'
        ? [...clientsArr]
        : type === 'category'
        ? [...categories]
        : type === 'projects'
        ? [...projects]
        : [...sites];
    if (order === 'asc') {
      setOrderSort({ ...orderSort, [type]: 'desc' });
      copyArr.sort((a, b) => (a.name < b.name ? 1 : -1));
    } else {
      setOrderSort({ ...orderSort, [type]: 'asc' });
      copyArr.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    if (type === 'client') setClientsArr(copyArr as ClientsRequest[]);
    if (type === 'category') setCategories(copyArr as Category[]);
    if (type === 'projects') setProjects(copyArr as ProjectsRequest[]);
    if (type === 'sites') setSites(copyArr);
  };

  const getSites = async () => {
    setLoading({ ...loading, sites: true });
    try {
      const resp = await fetchSingleProject(selectedProject);
      const sortA = orderSort.sites === 'asc' ? -1 : 1;
      const sortB = orderSort.sites === 'asc' ? 1 : -1;
      if (tab === 0) {
        setSites(
          resp.sites
            .filter(item => item.status === 'active')
            .sort((a, b) => (a.name < b.name ? sortA : sortB)),
        );
        return;
      }
      setSites(resp.sites.sort((a, b) => (a.name < b.name ? sortA : sortB)));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({ ...loading, sites: false });
    }
  };

  const getClientsInfo = async () => {
    setLoading({ ...loading, clients: true });
    try {
      const resp = await fetchClients(tab === 0);
      const sortA = orderSort.client === 'asc' ? -1 : 1;
      const sortB = orderSort.client === 'asc' ? 1 : -1;
      setClientsArr(resp.sort((a, b) => (a.name < b.name ? sortA : sortB)));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({ ...loading, clients: false });
    }
  };

  const getProjects = async () => {
    setLoading({ ...loading, projects: true });
    try {
      const resp = await fetchProjects(
        undefined,
        selectedCategory,
        `${orderSort.projects === 'desc' ? '-name' : 'name'}`,
        tab === 0,
      );
      setProjects(resp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({ ...loading, projects: false });
    }
  };

  const getCategories = async () => {
    setLoading({ ...loading, category: true });
    try {
      const resp = await fetchSingleClient(selectedClient);
      const sortA = orderSort.category === 'asc' ? -1 : 1;
      const sortB = orderSort.category === 'asc' ? 1 : -1;
      if (tab === 0) {
        setCategories(
          resp.categories
            .filter(item => item.status === 'active')
            .sort((a, b) => (a.name < b.name ? sortA : sortB)),
        );
        return;
      }
      setCategories(
        resp.categories.sort((a, b) => (a.name < b.name ? sortA : sortB)),
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({ ...loading, category: false });
    }
  };

  // FUNCTIONS
  const openDrawerOption = (id: number) => {
    setDrawerOption(id);
    setOpenDrawer(true);
  };

  const editClient = (id: number) => {
    const client = clientsArr.find(item => item.id === id) as ClientsForm;
    if (client.address_2 === null) client.address_2 = '';
    setEditObj({ ...editObj, clientObj: client });
    setDrawerOption(2);
    setOpenDrawer(true);
  };

  const editCategory = (id: number) => {
    setEditObj({ ...editObj, category: id });
    setDrawerOption(4);
    setOpenDrawer(true);
  };

  const editProject = (id: number) => {
    setEditObj({ ...editObj, project: id });
    setDrawerOption(6);
    setOpenDrawer(true);
  };

  const editSite = (id: number) => {
    setEditObj({ ...editObj, site: id });
    setDrawerOption(8);
    setOpenDrawer(true);
  };

  const createMessage = (
    message: string,
    type: 'client' | 'category' | 'project' | 'site',
  ) => {
    setFeedbackMessage({ message, severity: 'success' });
    setFeedbackOpen(true);
    if (type === 'client') getClientsInfo();
    if (type === 'category') getCategories();
    if (type === 'project') getProjects();
    if (type === 'site') getSites();
  };

  const clean = (type: 'client' | 'category' | 'project') => {
    if (type === 'client') {
      setSelectedCategory(0);
      setSelectedProject(0);
      setSelectedClient(0);
      setSites([]);
      setProjects([]);
      setCategories([]);
      getClientsInfo();
    }
    if (type === 'category') {
      setSelectedCategory(0);
      setSelectedProject(0);
      setSelectedSite(0);
      setProjects([]);
      setSites([]);
      getCategories();
    }
    if (type === 'project') {
      setSelectedProject(0);
      setSites([]);
      getProjects();
    }
  };

  // USE EFFECTS

  useEffect(() => {
    if (selectedProject !== 0) {
      setSelectedSite(0);
      getSites();
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedCategory !== 0) {
      setSelectedProject(0);
      setSites([]);
      getProjects();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedClient !== 0) {
      setSelectedCategory(0);
      setSelectedProject(0);
      setSites([]);
      setProjects([]);
      getCategories();
    }
  }, [selectedClient]);

  useEffect(() => {
    clean('client');
  }, [tab]);

  const deleteItem = async (
    id: number,
    option: string,
    newId?: number,
    obj?: { status: string; text_note: string },
  ) => {
    setLoadDelete(true);
    try {
      switch (option) {
        case 'Client':
          if (objDelete.option !== 'Delete')
            await updateClientStatus(obj ?? { status: '', text_note: '' }, id);
          if (objDelete.option === 'Delete') await deleteClient(id);
          clean('client');
          break;
        case 'Category':
          if (objDelete.option !== 'Delete')
            await updateCategoryStatus(
              obj ?? { status: '', text_note: '' },
              id,
            );
          if (objDelete.option === 'Delete') await deleteCategory(id);
          clean('category');
          break;
        case 'Project':
          if (objDelete.option !== 'Delete')
            await updateProjectStatus(obj ?? { status: '', text_note: '' }, id);
          if (objDelete.option === 'Delete') await deleteProject(id);
          clean('project');
          break;
        case 'Site':
          if (objDelete.option !== 'Delete')
            await updateSiteStatus(obj ?? { status: '', text_note: '' }, id);
          if (objDelete.option === 'Delete') await deleteSite(id, newId ?? 0);
          getSites();
          break;
        default:
          break;
      }
      setFeedbackMessage({
        message: `${option} was ${
          objDelete.option === 'Delete' ? 'deleted' : 'updated'
        } successfully`,
        severity: 'success',
      });
      setFeedbackOpen(true);
    } catch (err) {
      if (obj) {
        setFeedbackMessage({
          message: 'Error changing site status',
          severity: 'error',
        });
        setFeedbackOpen(true);
      } else {
        const error = err as any;
        setFeedbackMessage({
          message: error.data,
          severity: 'error',
        });
        setFeedbackOpen(true);
      }
    } finally {
      setLoadDelete(false);
      if (obj) setOpenStatusChange(false);
      else setOpenModalDelete(false);
    }
  };

  const handleDeleteDeactivate = (
    type: 'client' | 'category' | 'projects' | 'sites',
    id: number,
    option: 'Delete' | 'Deactivate' | 'Activate',
  ) => {
    if (type === 'client') {
      const obj = clientsArr.find(item => item.id === id);
      setObjDelete({
        id,
        title: obj?.name ?? '',
        type: 'Client',
        impact: `${obj?.categories.length} categories`,
        option,
      });
    }
    if (type === 'category') {
      const obj = categories.find(item => item.id === id);
      setObjDelete({
        id,
        title: obj?.name ?? '',
        type: 'Category',
        impact: '',
        option,
      });
    }
    if (type === 'projects') {
      const obj = projects.find(item => item.id === id);
      setObjDelete({
        id,
        title: obj?.name ?? '',
        type: 'Project',
        impact: `${obj?.sites.length} sites`,
        option,
      });
    }
    if (type === 'sites') {
      const obj = sites.find(item => item.id === id);

      setObjDelete({
        id,
        title: obj?.name ?? '',
        type: 'Site',
        impact: '',
        option,
      });
    }
    setOpenStatusChange(true);
    // else setOpenModalDelete(true);
  };

  const columnsArr: ProjectsSitesProps[] = [
    {
      id: 1,
      orderFunction: sortResults,
      text: 'Clients',
      orderSort: orderSort.client,
      disabled: { add: false, sort: false },
      addFunction: () => openDrawerOption(1),
      type: 'client',
      onEdit: (id: number) => editClient(id),
      options: clientsArr,
      selected: selectedClient,
      handleDelete: handleDeleteDeactivate,
      setSelected: setSelectedClient,
      loading: loading.clients,
    },
    {
      id: 3,
      orderFunction: sortResults,
      text: 'Categories',
      orderSort: orderSort.category,
      disabled: {
        add: !(selectedClient !== 0),
        sort: !(categories.length > 0),
      },
      addFunction: () => openDrawerOption(3),
      type: 'category',
      onEdit: (id: number) => editCategory(id),
      handleDelete: handleDeleteDeactivate,
      options: categories,
      selected: selectedCategory,
      setSelected: setSelectedCategory,
      loading: loading.category,
    },
    {
      id: 5,
      orderFunction: sortResults,
      handleDelete: handleDeleteDeactivate,
      text: 'Projects',
      orderSort: orderSort.projects,
      disabled: {
        add: !(selectedCategory !== 0),
        sort: !(projects.length > 0),
      },
      addFunction: () => openDrawerOption(5),
      type: 'projects',
      onEdit: (id: number) => editProject(id),
      options: projects.map(item => {
        return {
          id: item.id,
          name: item.name,
          status: item.status,
        };
      }),
      selected: selectedProject,
      setSelected: setSelectedProject,
      loading: loading.projects,
    },
    {
      id: 7,
      orderFunction: sortResults,
      text: 'Sites',
      handleDelete: handleDeleteDeactivate,
      orderSort: orderSort.sites,
      disabled: {
        add: !(selectedProject !== 0),
        sort: !(sites.length > 0),
      },
      addFunction: () => openDrawerOption(7),
      type: 'sites',
      onEdit: (id: number) => editSite(id),
      options: sites,
      selected: selectedSite,
      setSelected: setSelectedSite,
      loading: loading.sites,
    },
  ];

  return (
    <>
      <div className="grid_container_settings">
        <div className="left_columns">
          <SettingsOptions option={4} />
        </div>
        <div className="right_columns">
          <Box>
            <Toolbar />
            <Box sx={{ pt: 4 }}>
              <Box sx={{ pl: 3, pr: 4 }}>
                <TitleButton title="Clients & Sites" button={false} />
              </Box>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  marginTop: 2,
                }}
              >
                <TabParent
                  valueTab={tab}
                  setTab={setTab}
                  tabs={tabsArr}
                  center={false}
                />
              </Box>
              <TabPanel value={tab} index={0}>
                <Box>
                  <div className="grid_client-sites">
                    {columnsArr.map(column => (
                      <div key={column.id} className="columns column-border">
                        <ColumnsProjects {...column} />
                      </div>
                    ))}
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Box>
                  <div className="grid_client-sites">
                    {columnsArr.map(column => (
                      <div key={column.id} className="columns column-border">
                        <ColumnsProjects {...column} />
                      </div>
                    ))}
                  </div>
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </div>
      </div>
      <DeleteModal
        open={openModalDelete}
        closeModal={setOpenModalDelete}
        loadingDelete={loadDelete}
        id={objDelete.id}
        deleteItem={deleteItem}
        title={objDelete.title}
        dataType={objDelete.type}
        impact={objDelete.impact === '' ? undefined : objDelete.impact}
        option={objDelete.option}
      />
      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        {drawerOption === 1 && (
          <AddClient
            setOpenDrawer={setOpenDrawer}
            type="add"
            createSuccess={createMessage}
          />
        )}
        {drawerOption === 2 && editObj.clientObj !== null && (
          <AddClient
            setOpenDrawer={setOpenDrawer}
            type="edit"
            createSuccess={createMessage}
            clientObj={editObj.clientObj}
          />
        )}
        {drawerOption === 3 && (
          <AddCategory
            setOpenDrawer={setOpenDrawer}
            clientValue={selectedClient}
            clientsOptions={clientsArr.map(item => {
              return { label: item.name, value: item.id.toString() };
            })}
            type="add"
            createSuccess={createMessage}
          />
        )}
        {drawerOption === 4 && (
          <AddCategory
            setOpenDrawer={setOpenDrawer}
            clientValue={selectedClient}
            clientsOptions={clientsArr.map(item => {
              return { label: item.name, value: item.id.toString() };
            })}
            type="edit"
            createSuccess={createMessage}
            categoryEdit={editObj.category}
          />
        )}
        {drawerOption === 5 && (
          <AddProject
            setOpenDrawer={setOpenDrawer}
            categoryValue={selectedCategory}
            clientValue={selectedClient}
            clientsOptions={clientsArr.map(item => {
              return { id: item.id, name: item.name };
            })}
            type="add"
            clientsCategories={clientsArr}
            createSuccess={createMessage}
            categoryOptions={categories}
          />
        )}
        {drawerOption === 6 && (
          <AddProject
            setOpenDrawer={setOpenDrawer}
            categoryValue={selectedCategory}
            clientValue={selectedClient}
            clientsOptions={clientsArr.map(item => {
              return { id: item.id, name: item.name };
            })}
            type="edit"
            clientsCategories={clientsArr}
            createSuccess={createMessage}
            categoryOptions={categories}
            projectEdit={editObj.project}
          />
        )}
        {drawerOption === 7 && (
          <AddSite
            setOpenDrawer={setOpenDrawer}
            categoryValue={selectedCategory}
            clientValue={selectedClient}
            projectValue={selectedProject !== 0 ? selectedProject : undefined}
            clientsOptions={clientsArr.map(item => {
              return { id: item.id, name: item.name };
            })}
            type="add"
            clientsCategories={clientsArr}
            createSuccess={createMessage}
            categoryOptions={categories}
          />
        )}
        {drawerOption === 8 && (
          <AddSite
            setOpenDrawer={setOpenDrawer}
            categoryValue={selectedCategory}
            clientValue={selectedClient}
            projectValue={selectedProject !== 0 ? selectedProject : undefined}
            siteEdit={editObj.site}
            clientsOptions={clientsArr.map(item => {
              return { id: item.id, name: item.name };
            })}
            type="edit"
            clientsCategories={clientsArr}
            createSuccess={createMessage}
            categoryOptions={categories}
          />
        )}
      </MainDrawer>
      <SiteStatusChange
        open={openStatusChange}
        closeModal={setOpenStatusChange}
        status={objDelete.option}
        id={objDelete.id}
        loading={loadDelete}
        changeStatus={deleteItem}
        type={objDelete.type}
      />
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="right"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
    </>
  );
};

export default ProjectSites;
