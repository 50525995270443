import { Box, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import EditUser from '../../../components/Forms/User/EditUser';
import SearchInput from '../../../components/Search/SearchInput';
import UserTable from '../../../components/UsersTable/UserTable';
import GeneralContext from '../../../context/GeneralActions';
import { GlobalContextType } from '../../../models/Context';
import SettingsStructure from '../SettingsStructure';
import useUsers from '../../../customHooks/useUsers';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import AddUser from '../../../components/Forms/User/AddUser';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { MessagesInt, SeverityType } from '../../../models/General';
import {
  UserContextType,
  UserEditObj,
  UserResponse,
} from '../../../models/User';
import { UserProjectSites } from '../../../models/Projects';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import Context from '../../../context/UserContext';
import { ROLES_USER_PAGE } from '../../../constants/user';
import UserStatusChange from '../../../components/Modals/UserStatusChange';

const Users = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<MessagesInt>({
    message: '',
    severity: 'success',
  });
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const { loading, usersArray, count, error, refreshUsers, usersProfiles } =
    useUsers();
  const [render, setRender] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [optionDrawer, setOptionDrawer] = useState(0);
  const [openStatusUser, setOpenStatusUser] = useState(false);
  const [changeUserStatus, setChangeUserStatus] = useState({
    id: 0,
    is_active: false,
  });
  const [userEdit, setUserEdit] = useState<{
    userEdit: UserEditObj | null;
    id: number;
    projectsUser: UserProjectSites[];
    sitesUser: UserProjectSites[];
  }>({
    userEdit: null,
    id: 0,
    projectsUser: [],
    sitesUser: [],
  });

  useEffect(() => {
    if (render !== 0) refreshUsers();
  }, [GlobalContext.users]);

  useEffect(() => {
    if (render === 0 && usersArray.length > 0) setRender(2);
  }, [usersArray]);

  useEffect(() => {
    setGlobalContext({
      ...GlobalContext,
      users: { page: 1, page_size: 10, search: '', order: 'username' },
    });
    setRender(1);
  }, []);

  const onChangeUserQuery = (value: string) => {
    setGlobalContext({
      ...GlobalContext,
      users: { ...GlobalContext.users, search: value, page: 1 },
    });
  };

  const showMessage = (message: string, severity: SeverityType) => {
    setFeedbackMessage({ message, severity });
    setFeedbackOpen(true);
  };

  const reloadData = () => refreshUsers();

  const openEdit = (user: UserResponse) => {
    const profile = usersProfiles.find(item => item.id === user.id);
    const objUserEdit: UserEditObj = {
      username: user.username,
      first_name: user.first_name,
      employee_title: profile?.profile.employee_title ?? '',
      last_name: user.last_name,
      monthly_exceptions: profile?.profile.monthly_exceptions.toString() ?? '0',
      unlimited_exceptions: profile?.profile.unlimited_exceptions ?? false,
      preferred_name: profile?.profile.preferred_name ?? '',
      email: user.email,
      groups: user.groups,
      account: profile?.profile.account ?? '',
      account_manager: profile?.profile.account_manager?.id ?? 0,
      manager: profile?.profile.manager ? profile.profile.manager.id : 0,
      role: user.role_name ?? '',
      diff_min: profile?.profile.difficulty_minimum.toString() ?? '0',
      diff_goal: profile?.profile.difficulty_goal.toString() ?? '0',
      avatar: user.avatar ?? '',
      status: user.is_active ? 'active' : 'inactive',
      status_effective_date: dayjs(profile?.profile.status_effective).format(
        'MM/DD/YYYY',
      ),
      contractor: false,
      can_override_links: profile?.profile.can_override_links ?? false,
      assign_links: false,
      send_profile: profile?.profile.send_profile_setup_link ?? false,
      training_env_allowed: profile?.profile.training_env_allowed ?? false,
    };

    setUserEdit({
      userEdit: objUserEdit,
      id: user.id,
      projectsUser:
        profile?.profile.projects.map(item => {
          return {
            id: item.id,
            date_effective: item.date_effective ?? '',
          };
        }) ?? [],
      sitesUser:
        profile?.profile.sites.map(item => {
          return {
            id: item.id,
            date_effective: item.date_effective ?? '',
          };
        }) ?? [],
    });
    setOptionDrawer(1);
    setOpenDrawer(true);
  };

  const changeStatus = (id: number, status: boolean) => {
    setChangeUserStatus({ id, is_active: status });
    setOpenStatusUser(true);
  };

  return (
    <>
      <SettingsStructure
        option={1}
        title="Users"
        button={sendBackObject(UserContext.profile.role, ROLES_USER_PAGE).add}
        buttonText="Add User"
        buttonFunction={() => {
          setOptionDrawer(0);
          setOpenDrawer(true);
        }}
      >
        <Box marginTop={4}>
          {error && (
            <Typography variant="h6">
              There was an error loading the users please try again
            </Typography>
          )}
          {!error && (
            <>
              <Box px={2}>
                <SearchInput
                  filter
                  placeholder=""
                  disabled={loading}
                  id="user"
                  onChange={onChangeUserQuery}
                />
              </Box>
              <Box mb={5} mt={3}>
                <UserTable
                  loading={loading}
                  changeStatus={changeStatus}
                  error={error}
                  refreshUsers={refreshUsers}
                  openEdit={openEdit}
                  users={usersArray}
                  countUsers={count}
                />
              </Box>
            </>
          )}
        </Box>
      </SettingsStructure>
      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        {optionDrawer === 0 && (
          <AddUser
            setOpenDrawer={setOpenDrawer}
            showMessage={showMessage}
            reloadData={reloadData}
          />
        )}
        {optionDrawer === 1 && userEdit.userEdit && (
          <EditUser
            setOpenDrawer={setOpenDrawer}
            userEdit={userEdit.userEdit}
            id={userEdit.id}
            projectsUser={userEdit.projectsUser}
            sitesUser={userEdit.sitesUser}
            reloadData={reloadData}
            showMessage={showMessage}
            isActive={userEdit.userEdit.status === 'active'}
          />
        )}
      </MainDrawer>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="right"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
      <UserStatusChange
        open={openStatusUser}
        closeModal={setOpenStatusUser}
        reloadData={reloadData}
        id={changeUserStatus.id}
        is_active={changeUserStatus.is_active}
        showMessage={showMessage}
      />
    </>
  );
};

export default Users;
