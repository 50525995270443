import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BasicArray } from '../../models/General';
import { Owners, UserContextType } from '../../models/User';
import { SelectFormBulk, SelectFormProspecting } from '../Forms/FormInputs';
import Context from '../../context/UserContext';
import { FlexBox } from '../tableItems';
import { MainButton } from '../buttons';
import { ProspectFieldsAssign } from '../../models/Prospecting';
import { fetchTemplatesFilter } from '../../helpers/templates';
import { TemplatesRequest } from '../../models/Templates';
import { SitesRequestV2 } from '../../models/Sites';
import { GlobalContextType } from '../../models/Context';
import GeneralContext from '../../context/GeneralActions';
import { fetchSitesAudiencesAffinities } from '../../helpers/sites';
import { fetchAudiencesForForm } from '../../helpers/audience';
import { fetchAffinitiesForForm } from '../../helpers/affinities';
import useSiteStore from '../../stores/sites/sites';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

const cannotAssignUser = ['linkbuilder', 'contractor'];

interface BulkProspectProps {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  managers: Owners[];
  sites: SitesRequestV2[];
  handleBulkUpdate: (
    obj: ProspectFieldsAssign,
    templateBulk?: TemplatesRequest[],
    audienceBulk?: BasicArray[],
    affinityBulk?: BasicArray[],
  ) => void;
  handleSubmitConfirm: (obj: ProspectFieldsAssign) => void;
  isLoading: boolean;
}

const BulkProspect = ({
  open,
  closeModal,
  managers,
  sites,
  handleBulkUpdate,
  isLoading,
  handleSubmitConfirm,
}: BulkProspectProps) => {
  const { userSite, updateUserSite } = useSiteStore();
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const { UserContext } = useContext(Context) as UserContextType;
  const [audiences, setAudiences] = useState<BasicArray[]>([]);
  const [affinities, setAffinities] = useState<BasicArray[]>([]);
  const [templates, setTemplates] = useState<BasicArray[]>([]);
  const [templatesNormal, setTemplatesNormal] = useState<TemplatesRequest[]>(
    [],
  );
  const [prospectFields, setProspectFields] = useState<ProspectFieldsAssign>({
    user: UserContext.id,
    site: 0,
    audience: 0,
    affinity: 0,
    template: 0,
  });

  const getTemplates = async () => {
    try {
      const resp = await fetchTemplatesFilter(
        prospectFields.audience,
        prospectFields.affinity,
        prospectFields.site,
      );
      const templatesFilter = resp.filter(item => item.status === 'active');
      setTemplates(
        templatesFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
      setTemplatesNormal(templatesFilter);
    } catch (err) {
      setTemplates([]);
    }
  };

  const getAudiences = async (siteId: number) => {
    try {
      const resp = await fetchAudiencesForForm(siteId);
      setAudiences(resp);
    } catch (err) {
      setAudiences([]);
    }
  };

  const getAffinities = async (siteId: number, aud: number) => {
    try {
      const resp = await fetchAffinitiesForForm(aud, siteId);
      setAffinities(resp);
    } catch (err) {
      setAffinities([]);
    }
  };

  useEffect(() => {
    if (prospectFields.site !== 0) {
      setTemplates([]);
      setAffinities([]);
      setAudiences([]);
      setProspectFields({
        ...prospectFields,
        template: 0,
        affinity: 0,
        audience: 0,
      });
      getAudiences(prospectFields.site);
    }
  }, [prospectFields.site]);

  useEffect(() => {
    if (prospectFields.audience !== 0) {
      setTemplates([]);
      setAffinities([]);
      setProspectFields({
        ...prospectFields,
        template: 0,
        affinity: 0,
      });
      getAffinities(prospectFields.site, prospectFields.audience);
    }
  }, [prospectFields.audience]);

  useEffect(() => {
    if (prospectFields.audience !== 0 && prospectFields.affinity !== 0) {
      setTemplates([]);
      setProspectFields({
        ...prospectFields,
        template: 0,
      });
      getTemplates();
    }
  }, [prospectFields.audience, prospectFields.affinity]);

  useEffect(() => {
    if (userSite !== 0)
      setProspectFields({
        ...prospectFields,
        site: userSite,
      });
  }, [userSite]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            Bulk Assign
          </Typography>
          <Box marginTop={2}>
            <SelectFormBulk
              width
              label="Assign to"
              required
              options={managers}
              disabled={
                managers.length === 0 ||
                cannotAssignUser.includes(UserContext.profile.role)
              }
              placeholder="Select assigned"
              value={
                managers.find(item => item.id === prospectFields.user) ?? {
                  id: 0,
                  username: '',
                }
              }
              onChange={(value: Owners | null) =>
                setProspectFields({
                  ...prospectFields,
                  user: value ? value.id : 0,
                })
              }
            />
          </Box>
          <Box marginTop={2}>
            <SelectFormProspecting
              required={false}
              width
              options={sites}
              label="Site"
              disabled
              placeholder="Select site"
              value={
                sites.find(item => item.id === prospectFields.site) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) =>
                setProspectFields({
                  ...prospectFields,
                  site: value ? (value.id as number) : 0,
                })
              }
            />
          </Box>

          <Box marginTop={2}>
            <SelectFormProspecting
              required={false}
              width
              label="Audience"
              options={audiences}
              disabled={audiences.length === 0}
              placeholder="Select audience"
              value={
                audiences.find(item => item.id === prospectFields.audience) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) =>
                setProspectFields({
                  ...prospectFields,
                  audience: value ? (value.id as number) : 0,
                })
              }
            />
          </Box>
          <Box marginTop={2}>
            <SelectFormProspecting
              required={false}
              width
              options={affinities}
              label="Affinity"
              disabled={affinities.length === 0}
              placeholder="Select affinity"
              value={
                affinities.find(
                  item => item.id === prospectFields.affinity,
                ) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) =>
                setProspectFields({
                  ...prospectFields,
                  affinity: value ? (value.id as number) : 0,
                })
              }
            />
          </Box>
          <Box marginTop={2}>
            <SelectFormProspecting
              required={false}
              width
              options={templates}
              label="Template"
              disabled={templates.length === 0}
              placeholder="Select template"
              value={
                templates.find(item => item.id === prospectFields.template) ?? {
                  id: 0,
                  name: '',
                }
              }
              onChange={(value: BasicArray | null) =>
                setProspectFields({
                  ...prospectFields,
                  template: value ? (value.id as number) : 0,
                })
              }
            />
          </Box>
          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={() =>
                  handleBulkUpdate(
                    prospectFields,
                    templatesNormal,
                    audiences,
                    affinities,
                  )
                }
                disabled={prospectFields.user === 0}
                sx={{ marginLeft: 1 }}
              >
                Save
              </MainButton>
              <MainButton
                variant="contained"
                onClick={() => handleSubmitConfirm(prospectFields)}
                disabled={prospectFields.user === 0}
                loading={isLoading}
                sx={{ marginLeft: 1 }}
              >
                Save & Submit
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default BulkProspect;
