import { ContactsFormLink } from './Forms';

export interface LinkTransformInterface {
  id: number;
  url: string;
  site: SiteLink;
  template: TemplateLink;
  audience: AudienceLink;
  affinity: AffinityInterface;
  contacts: ContactsFormLink[];
  created: string;
  modified: string;
  found: string;
  owner: OwnerInterface;
  status: string;
  notes_count: number;
}

export interface ContactInterface {
  id: number;
  name: string;
  email: string;
  is_form: boolean;
}

export interface OwnerInterface {
  id: number;
  username: string;
  email: string;
}

export interface AffinityInterface {
  id: number;
  name: string;
  status: string;
}

interface SiteLink {
  id: number;
  name: string;
  status: string;
}

interface TemplateLink {
  id: number;
  title: string;
  status: string;
}

interface AudienceLink {
  id: number;
  name: string;
  status: string;
}

export interface ScanStatusLink {
  id: number;
  created: string;
  modified: string;
  removed: string | null;
  status: string;
  outbound_count: number;
  outbound_warning: boolean;
  title_text: string;
  anchor_text: string;
  anchor_href: string;
  found: null | boolean;
  overriden: boolean | null;
  content_type: string;
  scrape_frequency: string;
  ignore_status: boolean;
  is_override: boolean;
  override_note: null;
  link: number;
}

export interface LinkObject {
  id: number;
  url: string;
  created: string;
  modified: string;
  is_unsubscribed: boolean;
  found: string;
  is_cannibalization: boolean;
  notes_count: number;
  status: string;
  is_older_than_ninety_days: boolean;
  is_assigned_by_manager: boolean;
  is_older_than_thirty_days: boolean;
  unsubscribed_date: string;
  site: SiteLink;
  owner: OwnerInterface;
  template: TemplateLink;
  affinity: AffinityInterface;
  audience: AudienceLink;
}

export interface LinkFetch {
  count: number;
  next: string;
  previous: null | string;
  results: LinkObject[];
}

export interface ValidateEmailInt {
  contact: null | ContactsFormLink;
  duplicate_contact: boolean;
  message: '';
  valid_email: boolean | string;
  valid_contact: boolean;
}

export interface ClaimsLinksInt {
  destination_url: string;
  is_target_page: boolean;
  name: string;
  type: string;
  value: string;
  url_type: string;
  url_tags: string;
  follow_type: string;
  is_link_redirect: boolean;
}

export const ClaimsLinksObj = {
  destination_url: '',
  is_target_page: false,
  name: '',
  type: 'text',
  value: '',
  url_type: '',
  url_tags: 'N/A',
  follow_type: 'DoFollow Link',
  is_link_redirect: false,
};

export interface ClaimPostPutInt {
  link?: number;
  owner: number;
  link_owner: number;
  site: number;
  project?: number;
  template: number;
  audience?: number;
  affinity: number;
  rejected_reason?: string;
  is_cannibalized: boolean;
  rejected_reason_detail?: string;
  approve_reason?: string;
  is_client_facing: boolean;
  url: string;
  found_date?: string | null;
  link_type: string;
  domain_authority: number;
  domain_rating: number;
  points: { name: string; value: number }[];
  notes: string;
  status?: string;
  total_points: number;
  created?: string;
  date_approved?: string | null;
  targets: ClaimsLinksInt[];
}

export const claimPostObjDefault = {
  owner: 0,
  link_type: '',
  link_owner: 0,
  site: 0,
  template: 0,
  project: 0,
  audience: 0,
  is_cannibalized: false,
  affinity: 0,
  url: '',
  domain_authority: 0,
  domain_rating: 0,
  is_client_facing: true,
  points: [],
  notes: '',
  total_points: 0,
  targets: [],
};

interface LinkClaims {
  id: number;
  url: string;
  created: string;
  modified: string;
  found: string | null;
  site: number;
  site_name: string;
  status: string;
  owner: { id: number; username: string; email: string };
  template: { id: number; title: string };
  affinity: { id: number; name: string; status: string };
  audience: { id: number; name: string; status: string };
}

export interface ClaimsResponseInt {
  count: number;
  next: string;
  previous: string;
  results: ClaimsInt[];
}

export interface ClaimsInt {
  id: number;
  audience: { id: number; name: string; status: string };
  affinity: { id: number; name: string; status: string };
  project: { id: number; name: string; status: string };
  project_name: string | null;
  template: { id: number; title: string; status: string };
  site: { id: number; name: string; status: string };
  owner: { id: number; username: string; email: string };
  manager?: { id: number; username: string; email: string };
  link_owner: { id: number; username: string; email: string };
  notes: string;
  rejected_reason?: string;
  rejected_reason_detail?: string;
  created: string;
  is_cannibalized: boolean;
  approved_by?: { id: number; username: string; email: string } | null;
  modified: string;
  date_approved: string | null;
  is_client_facing: boolean;
  approve_reason: null | string;
  status: string;
  found_date: string | null;
  link_type: string;
  duplicated: boolean;
  domain_authority: number;
  domain_rating: number;
  total_points: number;
  url: string;
  targets: ClaimsLinksInt[];
  points: { name: string; value: number; id: number }[];
}

export const claimsObjDef = {
  id: 0,
  audience: { id: 0, name: '', status: '' },
  affinity: { id: 0, name: '', status: '' },
  project_name: '',
  project: { id: 0, name: '', status: '' },
  template: { id: 0, title: '', status: '' },
  site: { id: 0, name: '', status: '' },
  owner: { id: 0, username: '', email: '' },
  link_owner: { id: 0, username: '', email: '' },
  notes: '',
  is_client_facing: true,
  approve_reason: null,
  created: '',
  modified: '',
  date_approved: '',
  is_cannibalized: false,
  status: '',
  found_date: null,
  link_type: '',
  duplicated: false,
  domain_authority: 0,
  domain_rating: 0,
  total_points: 0,
  url: '',
  targets: [],
  points: [],
};

export interface FormClaims {
  link?: number;
  owner: number;
  link_type: string;
  domain_authority: string;
  domain_rating: string;
  is_client_facing: boolean;
  notes: string;
  status?: string;
  created?: string;
  is_cannibalized: boolean;
  approve_reason: null | string;
  date_approved?: string | null;
  found_date: string | null;
  base: { name: string; value: string };
  target_page: { name: string; value: string };
  edu_gov: { name: string; value: string };
  content: { name: string; value: string };
  second_link: { name: string; value: string };
  cannibalized: { name: string; value: string };
  optimized_anchor: { name: string; value: string };
  pleural_meso: { name: string; value: string };
  relevance: { name: string; value: string };
  da_points: { name: string; value: string };
  dr_points: { name: string; value: string };
}

export interface PendingList {
  affinity: { id: number; name: string; status: string };
  audience: { id: number; name: string; status: string };
  template: { id: number; name: string; status: string };
  url: string;
  status: string;
  site: { id: number; name: string; status: string };
  owner: { id: number; username: string; email: string };
  modified: string;
  created: string;
  id: number;
}
