import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../../../components/tableItems';
import { MainButton } from '../../../components/buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface WarningModalProps {
  open: boolean;
  closeModal: () => void;
  continueModal: () => void;
  loading: boolean;
  title: string;
  body: string;
  buttonText: string;
}

const WarningClose: React.FC<WarningModalProps> = ({
  open,
  closeModal,
  continueModal,
  loading,
  title,
  body,
  buttonText,
}) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton
              disabled={loading}
              onClick={closeModal}
              sx={{ fontSize: 17 }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            {title}
          </Typography>
          <Typography marginTop={2} fontSize={14} textAlign="center">
            {body}
          </Typography>
          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                variant="contained"
                onClick={continueModal}
                disabled={loading}
                loading={loading}
                sx={{ marginLeft: 1 }}
              >
                {buttonText}
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default WarningClose;
