import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface PreviewEmailProps {
  open: boolean;
  quillRef: React.MutableRefObject<ReactQuill | null>;
  body: string;
  closeModal: () => void;
  title?: string;
}

const PreviewEmail: React.FC<PreviewEmailProps> = ({
  open,
  closeModal,
  quillRef,
  body,
  title,
}) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <FlexBoxBetween>
            <Box />
            <Typography
              fontWeight={700}
              fontSize={17}
              textAlign="center"
              textTransform="capitalize"
            >
              {title ?? 'Email Preview'}
            </Typography>
            <IconButton
              disabled={false}
              onClick={closeModal}
              sx={{ fontSize: 17 }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </FlexBoxBetween>

          <Box marginTop={3.5}>
            <ReactQuill
              theme="snow"
              style={{ minHeight: '400px' }}
              className="my-custom-preview"
              readOnly
              value={body}
              onChange={() => {}}
              placeholder="Compose your message..."
              modules={{}}
            />
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default PreviewEmail;
