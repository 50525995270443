import dayjs from 'dayjs';
import { Box, Divider, IconButton, InputLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useContext, useState, useEffect } from 'react';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { addNoteClient } from '../../helpers/clients';
import { NotesReq } from '../../models/Notes';
import { MainButton } from '../buttons';
import { FlexBox, FlexBoxBetween } from '../tableItems';
import { TextFormField } from './FormInputs';
import Spinner from '../Loading/Spinner';
import { addNoteCategory } from '../../helpers/category';
import { addNoteProject } from '../../helpers/projects';
import { addNoteSite } from '../../helpers/sites';
import { addNoteTemplate } from '../../helpers/templates';
import { addNoteLink } from '../../helpers/links';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import { CustomTextAreaField } from '../inputs';
import { SeverityType } from '../../models/General';

interface Props {
  id: number;
  notes: NotesReq[];
  getNotes: (id: number) => void;
  loadingNotes: boolean;
  openMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<
    React.SetStateAction<{
      message: string;
      severity: SeverityType;
    }>
  >;
  type: 'client' | 'category' | 'project' | 'site' | 'template' | 'link';
}

const FormNotes = ({
  id,
  openMessage,
  setMessage,
  loadingNotes,
  getNotes,
  notes,
  type,
}: Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { UserContext } = useContext(Context) as UserContextType;
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(5);
  const results = notes.slice(0, pagination);

  const addNote = async () => {
    setLoading(true);
    try {
      if (type === 'client') await addNoteClient({ text_note: note }, id);
      if (type === 'category') await addNoteCategory({ text_note: note }, id);
      if (type === 'project') await addNoteProject({ text_note: note }, id);
      if (type === 'site') await addNoteSite({ text_note: note }, id);
      if (type === 'template') await addNoteTemplate({ text_note: note }, id);
      if (type === 'link')
        await addNoteLink({ body: note, link: id, user: UserContext.id });
      setNote('');
      setMessage({ message: 'Note created successfully', severity: 'success' });
      openMessage(true);
      getNotes(id);
    } catch (err) {
      setMessage({ message: 'Error creating the note', severity: 'error' });
      openMessage(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('otra vez?');
  }, []);

  return (
    <div>
      <Box>
        <CustomTextAreaField
          minRows={4}
          multiline
          maxRows={Infinity}
          disabled={false}
          required
          value={note}
          onChange={e => setNote(e.target.value)}
        />

        <Box justifyContent="end" display="flex">
          <MainButton
            variant="contained"
            loading={loading}
            sx={{ marginTop: 1 }}
            disabled={note === ''}
            onClick={addNote}
          >
            Add Note
          </MainButton>
        </Box>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
      <Box>
        {loadingNotes && <Spinner />}
        {!loadingNotes && notes.length > 0 && (
          <div>
            {results.map(item => (
              <Box key={item.id} marginTop={3}>
                <FlexBoxBetween>
                  <FlexBox>
                    <div className="navbar-user" />
                    <Typography
                      sx={{
                        ml: 1.5,
                        fontWeight: 600,
                        fontSize: 14,
                        color: 'rgba(0, 0, 0, 0.8)',
                      }}
                    >
                      {item.user ? item.user.username : ''}
                    </Typography>
                  </FlexBox>
                  <Typography
                    fontWeight={500}
                    fontSize={13}
                    color="rgba(0, 0, 0, 0.8)"
                  >
                    {dayjs(item.created).format('MM/DD/YYYY')} -{' '}
                    {dayjs(item.created).tz('America/New_York').format('h:mma')}{' '}
                    EST
                  </Typography>
                </FlexBoxBetween>
                <Typography
                  marginTop={1.6}
                  fontSize={15}
                  marginLeft={0.8}
                  whiteSpace="pre-wrap"
                >
                  {item.body}
                </Typography>
              </Box>
            ))}
            {notes.length > pagination && (
              <Box display="flex" justifyContent="center" marginTop={1}>
                <IconButton
                  size="large"
                  sx={{ borderRadius: 20 }}
                  onClick={() => setPagination(pagination + 5)}
                >
                  <Typography>Load more notes...</Typography>
                </IconButton>
              </Box>
            )}
          </div>
        )}
      </Box>
    </div>
  );
};

export default FormNotes;
