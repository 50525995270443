import { ClientsForm, ClientsRequest } from './Clients';
import { SeverityType } from './General';
import { CreateTemplateInt } from './Templates';

interface PropsFormBasic {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'add' | 'edit';
}

interface PropsForm {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'add' | 'edit';
  createSuccess: (
    message: string,
    type: 'client' | 'category' | 'project' | 'site',
  ) => void;
}

export interface PropsClientForm extends PropsForm {
  clientObj?: ClientsForm;
}

export interface PropsCategoryForm extends PropsForm {
  clientsOptions: { label: string; value: string }[];
  clientValue?: number;
  categoryEdit?: number;
}

export interface PropsProjectForm extends PropsForm {
  clientsOptions: { id: number; name: string }[];
  categoryOptions: { id: number; name: string }[];
  clientValue: number;
  categoryValue: number;
  clientsCategories: ClientsRequest[];
  projectEdit?: number;
}

export interface PropsSitesForm extends PropsProjectForm {
  projectValue?: number;
  siteEdit?: number;
}

export interface PropsTemplateForm {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'add' | 'clone' | 'view' | 'edit';
  templateValue?: number;
  templateObj?: CreateTemplateInt;
  tabNumber?: number;
  showMessage: (message: string, severity: SeverityType, type: string) => void;
  review: boolean;
}

export interface UsersSites {
  id: number;
  username: string;
  minimum_links: string;
  percentage_allowed: string;
}

export interface UsersAssignedSelected {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  date_effective: string;
}

export interface SiteFormInt {
  name: string;
  domain: string;
  client: number;
  category: number;
  status: string;
  text_note: string;
  project: number;
  affinities: number[];
  physical_addr: string;
  site_title: string;
  flat_amount: string;
  audiences: number[];
  link_claims_allowed: boolean;
  allow_cannibalization: boolean;
  default_percentage: string;
  days_before_cannibalization: string;
  default_min_links_required: string;
  blacklisted_status_list: string[];
  blacklisted_projects: number[];
  blacklisted_sites: number[];
  user_cannibalization_rule: UsersSites[];
  users_assigned: UsersAssignedSelected[];
  form_contacts_allowed: boolean;
}

export interface ProjectFormInt {
  name: string;
  status: string;
  client: number;
  category: number;
  text_note: string;
  users_assigned: UsersAssignedSelected[];
}

export interface SiteCreateInt {
  name: string;
  domain: string;
  status: string;
  text_note: string;
  project: number;
  affinities: number[];
  audiences: number[];
  link_claims_allowed: boolean;
  allow_cannibalization: boolean;
  days_before_cannibalization: number;
  physical_addr: string;
  site_title: string;
  flat_amount: number;
  default_min_links_required: number;
  blacklisted_status_list: string[];
  blacklisted_projects: number[];
  blacklisted_sites: number[];
  default_percentage: number;
  user_cannibalization_rule: {
    id: number;
    minimum_links: number;
    percentage_allowed: number;
  }[];
  form_contacts_allowed: boolean;
  users_assigned: { id: number; date_effective: string }[];
}

export interface PropsAudienceAffinityForm extends PropsFormBasic {
  optionsArray: { id: number; name: string }[];
  option: 'Audience' | 'Affinity';
  showMessage: (
    message: string,
    option: 'Affinity' | 'Audience',
    severity: SeverityType,
  ) => void;
}

export interface ContactsLatestEvent {
  contact: number;
  created: string;
  id: number;
  ip_address: string;
  link: number;
  modified: string;
  open_event?: null | string;
  notified: boolean;
  owner: number;
  send_timestamp: number;
  site: number;
  template: number;
  user_agent: string;
}

export interface ContactsFormLink {
  id?: number;
  name: string;
  email: string;
  status?: string;
  is_form: boolean;
  close_reason?: string;
  last_outreach?: string;
  form_text?: string;
  form_url?: string;
  is_default?: boolean;
  validation_status: string;
  latest_event?: null | ContactsLatestEvent;
  validOriginalContact?: boolean;
}

export interface ContactsArrayOutreach {
  id: number;
  name: string;
  email: string;
  form_url: string;
  status?: string;
  last_outreach?: string;
  is_form: boolean;
  form_text?: string;
  is_default?: boolean;
  latest_event?: null | ContactsLatestEvent;
  validation_status: string;
  close_reason?: string;
}

export interface RequestContacts {
  id: number;
  contacts_links: ContactsArrayOutreach[];
  latests_outreach: ContactsLatestEvent | null;
}

export const contactStatus = [
  { id: 1, name: 'Open', value: 'open' },
  { id: 2, name: 'Global Closed', value: 'global_closed' },
  { id: 3, name: 'Project Closed', value: 'project_closed' },
  { id: 4, name: 'Site Closed', value: 'site_closed' },
  { id: 5, name: 'Invalid Contact', value: 'invalid_contact' },
];

export const closeTypes = [
  'site_closed',
  'project_closed',
  'global_closed',
  'invalid_contact',
];

export interface ObjectLinks {
  id?: number;
  url: string;
  status: string;
  owner: number;
  assignee: number;
  is_cannibalization: boolean;
  close_reason?: string;
  send_status?: string;
  relevance_close?: boolean;
  site: number;
  affinity: number;
  audience: number;
  is_assigned_by_manager: boolean;
  found_date?: string | null;
  template: number;
  contacts: ContactsFormLink[];
  send_email?: boolean;
  text_note?: string;
  prospecting_assignment?: number;
  cannibalized_link_id?: number;
}

export interface EditObjectLink extends ObjectLinks {
  site_name: string;
  is_older_than_ninety_days: boolean;
  is_older_than_thirty_days: boolean;
  is_unsubscribed: boolean;
  unsubscribed_date: string;
  added_date: string;
  modified_date: string;
  openNewContactModal?: boolean;
}

interface TemplateLinkAudAff {
  id: number;
  name: string;
  status: string;
}

export interface TemplateLink {
  id: number;
  title: string;
  affinity: TemplateLinkAudAff;
  audience: TemplateLinkAudAff;
  subject: string;
  body: string;
  greetings: string;
  signoff: string;
  status: string;
  created: string;
  automatic_greeting: boolean;
  modified: string;
  site: TemplateLinkAudAff;
  owner: { id: number; username: string; email: string };
  status_notes: number[];
  general_notes: number[];
}

export const formTemplateLink = {
  id: 0,
  title: '',
  affinity: { id: 0, name: '', status: '' },
  audience: { id: 0, name: '', status: '' },
  subject: '',
  body: '',
  status: '',
  greetings: '',
  signoff: '',
  created: '',
  automatic_greeting: true,
  modified: '',
  site: { id: 0, name: '', status: '' },
  owner: { id: 0, username: '', email: '' },
  status_notes: [],
  general_notes: [],
};

export const formObjectLink = {
  url: '',
  status: '',
  owner: 0,
  assignee: 0,
  site: 0,
  affinity: 0,
  is_cannibalization: false,
  audience: 0,
  is_assigned_by_manager: false,
  template: 0,
  contacts: [
    { id: 0, name: '', email: '', is_form: false, validation_status: 'active' },
  ],
};

export interface InputInterface {
  id: number;
  value: string;
  fullSpace: boolean;
  required: boolean;
  validate?: boolean;
  validation?: never[];
  body: JSX.Element;
}
