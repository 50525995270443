import axios, { GenericAbortSignal } from 'axios';
import {
  LogIn,
  UserResponse,
  LoginGoogle,
  UserRequest,
  MyProfile,
  FavoriteFilter,
  ListFavoriteFilter,
  CreateUserInt,
  EditUserInt,
  ProfileUser,
  Owners,
} from '../models/User';
import APIClient from '../services/APIClient';
import { ValidateEmailInt } from '../models/Links';
import { HistoryReq } from '../models/Notes';

export const authenticate = async (userInfo: LogIn | LoginGoogle) => {
  const { REACT_APP_API_BASE_URL } = process.env;
  const authResponse = await axios.post<UserResponse>(
    `${REACT_APP_API_BASE_URL}/api/session/authenticate/` as string,
    userInfo,
  );
  return authResponse.data;
};

export const getUsersFilter = async (role?: string) => {
  const clientAPI = new APIClient();
  const usersResponse = await clientAPI.client.get<Owners[] | UserResponse[]>(
    `/users/?version=v2${role ? `&role=${role}` : ''}&is_active=true`,
  );
  return usersResponse.data;
};

export const getAllUsers = async (
  page: number,
  page_size: number,
  search: string,
  order: string,
) => {
  const clientAPI = new APIClient();
  const usersResponse = await clientAPI.client.get<UserRequest>(
    `/users/?page=${page}&page_size=${page_size}${
      search ? `&search=${search}` : ''
    }${order !== '' ? `&ordering=${order}` : ''}&version=v2`,
  );
  return usersResponse.data;
};

export const getProfileInfo = async () => {
  const clientAPI = new APIClient();
  const profileResponse = await clientAPI.client.get<MyProfile>(
    '/users/profile/?version=v2',
  );
  return profileResponse.data;
};

export const getUsersProfiles = async (ids: number[]) => {
  const clientAPI = new APIClient();
  const profileResponse = await clientAPI.client.get<ProfileUser[]>(
    `/users/profiles/?users=${ids.join(',')}&version=v2`,
  );
  return profileResponse.data;
};

export const changePassword = async (
  obj: {
    password: string;
    password_confirmation: string;
  },
  id: number,
) => {
  const clientAPI = new APIClient();
  const changePasswordResponse = await clientAPI.client.put(
    `/users/${id}/change-password/?version=v2`,
    obj,
  );
  return changePasswordResponse.data;
};

export const updateUserInfo = async (id: number, obj: EditUserInt) => {
  const clientAPI = new APIClient();
  const updateResponse = await clientAPI.client.put(
    `/users/${id}/?version=v2`,
    obj,
  );
  return updateResponse.data;
};

export const updateProfilePicture = async (image: File, id: number) => {
  const clientAPI = new APIClient();
  const formData = new FormData();
  formData.append('image', image);
  const profileResponse = await clientAPI.client.put(
    `/users/${id}/change-avatar/?version=v2`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return profileResponse.data;
};

export const validateEmail = async (
  email: string,
  site: number,
  id?: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const validation = await clientAPI.client.get<ValidateEmailInt>(
    `/v2/links/validate-email-with-provider/?email=${email}&site_id=${site}${
      id ? `&id=${id}` : ''
    }`,
    { signal },
  );
  return validation.data;
};

export const createFavFilter = async (obj: FavoriteFilter) => {
  const clientAPI = new APIClient();
  const resp = await clientAPI.client.post(
    '/users/create-favorite-filter/?version=v2',
    obj,
  );
  return resp.data;
};

export const getListFavFilter = async () => {
  const clientAPI = new APIClient();
  const resp = await clientAPI.client.get<ListFavoriteFilter[]>(
    '/users/list-favorite-filters/?version=v2',
  );
  return resp.data;
};

export const deleteFavFilter = async (id: number) => {
  const clientAPI = new APIClient();
  const resp = await clientAPI.client.delete<ListFavoriteFilter[]>(
    `/users/delete-favorite-filter/?filter_id=${id}&version=v2`,
  );
  return resp.data;
};

export const createUser = async (obj: CreateUserInt) => {
  const clientAPI = new APIClient();
  const resp = await clientAPI.client.post('/users/?version=v2', obj);
  return resp.data;
};

export const fetchUserHistory = async (id: number) => {
  const clientAPI = new APIClient();
  const userResponse = await clientAPI.client.get<HistoryReq[]>(
    `/users/${id}/list-status-note/?version=v2`,
  );
  return userResponse.data;
};

export const fetchUserAlias = async (siteId: number) => {
  const clientAPI = new APIClient();
  const userResponse = await clientAPI.client.get<{
    email_alias: null | string;
  }>(`/users/get_email_alias/?site=${siteId}`);
  return userResponse.data;
};

export const getUsers = async () => {
  const clientAPI = new APIClient();
  const userResponse = await clientAPI.client.get<Owners[]>(
    `/users/?ordering=username&minimal=true&is_active=true`,
  );
  return userResponse.data;
};
