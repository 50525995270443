import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBoxBetween } from '../../../../components/tableItems';
import { MainButton } from '../../../../components/buttons';

interface AliasFilterInterface {
  updateDrawerFilter: () => void;
}

const AliasFilter: React.FC<AliasFilterInterface> = ({
  updateDrawerFilter,
}) => {
  return (
    <Box mt={2}>
      <FlexBoxBetween px={3}>
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <IconButton
          aria-label="close"
          disabled={false}
          id="long-button"
          aria-haspopup="true"
          onClick={() => updateDrawerFilter()}
        >
          <CloseIcon
            sx={{
              color: 'black',
              '&:hover': { color: 'red' },
            }}
          />
        </IconButton>
      </FlexBoxBetween>
      <Box mt={1} px={3} display="flex" justifyContent="end">
        <MainButton
          disabled={false}
          size="small"
          variant="contained"
          type="submit"
          onClick={() => {}}
        >
          Apply Filters
        </MainButton>
      </Box>
    </Box>
  );
};

export default AliasFilter;
