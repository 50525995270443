import { Modal, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { FlexBox, FlexBoxBetween } from '../tableItems';
import { TextFormField } from './FormInputs';
import { MainButton } from '../buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 570,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 3,
  pb: 2,
};

interface Props {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateStatus: (note: string, openClaim?: boolean) => void;
  linkNote?: string;
  newValue?: string;
  validUpdate?: boolean;
}

const StatusModal = ({
  open,
  closeModal,
  updateStatus,
  linkNote,
  newValue,
  validUpdate,
}: Props) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(linkNote ?? '');
  }, [linkNote]);

  const validateWithClaim = () => {
    if (newValue !== 'active') return false;

    return validUpdate;
  };

  return (
    <div>
      <Modal open={open}>
        <Box sx={style}>
          <FlexBoxBetween>
            <Typography fontWeight={700} fontSize={17}>
              Please Provide a Note
            </Typography>
            <IconButton onClick={() => closeModal(false)}>
              <CloseIcon />
            </IconButton>
          </FlexBoxBetween>
          <Typography color="#373737" fontSize={14}>
            Please enter a note for the history record.
          </Typography>
          <Box marginTop={2}>
            <TextFormField
              disabled={false}
              nameInput="text_note"
              label="Note"
              error={false}
              multiline
              required
              rows={10}
              value={note}
              errorText=""
              placeholder="Note"
              onChange={(value: string) => setNote(value)}
            />

            <FlexBox marginTop={3} justifyContent="end">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                sx={{ marginX: 1.5 }}
                disabled={note === '' || validateWithClaim()}
                onClick={() => updateStatus(note)}
              >
                Update Status
              </MainButton>
            </FlexBox>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StatusModal;
