/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Box,
  FormControlLabel,
  IconButton,
  InputLabel,
  Tooltip,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import BoxAddSettings from '../BoxAddSettings';
import {
  BasicSelect,
  SelectFormBulk,
  SelectFormProspecting,
  TextFormField,
} from '../FormInputs';
import { Owners, UserContextType } from '../../../models/User';
import { BasicArray } from '../../../models/General';
import BoxInput from '../BoxInput';
import {
  InputInterface,
  ObjectLinks,
  TemplateLink,
} from '../../../models/Forms';
import { DefaultCheckbox, TextInputField } from '../../inputs';
import TargetingComponent from './TargetingComponent';
import Context from '../../../context/UserContext';
import { onlyNumbers } from '../../../helpers/validateInputs';
import {
  ClaimPostPutInt,
  ClaimsInt,
  ClaimsLinksInt,
  ClaimsLinksObj,
  FormClaims,
} from '../../../models/Links';
import { FlexBox } from '../../tableItems';
import { ApproveRejectButton, MainButton } from '../../buttons';
import DateInput from '../DateInput';
import { TemplateAudience } from '../../../models/Templates';
import { fetchTemplatesFilter } from '../../../helpers/templates';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import ClaimRejectModal from '../../../pages/DefaultView/Links/ClaimsTable/ClaimRejectModal';
import ClaimApproveModal from '../../../pages/DefaultView/Links/ClaimsTable/ClaimApproveModal';
import { IS_USER_ADMIN } from '../../../constants/user';
import TabParent from '../../Tab/TabParent';
import ClaimAddHeader from '../../../pages/DefaultView/LinkForm/ClaimLink/ClaimAddHeader';

interface ClaimTabProps {
  owners: Owners[];
  objEdit?: ObjectLinks;
  audienceTemporal?: TemplateAudience;
  affinityTemporal?: TemplateAudience;
  siteTemp?: { id: number; name: string };
  tempsLinks?: TemplateLink;
  handleClaimDisabled: (value: boolean) => void;
  sites: BasicArray[];
  handleUpdateClaimObj: (obj: ClaimPostPutInt) => void;
  handleSaveForm: (obj?: ClaimPostPutInt, id?: number) => Promise<void>;
  claimLinks?: ClaimsInt;
  readMode?: boolean;
  loading?: boolean;
  type?: string;
  setOpenDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  warningModal?: (value: boolean) => void;
}

const linkType = [
  { value: 'Resource Page', label: 'Resource Page' },
  { value: 'Editorial', label: 'Editorial' },
  { value: 'Blog', label: 'Blog' },
  { value: 'Home Page', label: 'Home Page' },
  { value: 'Menu Drop-Down', label: 'Menu Drop-Down' },
  { value: 'Footer', label: 'Footer' },
];

const returnPointsValue = (
  arr: { name: string; value: number; id: number }[],
  name: string,
) => {
  const value = arr.find(item => item.name === name);
  if (value) {
    return value.value ? value.value.toString() : '0';
  }
  return '0';
};

const canEditOwner = [
  'manager',
  'account manager',
  'administrator',
  'dev admin',
  'strategist',
];

const ClaimTab = ({
  owners,
  objEdit,
  handleClaimDisabled,
  handleUpdateClaimObj,
  claimLinks,
  handleSaveForm,
  audienceTemporal,
  affinityTemporal,
  tempsLinks,
  sites,
  siteTemp,
  loading,
  readMode = false,
  type,
  setOpenDrawer,
  warningModal,
}: ClaimTabProps) => {
  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);
  const [render, setRender] = useState(0);
  const { UserContext } = useContext(Context) as UserContextType;
  const [tabValue, setTabValue] = useState(0);
  const updateActiveStep = (value: number) => setTabValue(value);

  const [openModalReject, setOpenModalReject] = useState(false);
  const closeModalReject = () => setOpenModalReject(false);

  const [openModalApprove, setOpenModalApprove] = useState(false);
  const closeModalApprove = () => setOpenModalApprove(false);

  const [totalPoints, setTotalPoints] = useState(0);
  const [errors, setErrors] = useState(false);
  const [disabledAll, setDisabledAll] = useState(true);
  const [urls, setUrls] = useState<ClaimsLinksInt[]>(() => {
    if (claimLinks) {
      const urlsTransform = claimLinks.targets.map(item => {
        const itemUrlTag = item.url_tags ?? '';
        return {
          destination_url: item.destination_url,
          value: item.value === null ? '' : item.value,
          is_target_page: item.is_target_page,
          name: item.name === null ? '' : item.name,
          type: item.type ?? 'text',
          url_type: item.url_type ?? '',
          url_tags: itemUrlTag.length === 0 ? 'N/A' : itemUrlTag,
          follow_type: item.follow_type ?? 'DoFollow Link',
          is_link_redirect: item.is_link_redirect ?? false,
        };
      });
      return urlsTransform;
    }
    return [ClaimsLinksObj];
  });
  const [audienceOptions, setAudienceOptions] = useState<TemplateAudience[]>(
    [],
  );
  const [affinityOptions, setAffinityOptions] = useState<TemplateAudience[]>(
    [],
  );

  const [templatesOpt, setTemplatesOpt] = useState<BasicArray[]>([]);

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const validationInputs = {
    site: claimLinks ? claimLinks.site.id : objEdit?.site ?? 0,
    audience: claimLinks
      ? claimLinks.audience
        ? claimLinks.audience.id
        : 0
      : objEdit?.audience ?? 0,
    affinity: claimLinks
      ? claimLinks.affinity
        ? claimLinks.affinity.id
        : 0
      : objEdit?.affinity ?? 0,
    template: claimLinks
      ? claimLinks.template
        ? claimLinks.template.id
        : 0
      : objEdit?.template ?? 0,
  };

  const [additionalObj, setAdditionalObj] = useState({
    site: claimLinks ? claimLinks.site.id : objEdit?.site ?? 0,
    audience: claimLinks
      ? claimLinks.audience
        ? claimLinks.audience.id
        : 0
      : objEdit?.audience ?? 0,
    affinity: claimLinks
      ? claimLinks.affinity
        ? claimLinks.affinity.id
        : 0
      : objEdit?.affinity ?? 0,
    template: claimLinks
      ? claimLinks.template
        ? claimLinks.template.id
        : 0
      : objEdit?.template ?? 0,
  });
  const [errorsArr, setErrorsArr] = useState<number[]>(() => {
    const arr = [];
    if (additionalObj.audience === 0) arr.push(4);
    if (additionalObj.affinity === 0) arr.push(5);
    if (additionalObj.template === 0) arr.push(6);
    return arr;
  });
  const [claimObj, setClaimObj] = useState<FormClaims>({
    owner: UserContext.id,
    link_type: '',
    domain_authority: '',
    domain_rating: '',
    approve_reason: null,
    is_client_facing: true,
    notes: '',
    is_cannibalized: false,
    found_date: claimLinks
      ? claimLinks.found_date
      : objEdit
      ? objEdit.found_date ?? null
      : null,
    base: { name: 'Base', value: '1' },
    target_page: { name: 'Target Page', value: '0' },
    edu_gov: { name: '.Edu/.Gov', value: '0' },
    content: { name: 'Content', value: '0' },
    second_link: { name: 'Second Link', value: '0' },
    cannibalized: { name: 'Cannibalized', value: '0' },
    optimized_anchor: { name: 'Optimized Anchor', value: '0' },
    pleural_meso: { name: 'Pleural/Meso Prog', value: '0' },
    relevance: { name: 'Relevance', value: '0' },
    da_points: { name: 'DA Points', value: '0' },
    dr_points: { name: 'DR Points', value: '0' },
  });

  const disabledInputs = () => {
    if (canEditOwner.includes(UserContext.profile.role)) return false;
    if (UserContext.id === claimObj.owner) return false;
    return true;
  };

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const getTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const resp = await fetchTemplatesFilter(
        additionalObj.audience,
        additionalObj.affinity,
        additionalObj.site,
      );

      const tempsFilter = resp
        .filter(template => template.status === 'active')
        .map(item => {
          return { id: item.id, name: item.title };
        });

      if (
        tempsLinks &&
        siteTemp &&
        tempsFilter.findIndex(item => item.id === tempsLinks.id) === -1 &&
        siteTemp.id === additionalObj.site
      ) {
        setTemplatesOpt([
          ...tempsFilter,
          { id: tempsLinks.id, name: tempsLinks.title },
        ]);
        return;
      }
      setTemplatesOpt(tempsFilter);
    } catch (err) {
      setTemplatesOpt([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (
      additionalObj.audience !== 0 &&
      additionalObj.affinity !== 0 &&
      audienceOptions.length > 0 &&
      affinityOptions.length > 0
    )
      getTemplates();
  }, [
    additionalObj.audience,
    additionalObj.affinity,
    audienceOptions,
    affinityOptions,
  ]);

  const fetchingAffinities = async () => {
    if (additionalObj.site === 0 || additionalObj.audience === 0) return [];
    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        additionalObj.audience,
        additionalObj.site,
        abortControllerAffinity.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const getAffinities = async () => {
    setLoadingAff(true);
    setAffinityOptions([]);
    setTemplatesOpt([]);
    cancelRequestAff();
    cancelRequestTemplate();

    const resp = await fetchingAffinities();

    if (
      affinityTemporal &&
      siteTemp &&
      resp.findIndex(item => item.id === affinityTemporal.id) === -1 &&
      siteTemp.id === additionalObj.site
    ) {
      setAffinityOptions([...resp, affinityTemporal]);
    } else {
      setAffinityOptions(resp);
    }

    setLoadingAff(false);
  };

  const fetchingAudiences = async () => {
    if (additionalObj.site === 0) return [];
    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        additionalObj.site,
        abortControllerAudience.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const getAudiences = async () => {
    setLoadingAud(true);
    setAudienceOptions([]);
    setAffinityOptions([]);
    setTemplatesOpt([]);
    if (render !== 0) {
      cancelRequestAud();
      cancelRequestAff();
      cancelRequestTemplate();
    }

    const resp = await fetchingAudiences();

    if (
      audienceTemporal &&
      siteTemp &&
      resp.findIndex(item => item.id === audienceTemporal.id) === -1 &&
      siteTemp.id === additionalObj.site
    ) {
      setAudienceOptions([...resp, audienceTemporal]);
    } else {
      setAudienceOptions(resp);
    }

    setLoadingAud(false);
  };

  useEffect(() => {
    if (additionalObj.audience !== 0) getAffinities();
  }, [additionalObj.audience]);

  useEffect(() => {
    if (render === 0) setRender(prev => prev + 1);
    if (additionalObj.site !== 0) {
      getAudiences();
    }
  }, [additionalObj.site]);

  const checkError = (value: string, id: number) => {
    const copyArr = [...errorsArr];
    if (id === 4 && !copyArr.includes(5) && additionalObj.affinity === 0)
      copyArr.push(5);
    if (id === 4 && !copyArr.includes(6) && additionalObj.template === 0)
      copyArr.push(6);
    if (id === 5 && !copyArr.includes(6) && additionalObj.template === 0)
      copyArr.push(6);
    if (value === '') {
      if (copyArr.includes(id)) return;
      copyArr.push(id);
      setErrorsArr(copyArr);
      return;
    }
    const newArr = copyArr.filter(item => item !== id);
    setErrorsArr(newArr);
  };

  const validateAll = () => {
    console.log(claimObj);
    console.log(errors);
    console.log(additionalObj);
    const validate = urls.filter(
      item =>
        item.destination_url === '' ||
        item.value === '' ||
        item.url_tags.length === 0 ||
        item.url_type === '',
    );
    if (
      claimObj.link_type === '' ||
      validate.length > 0 ||
      errors ||
      additionalObj.site === 0 ||
      additionalObj.audience === 0 ||
      additionalObj.affinity === 0 ||
      additionalObj.template === 0
    )
      return true;

    return false;
  };

  const inputsArray: InputInterface[] = [
    {
      id: 0,
      value: '0',
      fullSpace: false,
      required: true,
      body: (
        <SelectFormBulk
          required
          options={owners}
          bigger
          label="Link Owner"
          disabled
          placeholder="Select assigned"
          width
          value={
            owners.find(
              item =>
                item.id ===
                (claimLinks ? claimLinks.link_owner.id : objEdit?.owner ?? 0),
            ) ?? {
              id: 0,
              username: '',
            }
          }
          onChange={(value: Owners | null) => {}}
        />
      ),
    },
    {
      id: 1,
      value: '0',
      fullSpace: false,
      required: true,
      body: (
        <SelectFormBulk
          required
          options={owners}
          bigger
          label="Claim Owner"
          disabled={
            !canEditOwner.includes(UserContext.profile.role) || readMode
          }
          placeholder="Select assigned"
          width
          value={
            owners.find(item => item.id === claimObj.owner) ?? {
              id: 0,
              username: '',
            }
          }
          onChange={(value: Owners | null) =>
            setClaimObj({ ...claimObj, owner: value?.id ?? 0 })
          }
        />
      ),
    },
    {
      id: 2,
      value: '0',
      fullSpace: true,
      required: false,
      body: (
        <SelectFormProspecting
          required
          options={sites}
          width
          label="Site"
          disabled
          placeholder="Select site"
          bigger
          value={
            sites.find(
              item =>
                item.id ===
                (claimLinks ? claimLinks.site.id : objEdit?.site ?? 0),
            ) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={() => {}}
        />
      ),
    },
    {
      id: 3,
      value: '0',
      fullSpace: true,
      required: false,
      body: (
        <>
          <InputLabel
            htmlFor="url"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
            }}
          >
            URL *
          </InputLabel>
          <TextInputField
            value={claimLinks ? claimLinks.url : objEdit?.url ?? ''}
            name="url"
            disabled
            error={false}
            size="small"
            onChange={e => {}}
            fullWidth
            placeholder="URL"
            id="url"
            helperText=""
          />
        </>
      ),
    },

    {
      id: 4,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <SelectFormProspecting
          required
          options={audienceOptions}
          loading={loadingAud}
          width
          label="Audience"
          onBlur={value => checkError(value, 4)}
          error={errorsArr.includes(4)}
          disabled={
            (validationInputs.affinity !== 0 &&
              validationInputs.audience !== 0) ||
            audienceOptions.length === 0 ||
            readMode
          }
          placeholder="Select audience"
          bigger
          value={
            audienceOptions.find(
              item => item.id === additionalObj.audience,
            ) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setAdditionalObj({
              ...additionalObj,
              audience: value ? (value.id as number) : 0,
              affinity: 0,
              template: 0,
            });
          }}
        />
      ),
    },
    {
      id: 5,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <SelectFormProspecting
          required
          options={affinityOptions}
          loading={loadingAff}
          width
          label="Affinity"
          onBlur={value => checkError(value, 5)}
          error={errorsArr.includes(5)}
          disabled={
            (validationInputs.affinity !== 0 &&
              validationInputs.audience !== 0) ||
            affinityOptions.length === 0 ||
            readMode
          }
          placeholder="Select affinity"
          bigger
          value={
            affinityOptions.find(
              item => item.id === additionalObj.affinity,
            ) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setAdditionalObj({
              ...additionalObj,
              affinity: value ? (value.id as number) : 0,
              template: 0,
            });
          }}
        />
      ),
    },
    {
      id: 6,
      value: '0',
      fullSpace: true,
      required: false,
      body: (
        <SelectFormProspecting
          required
          options={templatesOpt}
          width
          loading={loadingTemplates}
          onBlur={value => checkError(value, 6)}
          error={errorsArr.includes(6)}
          label="Template"
          disabled={
            templatesOpt.length === 0 ||
            (validationInputs.affinity !== 0 &&
              validationInputs.audience !== 0 &&
              validationInputs.template !== 0) ||
            readMode
          }
          placeholder="Select template"
          bigger
          value={
            templatesOpt.find(item => item.id === additionalObj.template) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setAdditionalObj({
              ...additionalObj,
              template: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 7,
      value: '0',
      fullSpace: false,
      required: true,
      body: (
        <BasicSelect
          label="Link Type"
          required
          options={linkType}
          error={errorsArr.includes(7)}
          nameInput="link-type"
          onBlur={value => checkError(value, 7)}
          disabled={disabledInputs() || readMode}
          value={claimObj.link_type}
          onChange={(value: string) =>
            setClaimObj({ ...claimObj, link_type: value })
          }
        />
      ),
    },
    {
      id: 55,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="domain-rating"
          onBlur={value => checkError(value, 8)}
          label="Domain Rating"
          value={claimObj.domain_rating}
          error={false}
          type="number"
          errorText=""
          placeholder="Domain Rating"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              domain_rating: onlyNumbers(value),
            })
          }
        />
      ),
    },
    {
      id: 8,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <>
          {IS_USER_ADMIN.includes(UserContext.profile.role) && (
            <TextFormField
              required={false}
              disabled={disabledInputs() || readMode}
              nameInput="domain-auth"
              onBlur={value => checkError(value, 8)}
              label="Domain Authority"
              value={claimObj.domain_authority}
              error={false}
              errorText=""
              placeholder="Domain Authority"
              onChange={(value: string) =>
                setClaimObj({
                  ...claimObj,
                  domain_authority: onlyNumbers(value),
                })
              }
            />
          )}
        </>
      ),
    },
    {
      id: 22,
      value: '0',
      fullSpace: false,
      required: true,
      body: (
        <FormControlLabel
          label="Client Facing"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: 14,
              color: '#000000',
            },
          }}
          control={
            <DefaultCheckbox
              disabled={readMode}
              id="is_client_facing"
              name="is_client_facing"
              size="medium"
              checked={claimObj.is_client_facing}
              onClick={() =>
                setClaimObj({
                  ...claimObj,
                  is_client_facing: !claimObj.is_client_facing,
                })
              }
            />
          }
        />
      ),
    },
    {
      id: 9,
      value: '0',
      fullSpace: true,
      required: false,
      body: (
        <TextFormField
          required={false}
          multiline
          disabled={disabledInputs() || readMode}
          nameInput="notes"
          label="Notes"
          error={false}
          rows={12}
          value={claimObj.notes}
          errorText=""
          placeholder=""
          onChange={(value: string) =>
            setClaimObj({ ...claimObj, notes: value })
          }
        />
      ),
    },
    {
      id: 10,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="base"
          label="Base"
          value={claimObj.base.value}
          error={false}
          type="number"
          errorText=""
          placeholder="Base"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              base: {
                name: 'Base',
                value: onlyNumbers(value),
              },
            })
          }
        />
      ),
    },
    {
      id: 11,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="target-page"
          label="Target Page"
          value={claimObj.target_page.value}
          error={false}
          type="number"
          errorText=""
          placeholder="Target Page"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              target_page: {
                name: 'Target Page',
                value: onlyNumbers(value),
              },
            })
          }
        />
      ),
    },
    {
      id: 12,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="edu-gov"
          label=".Edu/.Gov"
          value={claimObj.edu_gov.value}
          error={false}
          type="number"
          errorText=""
          placeholder=".Edu/.Gov"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              edu_gov: {
                name: '.Edu/.Gov',
                value: onlyNumbers(value),
              },
            })
          }
        />
      ),
    },
    {
      id: 13,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="content"
          label="Content"
          value={claimObj.content.value}
          error={false}
          type="number"
          errorText=""
          placeholder="Content"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              content: {
                name: 'Content',
                value: onlyNumbers(value),
              },
            })
          }
        />
      ),
    },
    // {
    //   id: 14,
    //   value: '0',
    //   fullSpace: false,
    //   required: false,
    //   body: (
    //     <TextFormField
    //       required={false}
    //       disabled={disabledInputs() || readMode}
    //       nameInput="second-link"
    //       label="Second Link"
    //       value={claimObj.second_link.value}
    //       type="number"
    //       error={false}
    //       errorText=""
    //       placeholder="Second Link"
    //       onChange={(value: string) =>
    //         setClaimObj({
    //           ...claimObj,
    //           second_link: {
    //             name: 'Second Link',
    //             value: onlyNumbers(value),
    //           },
    //         })
    //       }
    //     />
    //   ),
    // },
    // {
    //   id: 15,
    //   value: '0',
    //   fullSpace: false,
    //   required: false,
    //   body: (
    //     <TextFormField
    //       required={false}
    //       disabled={disabledInputs() || readMode}
    //       nameInput="cannibalized"
    //       label="Cannibalized"
    //       value={claimObj.cannibalized.value}
    //       error={false}
    //       errorText=""
    //       placeholder="Cannibalized"
    //       type="number"
    //       onChange={(value: string) =>
    //         setClaimObj({
    //           ...claimObj,
    //           cannibalized: {
    //             name: 'Cannibalized',
    //             value: onlyNumbers(value),
    //           },
    //         })
    //       }
    //     />
    //   ),
    // },
    {
      id: 16,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="optimized-anchor"
          label="Optimized Anchor"
          value={claimObj.optimized_anchor.value}
          type="number"
          error={false}
          errorText=""
          placeholder="Optimized Anchor"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              optimized_anchor: {
                name: 'Optimized Anchor',
                value: onlyNumbers(value),
              },
            })
          }
        />
      ),
    },
    // {
    //   id: 17,
    //   value: '0',
    //   fullSpace: false,
    //   required: false,
    //   body: (
    //     <TextFormField
    //       required={false}
    //       disabled={disabledInputs() || readMode}
    //       nameInput="pleural-meso"
    //       label="Pleural/Meso Prog"
    //       type="number"
    //       value={claimObj.pleural_meso.value}
    //       error={false}
    //       errorText=""
    //       placeholder="Pleural/Meso Prog"
    //       onChange={(value: string) =>
    //         setClaimObj({
    //           ...claimObj,
    //           pleural_meso: {
    //             name: 'Pleural/Meso Prog',
    //             value: onlyNumbers(value),
    //           },
    //         })
    //       }
    //     />
    //   ),
    // },
    {
      id: 18,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled={disabledInputs() || readMode}
          nameInput="relevance"
          label="Relevance"
          value={claimObj.relevance.value}
          error={false}
          errorText=""
          type="number"
          placeholder="Relevance"
          onChange={(value: string) =>
            setClaimObj({
              ...claimObj,
              relevance: {
                name: 'Relevance',
                value: onlyNumbers(value),
              },
            })
          }
        />
      ),
    },
    {
      id: 20,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <>
          <TextFormField
            required={false}
            disabled={disabledInputs() || readMode}
            nameInput="dr-points"
            label="DR Points"
            value={claimObj.dr_points.value}
            error={false}
            type="number"
            errorText=""
            placeholder="DR Points"
            onChange={(value: string) =>
              setClaimObj({
                ...claimObj,
                dr_points: {
                  name: 'DR Points',
                  value: onlyNumbers(value),
                },
              })
            }
          />
        </>
      ),
    },
    // {
    //   id: 19,
    //   value: '0',
    //   fullSpace: false,
    //   required: false,
    //   body: (
    //     <>
    //       {IS_USER_ADMIN.includes(UserContext.profile.role) && (
    //         <TextFormField
    //           required={false}
    //           disabled={disabledInputs() || readMode}
    //           nameInput="da-points"
    //           label="DA Points"
    //           value={claimObj.da_points.value}
    //           error={false}
    //           type="number"
    //           errorText=""
    //           placeholder="DA Points"
    //           onChange={(value: string) =>
    //             setClaimObj({
    //               ...claimObj,
    //               da_points: {
    //                 name: 'DA Points',
    //                 value: onlyNumbers(value),
    //               },
    //             })
    //           }
    //         />
    //       )}
    //     </>
    //   ),
    // },

    {
      id: 20,
      value: '0',
      fullSpace: false,
      required: false,
      body: (
        <TextFormField
          required={false}
          disabled
          nameInput="total-points"
          label="Total Points"
          value={totalPoints.toString()}
          error={false}
          errorText=""
          placeholder="Total Points"
          onChange={(value: string) => {}}
        />
      ),
    },
  ];

  const returnNotNaN = (value: string) =>
    value === '' ? 0 : parseFloat(value);

  const handleUpdateTargets = (value: ClaimsLinksInt[]) => {
    setUrls(value);
  };

  const handleErrors = (value: boolean) => setErrors(value);

  const createObject = (total?: number, status?: string) => {
    const pointsObj = [
      claimObj.base,
      claimObj.target_page,
      claimObj.edu_gov,
      claimObj.content,
      claimObj.second_link,
      claimObj.cannibalized,
      claimObj.optimized_anchor,
      claimObj.pleural_meso,
      claimObj.relevance,
      claimObj.da_points,
      claimObj.dr_points,
    ].map(item => {
      return {
        name: item.name,
        value: item.value === '' ? 0 : parseFloat(item.value),
      };
    });
    const obj: ClaimPostPutInt = {
      url: claimLinks ? claimLinks.url : objEdit?.url ?? '',
      link_owner: claimLinks ? claimLinks.link_owner.id : objEdit?.owner ?? 0,
      site: additionalObj.site,
      template: additionalObj.template,
      audience: additionalObj.audience,
      affinity: additionalObj.affinity,
      owner: claimObj.owner,
      link_type: claimObj.link_type,
      is_client_facing: claimObj.is_client_facing ?? true,
      found_date: claimObj.found_date,
      is_cannibalized: claimObj.is_cannibalized,
      domain_authority: parseInt(claimObj.domain_authority, 10),
      domain_rating: parseFloat(claimObj.domain_rating),
      notes: claimObj.notes,
      total_points: total ?? totalPoints,
      points: pointsObj,
      targets: urls,
    };
    if (obj.audience === 0) delete obj.audience;
    if (objEdit) obj.link = objEdit.id;
    if (claimLinks) {
      obj.status = status ?? claimObj.status;
      // Format date to 'YYYY-MM-DDThh:mm:ss.sssZ'
      if (claimObj.created) {
        const createdDate = new Date(claimObj.created);
        obj.created = createdDate.toISOString();
      }
      if (claimObj.date_approved) {
        const approvedDate = new Date(claimObj.date_approved);
        obj.date_approved = approvedDate.toISOString();
      }
    }
    return obj;
  };

  useEffect(() => {
    setDisabledAll(validateAll());
  }, [errors]);

  useEffect(() => {
    if (render !== 0) setDisabledAll(validateAll());
  }, [additionalObj]);

  useEffect(() => {
    handleClaimDisabled(disabledAll);
  }, [disabledAll]);

  useEffect(() => {
    handleUpdateClaimObj(createObject());
    setDisabledAll(validateAll());
  }, [urls]);

  useEffect(() => {
    if (claimLinks) {
      const urlsTransform = claimLinks.targets.map(item => {
        const itemUrlTag = item.url_tags ?? '';
        return {
          destination_url: item.destination_url,
          value: item.value === null ? '' : item.value,
          is_target_page: item.is_target_page,
          name: item.name === null ? '' : item.name,
          type: item.type ?? 'text',
          url_type: item.url_type ?? '',
          url_tags: itemUrlTag.length === 0 ? 'N/A' : itemUrlTag,
          follow_type: item.follow_type ?? 'DoFollow Link',
          is_link_redirect: item.is_link_redirect ?? false,
        };
      });
      setUrls(urlsTransform);
      const obj = {
        owner: claimLinks.owner.id,
        link_type: claimLinks.link_type,
        domain_authority: claimLinks.domain_authority
          ? claimLinks.domain_authority.toString()
          : '0',
        domain_rating: claimLinks.domain_rating
          ? claimLinks.domain_rating.toString()
          : '0',
        notes: claimLinks.notes ?? '',
        status: claimLinks.status,
        created: claimLinks.created,
        found_date: claimLinks.found_date,
        date_approved: claimLinks.date_approved,
        approve_reason: claimLinks.approve_reason,
        is_cannibalized: claimLinks.is_cannibalized,
        is_client_facing: claimLinks.is_client_facing,
        base: {
          name: 'Base',
          value: returnPointsValue(claimLinks.points, 'Base'),
        },
        target_page: {
          name: 'Target Page',
          value: returnPointsValue(claimLinks.points, 'Target Page'),
        },
        edu_gov: {
          name: '.Edu/.Gov',
          value: returnPointsValue(claimLinks.points, '.Edu/.Gov'),
        },
        content: {
          name: 'Content',
          value: returnPointsValue(claimLinks.points, 'Content'),
        },
        second_link: {
          name: 'Second Link',
          value: returnPointsValue(claimLinks.points, 'Second Link'),
        },
        cannibalized: {
          name: 'Cannibalized',
          value: returnPointsValue(claimLinks.points, 'Cannibalized'),
        },
        optimized_anchor: {
          name: 'Optimized Anchor',
          value: returnPointsValue(claimLinks.points, 'Optimized Anchor'),
        },
        pleural_meso: {
          name: 'Pleural/Meso Prog',
          value: returnPointsValue(claimLinks.points, 'Pleural/Meso Prog'),
        },
        relevance: {
          name: 'Relevance',
          value: returnPointsValue(claimLinks.points, 'Relevance'),
        },
        da_points: {
          name: 'DA Points',
          value: returnPointsValue(claimLinks.points, 'DA Points'),
        },
        dr_points: {
          name: 'DR Points',
          value: returnPointsValue(claimLinks.points, 'DR Points'),
        },
      };
      setClaimObj(obj);
    }
  }, [claimLinks]);

  useEffect(() => {
    const arrayNumbers = [
      returnNotNaN(claimObj.base.value),
      returnNotNaN(claimObj.target_page.value),
      returnNotNaN(claimObj.edu_gov.value),
      returnNotNaN(claimObj.content.value),
      returnNotNaN(claimObj.second_link.value),
      returnNotNaN(claimObj.cannibalized.value),
      returnNotNaN(claimObj.optimized_anchor.value),
      returnNotNaN(claimObj.pleural_meso.value),
      returnNotNaN(claimObj.relevance.value),
      returnNotNaN(claimObj.da_points.value),
      returnNotNaN(claimObj.dr_points.value),
    ];
    const total = parseFloat(
      arrayNumbers.reduce((a, b) => a + b, 0).toFixed(2),
    );
    setTotalPoints(claimObj.is_cannibalized ? total / 2 : total);

    handleUpdateClaimObj(
      createObject(claimObj.is_cannibalized ? total / 2 : total),
    );

    setDisabledAll(validateAll());
  }, [claimObj]);

  const handleResubmitClaim = () => {
    const obj = createObject(undefined, 'review');
    handleSaveForm(obj);
  };

  const validateStepOne = () => {
    if (
      additionalObj.site === 0 ||
      additionalObj.audience === 0 ||
      additionalObj.affinity === 0 ||
      additionalObj.template === 0 ||
      errorsArr.includes(4) ||
      errorsArr.includes(5) ||
      errorsArr.includes(6)
    )
      return true;

    return false;
  };

  const validateStepTwo = () => {
    const validate = urls.filter(
      item =>
        item.destination_url === '' ||
        item.value === '' ||
        item.url_tags.length === 0 ||
        item.url_type === '',
    );

    if (validate.length > 0 || errors) return true;
    return false;
  };

  const validateStepThree = () => claimObj.link_type === '';

  return (
    <>
      <Box mt={2}>
        {type === 'create' && (
          <ClaimAddHeader
            saveClaim={handleSaveForm}
            activeStep={tabValue}
            updateStep={updateActiveStep}
            loadingForm={loading ?? false}
            stepOneFormValid={validateStepOne()}
            stepTwoFormValid={validateStepTwo()}
            stepThreeFormValid={validateStepThree()}
            updateOpenWarningAdd={() => {
              if (warningModal) warningModal(true);
            }}
            validateClear={false}
          />
        )}
      </Box>
      {type !== 'create' && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginTop: 1,
          }}
        >
          <TabParent
            valueTab={tabValue}
            smaller
            setTab={setTabValue}
            tabs={[
              { id: 0, label: 'Link', disabled: false },
              { id: 1, label: 'Targeting', disabled: false },
              { id: 2, label: 'Claim', disabled: false },
              { id: 3, label: 'Points', disabled: false },
            ]}
            style
            center
          />
        </Box>
      )}
      <Box mt={2} display={tabValue === 0 ? '' : 'none'}>
        <BoxAddSettings title="Owner Info" mb={0}>
          <BoxInput inputs={inputsArray.slice(0, 3)} />
        </BoxAddSettings>
        <BoxAddSettings title="Link Info" mb={0} pt={2}>
          <BoxInput inputs={inputsArray.slice(3, 7)} />
          <div className="form-grids">
            <div className="mb-2">
              <InputLabel
                htmlFor="date-found"
                sx={{
                  fontSize: 12,
                  fontWeight: '700',
                  color: 'rgba(0, 0, 0, 0.8)',
                  marginBottom: 1,
                }}
              >
                Date Found
              </InputLabel>
              <DateInput
                value={claimObj.found_date}
                disabled
                fullWidth
                handleChangeDate={() => {}}
              />
            </div>
          </div>
        </BoxAddSettings>
      </Box>
      <Box mt={2} display={tabValue === 1 ? '' : 'none'}>
        <BoxAddSettings title="Targeting" mb={0} pt={2}>
          <TargetingComponent
            handleUpdateTargets={handleUpdateTargets}
            handleErrors={handleErrors}
            urlsEdit={urls}
            disableInputs={disabledInputs() || readMode}
          />
        </BoxAddSettings>
      </Box>
      <Box mt={2} display={tabValue === 2 ? '' : 'none'}>
        <BoxAddSettings title="Claim Info" mb={0} pt={2}>
          <BoxInput inputs={inputsArray.slice(7, 10)} />
          <div className="form-grids">
            <div>{inputsArray[10].body}</div>
            <FlexBox>
              <FormControlLabel
                label="Cannibalized"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    color: '#000000',
                  },
                }}
                control={
                  <DefaultCheckbox
                    disabled={readMode}
                    id="is_cannibalized"
                    name="is_cannibalized"
                    size="medium"
                    checked={claimObj.is_cannibalized}
                    onClick={() =>
                      setClaimObj({
                        ...claimObj,
                        is_cannibalized: !claimObj.is_cannibalized,
                      })
                    }
                  />
                }
              />
              <Tooltip title="Total point value of the link will be 50% when enabled">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </FlexBox>
          </div>

          {claimLinks && (
            <div className="form-grids mb-2">
              <div className="mb-2">
                <InputLabel
                  htmlFor="date-created"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Date Created
                </InputLabel>
                <DateInput
                  value={claimObj.created ?? null}
                  disabled={
                    claimObj.status !== 'approved' &&
                    claimObj.owner === UserContext.id
                      ? false || readMode
                      : !canEditOwner.includes(UserContext.profile.role) ||
                        readMode
                  }
                  fullWidth
                  handleChangeDate={(value: string) =>
                    setClaimObj({
                      ...claimObj,
                      created: value,
                    })
                  }
                />
              </div>
              {claimLinks.status === 'approved' && (
                <div className="mb-2">
                  <InputLabel
                    htmlFor="date-approved"
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    Date Approved
                  </InputLabel>
                  <DateInput
                    value={claimObj.date_approved ?? null}
                    fullWidth
                    disabled={
                      !canEditOwner.includes(UserContext.profile.role) ||
                      readMode
                    }
                    handleChangeDate={(value: string) =>
                      setClaimObj({
                        ...claimObj,
                        date_approved: value,
                      })
                    }
                  />
                </div>
              )}

              <div className="mb-2">
                <BasicSelect
                  label="Status"
                  required={false}
                  options={[
                    { value: 'approved', label: 'Approved' },
                    { value: 'unclaimed', label: 'Unclaimed' },
                    { value: 'review', label: 'Review' },
                    { value: 'rejected', label: 'Rejected' },
                  ]}
                  nameInput="status"
                  disabled={
                    !canEditOwner.includes(UserContext.profile.role) || readMode
                  }
                  value={claimObj.status ?? ''}
                  onChange={(value: string) =>
                    setClaimObj({ ...claimObj, status: value })
                  }
                />
              </div>
            </div>
          )}
          <Box mb={1}>{inputsArray[11].body}</Box>
        </BoxAddSettings>
      </Box>
      <Box mt={2} display={tabValue === 3 ? '' : 'none'}>
        <BoxAddSettings title="Points" mb={4} pt={2}>
          <div className="form-grids-points mb-2">
            {inputsArray.slice(12, 20).map(item => (
              <div key={item.id}>{item.body}</div>
            ))}
          </div>
        </BoxAddSettings>
      </Box>
      {claimLinks &&
        claimLinks.status === 'rejected' &&
        (UserContext.profile.role === 'linkbuilder' ||
          UserContext.profile.role === 'contractor') &&
        UserContext.id === claimLinks.owner.id && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={4}
          >
            <ApproveRejectButton
              onClick={handleResubmitClaim}
              disabled={false}
              sx={{
                background: '#4B4B4B',
                '&:hover': { background: '#5A5A5A' },
              }}
            >
              Resubmit Claim
            </ApproveRejectButton>
          </Box>
        )}
      {canEditOwner.includes(UserContext.profile.role) && !readMode && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
        >
          <FlexBox>
            <MainButton
              loading={false}
              variant="contained"
              sx={{
                backgroundColor: '#EB9393',
                color: 'black',
                marginRight: 2,
                '&:hover': {
                  backgroundColor: '#EB9393',
                },
              }}
              disabled={disabledAll}
              onClick={() => setOpenModalReject(true)}
            >
              Reject
            </MainButton>
            <MainButton
              loading={false}
              variant="contained"
              sx={{
                backgroundColor: '#BAF372',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
              }}
              disabled={disabledAll}
              onClick={() => setOpenModalApprove(true)}
            >
              Approve
            </MainButton>
          </FlexBox>
        </Box>
      )}
      <ClaimRejectModal
        openModal={openModalReject}
        closeModal={closeModalReject}
        linkOwner={
          owners.find(
            item =>
              item.id ===
              (claimLinks ? claimLinks.link_owner.id : objEdit?.owner ?? 0),
          )?.username ?? ''
        }
        linkURL={createObject().url}
        claimObject={createObject()}
        handleSubmit={handleSaveForm}
        loading={loading ?? false}
      />{' '}
      <ClaimApproveModal
        openModal={openModalApprove}
        closeModal={closeModalApprove}
        linkOwner={
          owners.find(
            item =>
              item.id ===
              (claimLinks ? claimLinks.link_owner.id : objEdit?.owner ?? 0),
          )?.username ?? ''
        }
        linkURL={createObject().url}
        claimObject={createObject()}
        handleSubmit={handleSaveForm}
        loading={loading ?? false}
      />
    </>
  );
};

export default ClaimTab;
