/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import {
  TableCell,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import { LinkObject } from '../../models/Links';
import { FlexBox, TableCellCustom } from '../tableItems';
import GeneralContext from '../../context/GeneralActions';
import { GlobalContextType } from '../../models/Context';
import { VisibleColumnsInterface } from '../../models/Filters';
import { linkHeadCells } from '../../models/Table';
import TableHeadComp from '../Table/TableHead.tsx/TableHeadComp';
import TableWrapper from '../Table/TableWrapper';
import { MenuOptionsDropdown, Order } from '../../models/General';
import TableCellMain from '../Table/Rows/TableCellMain';
import { getKeyValue, sendBackObject } from '../../helpers/helpersfunctions';
import Context from '../../context/UserContext';
import { UserContextType } from '../../models/User';
import { IS_USER_ADMIN, ROLES_LINKS } from '../../constants/user';
import returnColorsStatus from '../../pages/DefaultView/Links/linksConstants';
import { LinkStatuses } from '../../models/Status';
import useBuildOutreach from '../../customHooks/useBuildOutreach';
import { RequestContacts } from '../../models/Forms';
import useLinkForm from '../../stores/links/useLinkForm';
import useSiteStore from '../../stores/sites/sites';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: grey[100],
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface Props {
  links: LinkObject[];
  loading: boolean;
  columnsVisibility: VisibleColumnsInterface;
  countItems: number;
  error: boolean;
  fetchLinks: () => void;
  editLink: (
    link: LinkObject,
    statusLink: string,
    tabEdit?: number,
    unclaimed?: boolean,
    openNewContact?: boolean,
    openClaim?: boolean,
  ) => void;
  deleteLinkAction: (id: number) => void;
  handleSendEmail: (link: LinkObject, statusLink: string) => void;
  tabLinks: number;
  statusOfLinks: LinkStatuses[];
  contacts: RequestContacts[];
}

type CountObjectType = {
  amount_remaining: number;
  percentage_remaining: number;
  previous_month_total: number;
  counter_links_dupulicated_current_month: number;
  user_percentage_allowed: null | number;
  site_default_percentage: number;
};

const EnhancedTable = ({
  links,
  loading,
  countItems,
  error,
  fetchLinks,
  columnsVisibility,
  editLink,
  handleSendEmail,
  deleteLinkAction,
  tabLinks,
  statusOfLinks,
  contacts,
}: Props) => {
  dayjs.extend(relativetime);
  const currentMonth = dayjs().format('MMMM');

  const { UserContext } = useContext(Context) as UserContextType;

  const { userSite, updateUserSite } = useSiteStore();

  const { resultsBuild, loading: loadingBuild } = useBuildOutreach();

  const { loadingForm } = useLinkForm();
  const [countObject, setCountObject] = useState<CountObjectType>({
    amount_remaining: 0,
    percentage_remaining: 0,
    previous_month_total: 0,
    counter_links_dupulicated_current_month: 0,
    user_percentage_allowed: null,
    site_default_percentage: 0,
  });
  const [order, setOrder] = React.useState<Order>('desc');
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [orderBy, setOrderBy] = React.useState<string>('created');

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    const propertyChanged =
      property === 'found_not_null'
        ? `${orderRequest === 'desc' ? property : '-found_not_null'},${
            orderRequest === 'desc' ? '-' : ''
          }found`
        : property;
    setGlobalContext({
      ...GlobalContext,
      links: {
        ...GlobalContext.links,
        order: `${orderRequest === 'desc' ? '-' : ''}${propertyChanged}`,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      links: { ...GlobalContext.links, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      links: {
        ...GlobalContext.links,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleEdit = (
    id: number,
    tab?: number,
    openNewContact?: boolean,
    openClaim?: boolean,
  ) => {
    const link = links.find(item => item.id === id);
    if (link) {
      editLink(
        link,
        link.status,
        tab ?? 0,
        undefined,
        openNewContact,
        openClaim,
      );
    }
  };

  const handleEmail = (id: number) => {
    const link = links.find(item => item.id === id);
    if (link) {
      handleSendEmail(link, link.status);
    }
  };

  const menuOptions: MenuOptionsDropdown[] = [
    {
      id: 1,
      title: 'History',
      click: (id: number) => {
        handleEdit(id, 2);
      },
    },
    {
      id: 2,
      title: 'Send New Email',
      click: (id: number) => {
        handleEmail(id);
      },
    },
  ];

  if (sendBackObject(UserContext.profile.role, ROLES_LINKS).delete) {
    menuOptions.push({
      id: 3,
      title: 'Delete',
      click: (id: number) => deleteLinkAction(id),
      delete: true,
    });
  }

  const getClassName = (name: string): string =>
    getKeyValue(name)(columnsVisibility) ? 'present-column' : 'hide-column';

  const getStatusName = (status: string) => {
    const findStatus = statusOfLinks.find(item => item.value === status);
    if (findStatus) return findStatus.name;
    return '';
  };

  const findContacts = (id: number) => {
    const contactsLink = contacts.find(contact => contact.id === id);

    if (!contactsLink) return null;
    return contactsLink.latests_outreach === null
      ? null
      : contactsLink.latests_outreach;
  };

  const getColorOutreach = (id: number) => {
    // if (!IS_USER_ADMIN.includes(UserContext.profile.role)) return '';
    const getContactsValue = findContacts(id);

    if (getContactsValue === null) return '';

    return getContactsValue.open_event === null ? '#e7e7e7' : '#BAF372';
  };

  return (
    <>
      <TableWrapper
        loading={loading}
        error={error}
        type="link"
        totalCells={6}
        fetchInfo={fetchLinks}
        itemsLength={links.length}
        countItems={countItems}
        search
        pageSize={GlobalContext.links.page_size}
        showCount
        page={GlobalContext.links.page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        HeadOfTable={
          <TableHeadComp
            order={order}
            headCells={linkHeadCells}
            orderBy={orderBy}
            columnsVisibility={columnsVisibility}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {!loading && links.length > 0 && (
          <>
            {links.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  key={row.id}
                  sx={{ cursor: !loadingForm ? 'pointer' : 'default' }}
                  className="column-row"
                  onClick={() => {
                    if (!loadingForm) handleEdit(row.id, undefined, true);
                  }}
                >
                  <TableCellMain
                    deleteLittleMenu
                    id={row.id}
                    title={row.url}
                    colorButtonIcon={getColorOutreach(row.id)}
                    changeEditIcon={
                      row.status === 'invalid_contact' &&
                      row.is_older_than_thirty_days
                    }
                    type="link"
                    index={index === 0 ? 2 : index}
                    notes={row.notes_count}
                    menuOptions={menuOptions}
                    labelId={labelId}
                    handleEdit={handleEdit}
                  />
                  <TableCell size="small" className={getClassName('status')}>
                    {row.status && statusOfLinks.length > 0 && (
                      <div
                        className="status_row"
                        style={{
                          backgroundColor: returnColorsStatus(row.status)
                            .bgColor,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 11,

                            '@media (min-width: 1900px)': {
                              fontSize: 13,
                            },
                          }}
                          color={returnColorsStatus(row.status).fontColor}
                        >
                          {getStatusName(row.status)}
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                  <TableCellCustom
                    size="small"
                    className={getClassName('owner__username')}
                  >
                    {row.owner ? row.owner.username : ''}
                  </TableCellCustom>
                  <TableCellCustom
                    size="small"
                    className={getClassName('modified')}
                  >
                    {dayjs(row.modified).format('MM-DD-YYYY')}
                  </TableCellCustom>
                  <TableCellCustom
                    size="small"
                    className={`table_overflow-text ${getClassName(
                      'site__name',
                    )}`}
                  >
                    {row.site.name}
                  </TableCellCustom>
                  {/* <TableCellCustom
                    size="small"
                    className={`table_overflow-text ${getClassName(
                      'template__title',
                    )}`}
                  >
                    {row.template ? row.template.title : ''}
                  </TableCellCustom> */}
                  {/* <TableCellCustom
                    size="small"
                    className={`table_overflow-text ${getClassName(
                      'affinity__name',
                    )}`}
                  >
                    {row.affinity ? row.affinity.name : ''}
                  </TableCellCustom>

                  <TableCellCustom
                    size="small"
                    className={`table_overflow-text ${getClassName(
                      'audience__name',
                    )}`}
                  >
                    {row.audience ? row.audience.name : ''}
                  </TableCellCustom>
                  <TableCellCustom
                    size="small"
                    className={getClassName('created')}
                  >
                    {dayjs(row.created).format('MM-DD-YYYY')}
                  </TableCellCustom> */}
                  <TableCellCustom
                    size="small"
                    className={getClassName('is_unsubscribed')}
                  >
                    {row.is_unsubscribed ? 'Yes' : 'No'}
                  </TableCellCustom>

                  <TableCellCustom
                    size="small"
                    className={getClassName('found_not_null')}
                  >
                    {row.found ? dayjs(row.found).format('MM-DD-YYYY') : ''}
                  </TableCellCustom>
                </StyledTableRow>
              );
            })}
          </>
        )}
      </TableWrapper>
      {/* <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        {}
      </MainDrawer> */}
    </>
  );
};
export default EnhancedTable;
