import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { CustomTextAreaField } from '../../../components/inputs';
import { MainButton } from '../../../components/buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface PreviewTemplateProps {
  open: boolean;
  body: string;
  closeModal: () => void;
  saveNote: () => void;
  updateBody: (value: string) => void;
  loadingSave: boolean;
}

const PreviewTemplate: React.FC<PreviewTemplateProps> = ({
  open,
  closeModal,
  body,
  saveNote,
  updateBody,
  loadingSave,
}) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <FlexBoxBetween>
            <Box />
            <Typography
              fontWeight={700}
              fontSize={17}
              textAlign="center"
              textTransform="capitalize"
            >
              Template Note Preview
            </Typography>
            <IconButton
              disabled={false}
              onClick={closeModal}
              sx={{ fontSize: 17 }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </FlexBoxBetween>

          <Box marginTop={3.5}>
            <CustomTextAreaField
              placeholder="Form Text"
              minRows={4}
              multiline
              maxRows={Infinity}
              disabled={false}
              required
              helperText=""
              error={false}
              value={body ?? ''}
              onChange={e => {
                updateBody(e.target.value);
              }}
            />
          </Box>

          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal()}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={saveNote}
                disabled={body === ''}
                loading={loadingSave}
                sx={{ marginLeft: 1 }}
              >
                Save
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default PreviewTemplate;
