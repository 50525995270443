import { Box, Modal, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import HeaderDrawer from '../../../Drawers/HeaderDrawer';
import {
  ProjectSitesFinalSave,
  ProjectSitesInt,
  ProjectSitesSearch,
  ProjectSitesSelectedInt,
  ProjectsRequest,
  UserProjectSites,
} from '../../../../models/Projects';
import useSearch from '../../../../customHooks/useSearch';
import SearchBox from './SearchBox';
import SearchInputModal from './SearchInputModal';
import RowProjectSites from './RowProjectSites';
import TableSiteAccess from './TableSiteAccess';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  borderRadius: '2px',
  boxShadow: 24,
  overflowY: 'auto',
  pb: 5,
};

interface Props {
  open: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveProjects: (
    arr: ProjectSitesFinalSave[],
    site_ids: UserProjectSites[],
    project_ids: UserProjectSites[],
  ) => void;
  sitesSelected: ProjectSitesFinalSave[];
  projects: ProjectsRequest[];
}

const ProjectsSitesModal = ({
  open,
  closeModal,
  handleSaveProjects,
  projects,
  sitesSelected,
}: Props) => {
  const [onlyProjects, setOnlyProjects] = useState<ProjectSitesInt[]>([]);
  const [onlySites, setOnlySites] = useState<ProjectSitesInt[]>([]);
  const [searchResult, setSearchResult] = useState<number>(0);
  const [query, setQuery] = useState('');
  const { searchProjects, searchSites } = useSearch({
    query,
    projects: onlyProjects,
    sites: onlySites,
  });
  const [selected, setSelected] = useState<ProjectSitesSelectedInt[][]>([[]]);
  const [selectedIsProject, setSelectedIsProject] = useState<boolean[]>([]);
  const [disabledSave, setDisabledSave] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const setInfo = () => {
    const projectsTransform = projects.map(item => {
      return {
        id: item.id,
        name: item.name,
        status: item.status,
      };
    });
    setOnlyProjects(projectsTransform);
    const sitesTransform = projects.map(item => {
      const sites = item.sites.map(site => {
        return {
          id: site.id,
          name: site.name,
          status: site.status,
          projectName: item.name,
        };
      });
      return sites;
    });
    setOnlySites(sitesTransform.flat());
  };

  const handleSearch = (item: ProjectSitesSearch) => {
    setShowSearch(false);
    const { id, projectName } = item;
    if (projectName) {
      const project = projects.find(proj => proj.name === projectName);
      if (project) {
        setSearchResult(project.id);
      }
      return;
    }
    const project = projects.find(proj => proj.id === id);
    if (project) setSearchResult(project.id);
  };

  const handleChecks = (
    arrId: number,
    item: ProjectSitesSelectedInt[],
    isProject: boolean,
  ) => {
    const copy = [...selected];
    copy.splice(arrId, 1, item);
    setSelected(copy);
    const copyIsProject = [...selectedIsProject];
    copyIsProject.splice(arrId, 1, isProject);
    setSelectedIsProject(copyIsProject);
  };

  const handleSave = () => {
    const projectIds = projects.map((item, index) => {
      if (
        item.sites.length > 0 &&
        selectedIsProject[index] &&
        selected[index].length > 0
      ) {
        return {
          id: item.id,
          date_effective: dayjs(selected[index][0].projectDate).format(),
        };
      }
      return { id: 0, date_effective: '' };
    });
    const sitesIds = projects.map((item, index) => {
      if (
        item.sites.length > 0 &&
        selected[index].length > 0 &&
        !selectedIsProject[index]
      ) {
        const sites = selected[index].map(site => {
          return {
            id: site.id,
            date_effective: dayjs(site.date).format(),
          };
        });
        return sites;
      }
      return { id: 0, date_effective: '' };
    });
    const sitesIdsFlat = sitesIds.flat().filter(item => item.id !== 0);
    const projectsIdsNotNull = projectIds.filter(item => item.id !== 0);
    const savedProjects: ProjectSitesFinalSave[] = projects
      .map((item, index) => {
        return {
          id: item.id,
          name: item.name,
          projectDate: selected[index][0]
            ? selected[index][0].projectDate
            : dayjs().format('MM/DD/YYYY'),
          sites: selected[index],
          isProject: selectedIsProject[index],
        };
      })
      .filter(item => item.sites.length > 0);
    handleSaveProjects(savedProjects, sitesIdsFlat, projectsIdsNotNull);
  };

  useEffect(() => {
    if (sitesSelected.length > 0) {
      const projectsHandle = projects.map(item => {
        const arrFind = sitesSelected.find(site => site.id === item.id);
        if (arrFind) {
          return arrFind.sites;
        }
        return [];
      });
      setSelected(projectsHandle);
      const areProjectsArr: boolean[] = [];
      projects.forEach(item => {
        const arrFind = sitesSelected.findIndex(site => site.id === item.id);
        if (arrFind !== -1) {
          areProjectsArr.push(sitesSelected[arrFind].isProject ?? false);
        } else {
          areProjectsArr.push(false);
        }
      });
      setSelectedIsProject(areProjectsArr);
    }
  }, [sitesSelected]);

  useEffect(() => {
    const findResults = selected.find(item => item.length > 0);
    if (findResults) setDisabledSave(false);
    else setDisabledSave(true);
  }, [selected]);

  useEffect(() => {
    if (query === '' && searchResult) setSearchResult(0);
  }, [query]);

  useEffect(() => {
    if (projects.length > 0) {
      setInfo();
      setSelected(new Array(projects.length).fill([]));
      setSelectedIsProject(new Array(projects.length).fill(false));
    }
  }, [projects]);

  useEffect(() => {
    return () => {
      setQuery('');
    };
  }, [open]);

  return (
    <Modal open={open}>
      <Box sx={style} className="site_access-responsive">
        <HeaderDrawer
          title="User Site Access"
          threeButtons={false}
          saveFunction={handleSave}
          closeDrawer={closeModal}
          loading={false}
          disabled={disabledSave}
        />
        <Box paddingX={2.5} paddingY={2.4}>
          <Typography fontSize={12} marginBottom={1.3}>
            To grant access to a user, search below
          </Typography>
          <Box position="relative">
            <SearchInputModal
              onChangeFunc={(value: string) => setQuery(value)}
              onBlurFunc={() => {}}
              onFocusFunc={() => setShowSearch(true)}
            />
            {showSearch && query !== '' && (
              <SearchBox
                projects={searchProjects}
                sites={searchSites}
                handleSearch={handleSearch}
              />
            )}
          </Box>
          <Box marginTop={3}>
            <TableSiteAccess>
              {projects.map((project, index) => (
                <>
                  {searchResult !== 0 && project.id === searchResult && (
                    <RowProjectSites
                      key={project.id}
                      project={project}
                      arrId={index}
                      arr={selected[index]}
                      handleChecks={handleChecks}
                      isProject={selectedIsProject[index]}
                    />
                  )}
                  {searchResult === 0 && (
                    <RowProjectSites
                      key={project.id}
                      project={project}
                      arrId={index}
                      handleChecks={handleChecks}
                      arr={selected[index]}
                      isProject={selectedIsProject[index]}
                    />
                  )}
                </>
              ))}
            </TableSiteAccess>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProjectsSitesModal;
