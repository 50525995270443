import { create } from 'zustand';

interface SiteStore {
  userSite: number;
  updateUserSite: (value: number) => void;
}

const useSiteStore = create<SiteStore>(set => ({
  userSite: 0,

  updateUserSite: newVal => set({ userSite: newVal }),
}));

export default useSiteStore;
