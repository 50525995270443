/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import unsubscribelogo from '../../assets/logos/unsubscribe.png';
import {
  unsubscribeUser,
  revertSubscribeUser,
} from '../../helpers/unsubscribed';
import { FlexBox } from '../../components/tableItems';
import { CustomRadio, FormWithLabel } from '../../components/inputs';
import { MainButton } from '../../components/buttons';
import feedbackOptions from './UnsubscribeConstants';
import FeedbackMessage from '../../components/FeedbackMessage';

const Unsubscribe = () => {
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedback, setFeedback] = useState<string[]>([]);
  const [suscriptionRevert, setSuscriptionRevert] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const email = searchParams.get('email');
  const fc = searchParams.get('fc');

  const sendRequest = async (
    first_param: string,
    second_param?: string,
    third_param?: string,
  ) => {
    setLoading(true);
    try {
      if (feedback.length > 0) {
        await unsubscribeUser(first_param, second_param, third_param, {
          feedback: feedback.join(','),
        });
        setFeedbackSent(true);
      } else await unsubscribeUser(first_param, second_param, third_param);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const revertRequest = async (
    first_param: string,
    second_param?: string,
    third_param?: string,
  ) => {
    setLoading(true);
    try {
      await revertSubscribeUser(first_param, second_param, third_param);
      setSuscriptionRevert(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && (email || fc))
      sendRequest(id, email ?? undefined, fc ?? undefined);
  }, []);

  const isFeedbackSelected = (option: string) => feedback.includes(option);

  const updateFeedback = (option: string) => {
    const copyFeedback = [...feedback];
    const isFeedbackThere = copyFeedback.includes(option);

    setFeedback(
      isFeedbackThere
        ? copyFeedback.filter(item => item !== option)
        : [...copyFeedback, option],
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          background: '#535353',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '100vh',
          py: '20px',
          px: { xs: '20px', sm: 3, md: 10 },
        }}
      >
        <FlexBox
          sx={{
            padding: '20px',
            flexDirection: { xs: 'column', lg: 'row' },
            border: 1,
            borderRadius: 3,
            bgcolor: 'white',
            width: '100%',
            maxWidth: { lg: '1100px' },
          }}
          className="unsubscribe-box"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '40%',
            }}
            className="logo"
          >
            <img
              src={unsubscribelogo}
              alt="Unsubscribe"
              style={{ maxWidth: '100%' }}
            />
          </Box>
          <Divider
            className="line"
            orientation="vertical"
            flexItem
            sx={{ mx: 3 }}
          />
          <Box
            ml={{ xs: 0, sm: 3 }}
            my={2}
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            <Typography variant="subtitle1" gutterBottom fontWeight={600}>
              Unsubscribed Successfully
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} marginTop={3}>
              Thank you for your time. You will no longer receive emails from
              me.
            </Typography>

            <Typography variant="body1" fontWeight={500} mt={2}>
              If you unsubscribed by mistake, you can resubscribe to receive
              future correspondence.
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              marginTop={2}
              marginBottom={1}
            >
              If you have a moment, please tell me why you chose to unsubscribe
              (optional).
            </Typography>
            <Box>
              {feedbackOptions.map((item, index) => (
                <FormWithLabel
                  key={index}
                  sx={{ display: 'block' }}
                  value
                  control={
                    <CustomRadio
                      size="small"
                      onClick={() => updateFeedback(item)}
                      checked={isFeedbackSelected(item)}
                    />
                  }
                  label={item}
                />
              ))}
            </Box>
            <FlexBox>
              {!feedbackSent && !suscriptionRevert && (
                <MainButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    marginTop: 2,
                    backgroundColor: '#BAF372',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#BAF372',
                    },
                  }}
                  disabled={!id || (!email && !fc)}
                  onClick={() => {
                    if (feedback.length === 0) {
                      setOpenMessage(true);
                      return;
                    }
                    if (id && (email || fc))
                      sendRequest(id, email ?? undefined, fc ?? undefined);
                  }}
                >
                  Send Feedback
                </MainButton>
              )}
              {(feedbackSent || suscriptionRevert) && (
                <Box
                  paddingX={2.7}
                  marginTop={2}
                  paddingY={1.6}
                  borderRadius={2}
                  sx={{ backgroundColor: '#84D0F0', display: 'inline-block' }}
                >
                  <Typography fontSize={15} fontWeight={600}>
                    {suscriptionRevert ? 'Resubscribed' : 'Feedback Sent!'}
                  </Typography>
                </Box>
              )}
              {!suscriptionRevert && (
                <MainButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    marginTop: 2,
                    ml: 2,
                    backgroundColor: 'gray',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                  }}
                  disabled={!id || (!email && !fc)}
                  onClick={() => {
                    if (id && (email || fc))
                      revertRequest(id, email ?? undefined, fc ?? undefined);
                  }}
                >
                  Resubscribe
                </MainButton>
              )}
            </FlexBox>
          </Box>
        </FlexBox>
      </Box>
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="center"
        severity="error"
        message="No feedback has been selected"
      />
    </>
  );
};

export default Unsubscribe;
