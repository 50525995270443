import { HeadCell } from '../../../models/Table';

export interface IAliasTable {
  id: number;
  alias: string;
  user: string;
  site: string;
  project: string;
  status: string;
}

export const columnsAlias: HeadCell[] = [
  {
    id: 'alias',
    disablePadding: false,
    disableSort: false,
    main: true,
    label: 'Alias',
  },
  {
    id: 'user',
    disablePadding: false,
    disableSort: false,
    label: 'User',
  },
  {
    id: 'site',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    id: 'project',
    disablePadding: false,
    disableSort: false,
    label: 'Project',
  },
  {
    id: 'status',
    disablePadding: false,
    disableSort: false,
    label: 'Status',
  },
];

export const dummyDataAlias: IAliasTable[] = [
  {
    id: 1,
    alias: 'jsantamaria@drugwatch.com',
    user: 'jsantamaria',
    site: 'drugwatch.com',
    project: '',
    status: 'active',
  },
  {
    id: 2,
    alias: 'ejimenez@asbestos.com',
    user: 'ejimenez',
    site: 'asbestos.com',
    project: '',
    status: 'validating',
  },
  {
    id: 3,
    alias: 'cquiroz@asbestos.com',
    user: 'cquiroz',
    site: '',
    project: 'asbestos.com',
    status: 'draft',
  },
  {
    id: 4,
    alias: 'nmcunu@consumernotice.org',
    user: 'nmcunu',
    site: 'consumernotice.org',
    project: '',
    status: 'active',
  },
  {
    id: 5,
    alias: 'alawless@drugwatch.com',
    user: 'alawless',
    site: 'drugwatch.com',
    project: '',
    status: 'failed',
  },
  {
    id: 6,
    alias: 'crasmussen@drugwatch.com',
    user: 'crasmussen',
    site: 'drugwatch.com',
    project: '',
    status: 'draft',
  },
];
