/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import Context from '../../../../context/UserContext';
import { UserContextType } from '../../../../models/User';
import useLinkForm from '../../../../stores/links/useLinkForm';
import {
  IS_USER_ADMIN,
  IS_USER_ADMIN_MANAGER,
} from '../../../../constants/user';
import useSidebar from '../../../../stores/links/useSidebar';

import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import {
  CustomRadio,
  CustomTextAreaField,
  DefaultCheckbox,
  FormWithLabel,
  SelectInput,
  TextInputField,
  TextInputFieldError,
  TextInputPendingOutreach,
} from '../../../../components/inputs';
import { BasicArray, SeverityType } from '../../../../models/General';
import {
  SelectFormInput,
  TextFormField,
} from '../../../../components/Forms/FormInputs';
import {
  closeTypes,
  ContactsFormLink,
  ContactsLatestEvent,
  contactStatus,
} from '../../../../models/Forms';
import BoxAddSettings from '../../../../components/Forms/BoxAddSettings';
import { fetchAudiencesForForm } from '../../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../../helpers/affinities';
import {
  fetchTemplatesFilter,
  getTemplateTranslate,
} from '../../../../helpers/templates';
import { MainButton } from '../../../../components/buttons';
import { SitesFieldInterface } from '../FormModel';
import PreviewTemplate from '../PreviewTemplate';
import { addNoteLink } from '../../../../helpers/links';

type ArrayOptions = {
  name: string;
  id: number;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface ContactsEditProps {
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  allowOpenNewModalContact: boolean;
  doNotDeleteContact: string;
  openNewContactModal: () => void;
  form_contacts_allowed: boolean;
  getNotes: () => void;
}

const statusBlock = ['project_closed', 'site_closed', 'global_closed'];

const ContactsEdit: React.FC<ContactsEditProps> = ({
  handleFeedbackMessage,
  allowOpenNewModalContact,
  openNewContactModal,
  doNotDeleteContact,
  form_contacts_allowed,
  getNotes,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const isLowerThan90Days = (day: string) => {
    const diffMilSeconds = Math.abs(+new Date(day) - +new Date());
    const diffDays = Math.ceil(diffMilSeconds / (1000 * 60 * 60 * 24));
    return diffDays < 90;
  };
  const { UserContext } = useContext(Context) as UserContextType;
  const isAdmin = IS_USER_ADMIN.includes(UserContext.profile.role);
  const isAdminManager = IS_USER_ADMIN_MANAGER.includes(
    UserContext.profile.role,
  );
  const {
    linkForm,
    contactsValues,
    toggleIsForm,
    addContact,
    deleteContact,
    linkFormErrors,
    updateContact,
    validateField,
    getOriginalStatus,
    validateEmail,
    updateLinkForm,
    originalContactsValues,
    editLinkForm,
  } = useLinkForm();

  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState<ArrayOptions[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<ArrayOptions[]>([]);
  const [templateOptions, setTemplateOptions] = useState<ArrayOptions[]>([]);
  const { formSidebar, available } = useSidebar();

  const [loadingTemplateNote, setLoadingTemplateNote] = useState(false);
  const [templateNote, setTemplateNote] = useState('');
  const [contactTemplateId, setContactTemplateId] = useState(0);

  const updateTemplateNote = (value: string) => setTemplateNote(value);

  const [previewTemplateModal, setPreviewTemplateModal] = useState(false);
  const openPreviewTemplateModal = () => setPreviewTemplateModal(true);
  const closePreviewTemplateModal = () => setPreviewTemplateModal(false);

  const [loadingSaveNoteTemplate, setLoadingSaveNoteTemplate] = useState(false);

  const updateIsDefault = (value: boolean, index: number) => {
    if (value && linkForm.contacts.find(item => item.is_default)) {
      handleFeedbackMessage('You can only have one default contact', 'error');
      return;
    }

    if (
      value &&
      contactsValues[index].validEmail === false &&
      !linkForm.contacts[index].is_form
    ) {
      handleFeedbackMessage('Contact not valid', 'error');
      return;
    }
    updateContact(index, { is_default: value });
  };

  const disableAdd = () => {
    if (formSidebar.type === 'read') return true;
    if (allowOpenNewModalContact && doNotDeleteContact === '') return false;
    return (
      available.disabled ||
      (linkForm.contacts.length === 1 &&
        linkForm.contacts[0].is_form &&
        linkForm.contacts[0].name === '')
    );
  };

  const tooltipInformation = (status: string | undefined) => {
    const statusContact = status ?? '';
    return (
      <>
        <Box>
          {statusContact === closeTypes[0] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Site Close: No one from this site should reach out to this
                contact again, but other sites have the ability to.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `Your content is out of date.`
              </Typography>
            </>
          )}{' '}
          {statusContact === closeTypes[1] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Project Close: No one from this project should reach out to this
                contact again, but other projects have the ability to.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                *i.e If a contact is marked as a Project Close on
                ConsumerNotice, no one from any of the Mass Torts projects will
                be able to reach out to them.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `We can only share information from government
                agencies or non-profits.`
              </Typography>
            </>
          )}
          {statusContact === closeTypes[2] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Global Close: No one from Launch That Linkbuilding should reach
                out again.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `If you email us again, we will report you for
                harassment and pursue legal action.`
              </Typography>
            </>
          )}
          {statusContact === closeTypes[3] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Invalid Contact: The contacts for this entry are not valid.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `An “Email Bounced” error message.“XYZ no
                longer works at this company.”“I am not responsible for this,
                XYZ is the director of this program.”`
              </Typography>
            </>
          )}
        </Box>
      </>
    );
  };

  const returnTime = (date: string) =>
    `${dayjs(date).format('MM/DD/YYYY')} - ${dayjs(date)
      .tz('America/New_York')
      .format('h:mma')} EST`;

  const getOutReachValue = (latest_event: ContactsLatestEvent | null) => {
    if (latest_event === null || latest_event === undefined)
      return { value: 'Pending Outreach', color: 'D7D7D7' };

    return latest_event.open_event !== null
      ? {
          value: `Open on ${returnTime(latest_event.modified)}`,
          color: 'BAF372',
        }
      : {
          value: `Sent on ${returnTime(latest_event.created)}`,
          color: 'D7D7D7',
        };
  };

  const getTemplates = async () => {
    setLoadingTemplates(true);
    updateLinkForm({ template: 0 });
    setTemplateOptions([]);
    cancelRequestTemplate();
    try {
      const resp = await fetchTemplatesFilter(
        linkForm.audience,
        linkForm.affinity,
        linkForm.site,
      );

      const tempsFilter = resp.filter(template => template.status === 'active');

      setTemplateOptions(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
    } catch (err) {
      setTemplateOptions([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (linkForm.affinity !== 0) getTemplates();
  }, [linkForm.affinity]);

  const getAffinities = async () => {
    setLoadingAff(true);
    updateLinkForm({ affinity: 0, template: 0 });
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAff();
    cancelRequestTemplate();

    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        linkForm.audience,
        linkForm.site,
        abortControllerAffinity.current.signal,
      );
      setAffinityOptions(resp);
    } catch (err) {
      setAffinityOptions([]);
    } finally {
      setLoadingAff(false);
    }
  };

  useEffect(() => {
    if (linkForm.audience !== 0) getAffinities();
  }, [linkForm.audience]);

  const getAudiences = async () => {
    setLoadingAud(true);
    setAudienceOptions([]);
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAud();
    cancelRequestAff();
    if (linkForm.site === 0) {
      setLoadingAud(false);
      return;
    }

    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        linkForm.site,
        abortControllerAudience.current.signal,
      );
      setAudienceOptions(resp);
    } catch (err) {
      setAudienceOptions([]);
    } finally {
      setLoadingAud(false);
    }
  };

  useEffect(() => {
    getAudiences();

    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
    };
  }, [linkForm.site]);

  const getMessageForTemplate = async (name: string) => {
    setLoadingTemplateNote(true);
    try {
      const resp = await getTemplateTranslate(
        linkForm.template,
        encodeURIComponent(linkForm.url),
        linkForm.id ?? 0,
        encodeURIComponent(name),
      );
      if (resp.body === '')
        handleFeedbackMessage('Template does not contain a message', 'error');
      return { body: resp.body, signature: resp.signature_html };
    } catch (err) {
      handleFeedbackMessage('Error while generating template', 'error');
      return { body: '', signature: '' };
    } finally {
      setLoadingTemplateNote(false);
    }
  };

  const stripHtmlTags = (html: string) => {
    return html
      .replace(/<p>/g, '\n') // Replace paragraph tags with double line breaks
      .replace(/<\/p>/g, '')
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .trim();
  };

  const updateNotes = async (arrId: number) => {
    const message = await getMessageForTemplate(linkForm.contacts[arrId].name);

    setTemplateNote(
      `${stripHtmlTags(`${message.body}\n\n`)}${stripHtmlTags(
        message.signature,
      )}`,
    );

    setContactTemplateId(arrId);

    openPreviewTemplateModal();
  };

  const addNote = async () => {
    setLoadingSaveNoteTemplate(true);

    const newNoteObject = {
      body: templateNote,
      link: linkForm.id ?? 0,
      user: UserContext.id,
    };

    try {
      await addNoteLink(newNoteObject);
      handleFeedbackMessage('Note created successfully', 'success');
      getNotes();
    } catch (err) {
      handleFeedbackMessage('Error creating new note', 'error');
    } finally {
      setLoadingSaveNoteTemplate(false);
    }
  };

  const saveTemplateNote = async () => {
    // if (linkForm.id && linkForm.id !== 0) await addNote();
    updateContact(contactTemplateId, {
      form_text: templateNote,
    });

    validateField(contactTemplateId, 'form_text');
    if (linkForm.status === 'to_do') {
      updateLinkForm({
        status: 'pending',
      });
    }

    closePreviewTemplateModal();
  };

  const addTextNote = (message: string) => {
    navigator.clipboard.writeText(stripHtmlTags(message));
    updateLinkForm({
      text_note: `Form sent:\n${message}`,
    });
  };

  return (
    <>
      {linkForm.contacts.length === linkFormErrors.contacts.length &&
        contactsValues.length === linkForm.contacts.length && (
          <>
            {/* {linkForm.contacts.find(item => item.is_form) && (
              <BoxAddSettings
                title="Targeting"
                pl={0}
                px={0}
                pr={0}
                mb={3}
                pt={1.5}
              >
                <SelectFormInput
                  loading={loadingAud}
                  required={false}
                  clearIcon
                  error={false}
                  onBlur={value => {}}
                  options={audienceOptions}
                  disabled={
                    loadingAud ||
                    audienceOptions.length === 0 ||
                    available.disabled
                  }
                  placeholder="Audience"
                  value={
                    audienceOptions.find(
                      item => item.id === linkForm.audience,
                    ) ?? {
                      id: 0,
                      name: '',
                    }
                  }
                  onChange={(value: BasicArray | null) =>
                    updateLinkForm({
                      audience: value ? (value.id as number) : 0,
                    })
                  }
                />

                <Box mt={2}>
                  <SelectFormInput
                    loading={loadingAff}
                    required={false}
                    clearIcon
                    error={false}
                    onBlur={value => {}}
                    options={affinityOptions}
                    disabled={
                      loadingAff ||
                      affinityOptions.length === 0 ||
                      available.disabled
                    }
                    placeholder="Affinity"
                    value={
                      affinityOptions.find(
                        item => item.id === linkForm.affinity,
                      ) ?? {
                        id: 0,
                        name: '',
                      }
                    }
                    onChange={(value: BasicArray | null) =>
                      updateLinkForm({
                        affinity: value ? (value.id as number) : 0,
                      })
                    }
                  />
                </Box>

                <Box mt={2}>
                  <SelectFormInput
                    loading={loadingTemplates}
                    required={false}
                    clearIcon
                    error={false}
                    onBlur={value => {}}
                    options={templateOptions}
                    disabled={
                      templateOptions.length === 0 ||
                      loadingTemplates ||
                      available.disabled
                    }
                    placeholder="Template"
                    value={
                      templateOptions.find(
                        item => item.id === linkForm.template,
                      ) ?? {
                        id: 0,
                        name: '',
                      }
                    }
                    onChange={(value: BasicArray | null) =>
                      updateLinkForm({
                        template: value ? (value.id as number) : 0,
                      })
                    }
                  />
                </Box>
              </BoxAddSettings>
            )} */}
            {linkForm.contacts.map((contact, index) => {
              const contactValue = contactsValues[index];
              const validationStatus = getOriginalStatus(contact.id ?? 0);
              const originalContact =
                (editLinkForm &&
                  editLinkForm.contacts.find(item => item.id === contact.id)) ??
                false;

              const valueValidate =
                formSidebar.type === 'add'
                  ? false
                  : contact.validOriginalContact ?? false;

              const findColorButton = () => {
                if (IS_USER_ADMIN.includes(UserContext.profile.role)) {
                  if (
                    contactValue.loading ||
                    linkForm.contacts.length <= 1 ||
                    formSidebar.type === 'read'
                  )
                    return 'rgba(0, 0, 0, 0.5)';
                }
                if (
                  available.disabled ||
                  linkForm.contacts.length <= 1 ||
                  validationStatus === 'inactive' ||
                  validationStatus === 'active' ||
                  contactValue.loading ||
                  doNotDeleteContact === contact.email ||
                  formSidebar.type === 'read'
                )
                  return 'rgba(0, 0, 0, 0.5)';

                return '#EB9393';
              };

              return (
                <Box
                  key={index}
                  sx={{
                    boxSizing: 'border-box',
                    border: '1px solid #D9D9D9',
                    borderRadius: '6px',
                    marginBottom: 1.5,
                  }}
                >
                  {/* Contact Header */}
                  <FlexBoxBetween padding="5px 10px 2px 10px">
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color="rgba(0, 0, 0, 0.8)"
                    >
                      Contact {index + 1}
                    </Typography>
                    <IconButton
                      size="small"
                      disabled={
                        IS_USER_ADMIN.includes(UserContext.profile.role)
                          ? contactValue.loading ||
                            linkForm.contacts.length <= 1 ||
                            formSidebar.type === 'read'
                          : available.disabled ||
                            linkForm.contacts.length <= 1 ||
                            validationStatus === 'inactive' ||
                            validationStatus === 'active' ||
                            contactValue.loading ||
                            doNotDeleteContact === contact.email ||
                            formSidebar.type === 'read'
                      }
                      onClick={() => deleteContact(index, isAdminManager)}
                    >
                      <DeleteIcon
                        fontSize="small"
                        sx={{
                          color: findColorButton(),
                        }}
                      />
                    </IconButton>
                  </FlexBoxBetween>
                  <Divider />

                  {/* Contact Body  */}
                  <Box padding="5px 10px 2px 10px">
                    {/* Contact section of isform */}

                    {validationStatus === 'inactive' && !contact.is_form && (
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        my={1}
                        textAlign="center"
                      >
                        Contact is Inactive
                      </Typography>
                    )}
                    <FlexBoxBetween>
                      <FormControl>
                        <RadioGroup
                          value={contact.is_form}
                          onChange={e =>
                            toggleIsForm(index, e.target.value === 'true')
                          }
                          row
                        >
                          <FormWithLabel
                            value
                            control={
                              <CustomRadio
                                size="small"
                                disabled={
                                  IS_USER_ADMIN.includes(
                                    UserContext.profile.role,
                                  )
                                    ? false
                                    : available.disabled ||
                                      validationStatus === 'inactive' ||
                                      validationStatus === 'active' ||
                                      contactValue.loading ||
                                      formSidebar.type === 'read' ||
                                      form_contacts_allowed === false ||
                                      valueValidate
                                }
                              />
                            }
                            label="Form"
                          />
                          <FormWithLabel
                            value={false}
                            control={
                              <CustomRadio
                                size="small"
                                disabled={
                                  IS_USER_ADMIN.includes(
                                    UserContext.profile.role,
                                  )
                                    ? false
                                    : available.disabled ||
                                      validationStatus === 'inactive' ||
                                      validationStatus === 'active' ||
                                      contactValue.loading ||
                                      formSidebar.type === 'read' ||
                                      valueValidate
                                }
                              />
                            }
                            label="Email"
                          />
                        </RadioGroup>
                      </FormControl>

                      <FormControlLabel
                        label="Default"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: 12,
                            color: '#000000',
                          },
                        }}
                        control={
                          <DefaultCheckbox
                            size="small"
                            disabled={
                              available.disabled ||
                              contactValue.loading ||
                              formSidebar.type === 'read' ||
                              statusBlock.includes(contact.status ?? '')
                            }
                            checked={contact.is_default ?? false}
                            onChange={e =>
                              updateIsDefault(e.target.checked, index)
                            }
                          />
                        }
                      />
                    </FlexBoxBetween>

                    {/* When Contact is NOT Form */}
                    {!contact.is_form && (
                      <Box mt={1} mb={2}>
                        <Box mb={2}>
                          <TextFormField
                            nameInput={`name-${index}`}
                            disabled={
                              isAdmin
                                ? false || formSidebar.type === 'read'
                                : available.disabled ||
                                  validationStatus === 'inactive' ||
                                  formSidebar.type === 'read' ||
                                  valueValidate ||
                                  (originalContact &&
                                    contactValue.validEmail === false)
                            }
                            label="Name"
                            error={!!linkFormErrors.contacts[index].status}
                            onBlur={() => validateField(index, 'name')}
                            value={contact.name}
                            onChange={(value: string) =>
                              updateContact(index, { name: value })
                            }
                            errorText={
                              linkFormErrors.contacts[index].status ?? ''
                            }
                            placeholder="Name"
                          />
                        </Box>

                        <Box>
                          <InputLabel
                            htmlFor={`email-${index}`}
                            sx={{
                              fontSize: 12,
                              fontWeight: '700',
                              color: 'rgba(0, 0, 0, 0.8)',
                              marginBottom: 1,
                            }}
                          >
                            Email <span className="required-fields_dot">*</span>
                          </InputLabel>
                          <TextInputField
                            value={contact.email}
                            name={`email-${index}`}
                            disabled={
                              available.disabled ||
                              linkForm.site === 0 ||
                              validationStatus === 'inactive' ||
                              // validationStatus === 'active' ||
                              contactValue.loading ||
                              formSidebar.type === 'read' ||
                              valueValidate ||
                              (originalContact &&
                                contactValue.validEmail === false &&
                                originalContact.email !== '')
                              // disableButtonAdd ||
                            }
                            helperText={contactValue.message}
                            error={contactValue.validEmail === false}
                            size="small"
                            onBlur={e => validateEmail(index, isAdminManager)}
                            onChange={e =>
                              updateContact(index, {
                                email: e.target.value.trim(),
                              })
                            }
                            fullWidth
                            placeholder="Email"
                            id={`email-${index}`}
                            InputProps={
                              contactValue.loading
                                ? {
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <CircularProgress
                                          sx={{ color: '#ABABAB' }}
                                          size={14}
                                        />
                                      </InputAdornment>
                                    ),
                                  }
                                : {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {!contact.is_form && (
                                          <>
                                            {contactValue.validEmail ===
                                              true && (
                                              <CheckCircleIcon fontSize="inherit" />
                                            )}
                                            {contactValue.validEmail ===
                                              'unknown' && (
                                              <HelpIcon fontSize="inherit" />
                                            )}
                                            {contactValue.validEmail ===
                                              false && (
                                              <CancelIcon fontSize="inherit" />
                                            )}
                                          </>
                                        )}
                                      </InputAdornment>
                                    ),
                                  }
                            }
                          />
                        </Box>

                        <Box mb={2}>
                          <FlexBox>
                            <Box sx={{ flexGrow: 1 }}>
                              <InputLabel
                                htmlFor={`status-contact-${index}`}
                                sx={{
                                  fontSize: 12,
                                  fontWeight: '700',
                                  color: 'rgba(0, 0, 0, 0.8)',
                                  marginTop:
                                    contactValue.message !== '' ? 1 : 2,
                                  marginBottom: 1,
                                }}
                              >
                                Contact Status{' '}
                                <span className="required-fields_dot">*</span>
                              </InputLabel>
                              <Select
                                value={contact.status ?? ''}
                                name={`status-contact-${index}`}
                                id={`status-contact-${index}`}
                                disabled={
                                  !isAdmin ||
                                  formSidebar.type === 'read' ||
                                  valueValidate
                                }
                                displayEmpty
                                fullWidth
                                onBlur={() => validateField(index, 'status')}
                                onChange={e => {
                                  if (e.target.value === 'global_closed') {
                                    updateLinkForm({ status: 'global_closed' });
                                    handleFeedbackMessage(
                                      'Global closed status enforced due to contact status',
                                      'info',
                                    );
                                  }
                                  updateContact(index, {
                                    status: e.target.value,
                                  });
                                }}
                                sx={{ fontSize: 12 }}
                                IconComponent={KeyboardArrowDownIcon}
                                input={<SelectInput />}
                              >
                                <MenuItem value="" disabled>
                                  None
                                </MenuItem>
                                {contactStatus.map(item => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            {closeTypes.includes(contact.status ?? '') && (
                              <HtmlTooltip
                                title={tooltipInformation(contact.status)}
                              >
                                <IconButton sx={{ fontSize: 14 }}>
                                  <HelpRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                          </FlexBox>
                        </Box>

                        {contact.close_reason &&
                          contact.close_reason !== '' &&
                          contact.status !== 'open' && (
                            <Box mb={2}>
                              <FlexBox>
                                <Box flexGrow={1}>
                                  <TextFormField
                                    nameInput={`close-reason-${index}`}
                                    disabled
                                    label="Close Reason"
                                    error={false}
                                    value={contact.close_reason}
                                    onChange={(value: string) =>
                                      updateContact(index, { name: value })
                                    }
                                    errorText=""
                                    placeholder="Name"
                                  />
                                </Box>
                                {contact.close_reason.length > 60 && (
                                  <HtmlTooltip title={contact.close_reason}>
                                    <IconButton sx={{ fontSize: 14 }}>
                                      <HelpRoundedIcon fontSize="inherit" />
                                    </IconButton>
                                  </HtmlTooltip>
                                )}
                              </FlexBox>
                            </Box>
                          )}

                        {contact.latest_event !== undefined && isAdmin && (
                          <Box>
                            <InputLabel
                              htmlFor={`latest_event-${index}`}
                              sx={{
                                fontSize: 12,
                                fontWeight: '700',
                                color: 'rgba(0, 0, 0, 0.8)',
                                marginBottom: 1,
                              }}
                            >
                              Outreach Status
                            </InputLabel>

                            <TextInputPendingOutreach
                              value={
                                getOutReachValue(contact.latest_event).value
                              }
                              name={`latest_event-${index}`}
                              disabled
                              error={false}
                              sx={{
                                '& .MuiInputBase-inputMultiline': {
                                  resize: 'vertical',
                                },

                                '& .Mui-disabled': {
                                  cursor: 'not-allowed',
                                  background: '#E8E8E8',
                                  border: `1.8px solid #${
                                    getOutReachValue(contact.latest_event).color
                                  }`,
                                  borderRadius: '5px',
                                  color: '#373737',
                                  fontWeight: 600,
                                },
                              }}
                              size="small"
                              onChange={e => {}}
                              fullWidth
                              type="text"
                              placeholder="Outreach Status"
                              id={`latest_event-${index}`}
                            />
                          </Box>
                        )}

                        {contact.last_outreach &&
                          contact.last_outreach !== '' && (
                            <Box marginTop={2}>
                              {!isLowerThan90Days(contact.last_outreach) && (
                                <TextFormField
                                  nameInput="last-outreach"
                                  disabled
                                  label="Last Outreach"
                                  error={false}
                                  value={dayjs(contact.last_outreach).format(
                                    'MM/DD/YYYY',
                                  )}
                                  onChange={() => {}}
                                  errorText=""
                                  placeholder="Last Outreach"
                                />
                              )}
                              {isLowerThan90Days(contact.last_outreach) && (
                                <>
                                  <InputLabel
                                    htmlFor="last-outreach"
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: '700',
                                      color: 'rgba(0, 0, 0, 0.8)',
                                      marginBottom: 1,
                                    }}
                                  >
                                    Last Outreach
                                  </InputLabel>
                                  <TextInputFieldError
                                    value={dayjs(contact.last_outreach).format(
                                      'MM/DD/YYYY',
                                    )}
                                    name="last-outreach"
                                    disabled
                                    error
                                    size="small"
                                    helperText="Contact was recently emailed"
                                    fullWidth
                                    type="text"
                                    id="last-outreach"
                                  />
                                </>
                              )}
                            </Box>
                          )}
                      </Box>
                    )}

                    {/* When contact IS form */}
                    {contact.is_form && (
                      <>
                        <Box mb={2}>
                          <TextFormField
                            nameInput={`name-${index}`}
                            disabled={
                              isAdmin
                                ? formSidebar.type === 'read'
                                : available.disabled ||
                                  formSidebar.type === 'read'
                            }
                            label="Name"
                            error={!!linkFormErrors.contacts[index].name}
                            required
                            value={contact.name}
                            onBlur={() => validateField(index, 'name')}
                            onChange={(value: string) =>
                              updateContact(index, { name: value })
                            }
                            errorText={
                              linkFormErrors.contacts[index].name ?? ''
                            }
                            placeholder="Name"
                          />
                        </Box>

                        <Box mb={2}>
                          <TextFormField
                            nameInput={`form-url-${index}`}
                            disabled={
                              available.disabled || formSidebar.type === 'read'
                            }
                            label="Form URL"
                            required
                            error={!!linkFormErrors.contacts[index]?.form_url}
                            value={contact.form_url ?? ''}
                            onBlur={value => validateField(index, 'form_url')}
                            onChange={(value: string) =>
                              updateContact(index, { form_url: value })
                            }
                            errorText={
                              linkFormErrors.contacts[index].form_url ?? ''
                            }
                            placeholder="Form URL"
                          />
                        </Box>

                        {contact.form_text && contact.form_text !== '' && (
                          <Box mb={2}>
                            <InputLabel
                              htmlFor="notes"
                              sx={{
                                fontSize: 12,
                                fontWeight: '700',
                                color: 'rgba(0, 0, 0, 0.8)',
                                marginBottom: 1,
                              }}
                            >
                              Notes
                              <span className="required-fields_dot">*</span>
                            </InputLabel>
                            <CustomTextAreaField
                              placeholder="Form Text"
                              minRows={4}
                              multiline
                              maxRows={Infinity}
                              disabled
                              required
                              helperText={
                                linkFormErrors.contacts[index].form_text ?? ''
                              }
                              error={!!linkFormErrors.contacts[index].form_text}
                              // onBlur={() => validateField(index, 'form_text')}
                              value={stripHtmlTags(contact.form_text)}
                              onChange={e => {
                                updateContact(index, {
                                  form_text: e.target.value,
                                });
                                validateField(index, 'form_text');
                              }}
                            />

                            <Box justifyContent="end" display="flex">
                              {/* <MainButton
                              variant="contained"
                              sx={{
                                marginTop: 1,
                                backgroundColor: '#BAF372',
                                color: 'black',
                                padding: '6px 5px',
                                fontSize: 12,
                                border: 1,
                                '&:hover': {
                                  backgroundColor: '#BAF372',
                                },
                              }}
                              loading={loadingTemplateNote}
                              size="small"
                              disabled={linkForm.template === 0}
                              onClick={() => updateNotes(index)}
                            >
                              Generate Template
                            </MainButton>{' '} */}
                              <MainButton
                                variant="contained"
                                sx={{
                                  marginTop: 1,
                                  marginX: 2,
                                  backgroundColor: '#84D0F0',
                                  color: 'black',
                                  padding: '6px 5px',
                                  fontSize: 12,
                                  border: 1,
                                  '&:hover': {
                                    backgroundColor: '#84D0F0',
                                  },
                                }}
                                size="small"
                                disabled={contact.form_text === ''}
                                onClick={() =>
                                  addTextNote(contact.form_text ?? '')
                                }
                              >
                                Copy To Clipboard
                              </MainButton>{' '}
                            </Box>
                          </Box>
                        )}

                        <Box mb={2}>
                          <FlexBox>
                            <Box sx={{ flexGrow: 1 }}>
                              <InputLabel
                                htmlFor={`status-contact-${index}`}
                                sx={{
                                  fontSize: 12,
                                  fontWeight: '700',
                                  color: 'rgba(0, 0, 0, 0.8)',
                                  marginBottom: 1,
                                }}
                              >
                                Contact Status{' '}
                                <span className="required-fields_dot">*</span>
                              </InputLabel>
                              <Select
                                value={contact.status ?? ''}
                                name={`status-contact-${index}`}
                                id={`status-contact-${index}`}
                                disabled={
                                  !isAdmin || formSidebar.type === 'read'
                                }
                                displayEmpty
                                fullWidth
                                onChange={e =>
                                  updateContact(index, {
                                    status: e.target.value,
                                  })
                                }
                                sx={{ fontSize: 13 }}
                                IconComponent={KeyboardArrowDownIcon}
                                input={<SelectInput />}
                              >
                                <MenuItem value="" disabled>
                                  None
                                </MenuItem>
                                {contactStatus.map(item => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            {closeTypes.includes(contact.status ?? '') && (
                              <HtmlTooltip
                                title={tooltipInformation(contact.status)}
                              >
                                <IconButton sx={{ fontSize: 16 }}>
                                  <HelpRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                          </FlexBox>
                        </Box>

                        {contact.close_reason &&
                          contact.close_reason !== '' &&
                          contact.status !== 'open' && (
                            <Box mb={2}>
                              <FlexBox>
                                <Box flexGrow={1}>
                                  <TextFormField
                                    nameInput={`close-reason-${index}`}
                                    disabled
                                    label="Close Reason"
                                    error={false}
                                    value={contact.close_reason}
                                    onChange={(value: string) =>
                                      updateContact(index, { name: value })
                                    }
                                    errorText=""
                                    placeholder="Name"
                                  />
                                </Box>
                                {contact.close_reason.length > 60 && (
                                  <HtmlTooltip title={contact.close_reason}>
                                    <IconButton sx={{ fontSize: 14 }}>
                                      <HelpRoundedIcon fontSize="inherit" />
                                    </IconButton>
                                  </HtmlTooltip>
                                )}
                              </FlexBox>
                            </Box>
                          )}
                      </>
                    )}
                  </Box>
                </Box>
              );
            })}
            <IconButton
              disabled={disableAdd()}
              onClick={() =>
                allowOpenNewModalContact && doNotDeleteContact === ''
                  ? openNewContactModal()
                  : addContact()
              }
              sx={{
                borderRadius: 3,
                justifyContent: 'center',
                border: 1,
                borderColor: '#6EBD0C',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
                '&:disabled': {
                  backgroundColor: '#E9E9E9',
                  borderColor: 'white',
                },
              }}
            >
              <AddIcon
                sx={{
                  color: disableAdd() ? 'rgba(0, 0, 0, 0.8)' : '#6EBD0C',
                }}
              />
              <Typography
                color="rgba(0, 0, 0, 0.8)"
                fontWeight={600}
                fontSize={14}
                marginLeft={0.7}
              >
                Add Contact
              </Typography>
            </IconButton>
            <PreviewTemplate
              open={previewTemplateModal}
              closeModal={closePreviewTemplateModal}
              body={templateNote}
              loadingSave={loadingSaveNoteTemplate}
              updateBody={updateTemplateNote}
              saveNote={saveTemplateNote}
            />
          </>
        )}
    </>
  );
};

export default ContactsEdit;
