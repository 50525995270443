/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BoxAddSettings from '../../../../components/Forms/BoxAddSettings';
import { FormInputsProps } from '../../../../constants/form';
import {
  DefaultCheckbox,
  SelectInput,
  TextInputField,
} from '../../../../components/inputs';
import {
  AutoOwnerSelectFormInput,
  NewAutoSelectFormInput,
  OwnersSelectLinkFormField,
  SelectFormInput,
  StatusSelectLinkFormField,
} from '../../../../components/Forms/FormInputs';
import { sendBackObject } from '../../../../helpers/helpersfunctions';
import Context from '../../../../context/UserContext';
import { UserContextType } from '../../../../models/User';
import { IS_USER_ADMIN, ROLES_LINKS } from '../../../../constants/user';

import {
  OwnersFieldInterface,
  OwnersFieldInterfaceEdit,
  SitesFieldInterface,
  StatusFieldInterface,
} from '../FormModel';
import useLinkForm from '../../../../stores/links/useLinkForm';
import { ContactsFormLink, ObjectLinks } from '../../../../models/Forms';
import { isURL } from '../../../../helpers/validateInputs';
import LoadingContext from '../../../../context/LoadingContext';
import {
  GlobalContextType,
  LoadingContextType,
} from '../../../../models/Context';
import {
  fetchAllContacts,
  fetchLinkHistory,
  fetchLinkNotes,
  sendErrorSubmit,
  updateLink,
  validateLink,
} from '../../../../helpers/links';
import useSidebar from '../../../../stores/links/useSidebar';
import { BasicArray, SeverityType } from '../../../../models/General';
import { LinkFormDefault, ObjEditFormLink } from '../../../../models/LinkForm';
import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import { FormButtons, MainButton } from '../../../../components/buttons';
import TabParent from '../../../../components/Tab/TabParent';
import { HistoryReq, NotesReq } from '../../../../models/Notes';
import LinkNotes from './LinkNotes';
import LinkHistory from './LinkHistory';
import StatusModal from '../../../../components/Forms/StatusModal';
import { LinkStatuses } from '../../../../models/Status';
import ContactsEdit from './ContactsEdit';
import AddNewContactModal from '../../../../components/Forms/Links/AddNewContactModal';
import StatusClosed from '../../../../components/Forms/Links/StatusClosed/StatusClosed';
import DateInput from '../../../../components/Forms/DateInput';
import LinkEmailSave from '../LinkEmailSave';
import AddWarningLink from '../../AddWarningLink';
import useLinksList from '../../../../stores/links/useLinksList';
import MainDrawer from '../../../../components/Drawers/MainDrawer';
import EditClaim from '../../../../components/Forms/Links/EditClaim';
import LinkFormClaim from './LinkFormClaim';
import GeneralContext from '../../../../context/GeneralActions';
import { ContactsValuesType } from '../../../../helpers/contacts';
import useUserProfile from '../../../../stores/user/useUserProfile';
import FormContactModal from '../FormContactModal';

interface MainFormProps {
  ownersField: OwnersFieldInterfaceEdit;
  statusField: StatusFieldInterface;
  sitesField: SitesFieldInterface;
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  updateIsOpenModalEmailOpen: (value: boolean) => void;
  isOpenModalEmailOpen: boolean;
  tabView: number;
  notesObj: {
    notes: NotesReq[];
    getNotes: () => Promise<void>;
    updateNotes: (notesUpdated: NotesReq[]) => void;
    loadingNotes: boolean;
  };
  historyObj: {
    history: HistoryReq[];
    updateHistory: (historyUpdated: HistoryReq[]) => void;
    getHistory: () => Promise<void>;
    loadingHistory: boolean;
  };
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  getValidationOfLinkClaim: () => Promise<void>;
}

const allowInReviewOption = ['pending', 'open', 'approved'];

const MainForm: React.FC<MainFormProps> = ({
  ownersField,
  statusField,
  sitesField,
  handleFeedbackMessage,
  updateIsOpenModalEmailOpen,
  isOpenModalEmailOpen,
  tabView,
  historyObj,
  notesObj,
  openDrawer,
  setOpenDrawer,
  getValidationOfLinkClaim,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativetime);
  const { notes, getNotes, loadingNotes, updateNotes } = notesObj;
  const { history, getHistory, loadingHistory, updateHistory } = historyObj;

  const { linksList, updateLinksList } = useLinksList();
  const { UserContext } = useContext(Context) as UserContextType;

  const [openNewContact, setOpenNewContact] = useState(false);
  const openNewContactModal = () => setOpenNewContact(true);
  const closeNewContact = () => setOpenNewContact(false);

  const [loading, setLoading] = useState(false);

  const [openWarning, setOpenWarning] = useState(false);
  const closeWarning = (value: boolean) => setOpenWarning(value);

  const [allowOpenNewModalContact, setAllowOpenNewModalContact] =
    useState(false);
  const [doNotDeleteContact, setDoNotDeleteContact] = useState('');
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [sitesWithAll, setSitesWithAll] = useState<BasicArray[]>([]);
  const {
    linkForm,
    updateLinkForm,
    editLinkForm,
    linkFormErrors,
    validUrl,
    validateLinkFormField,
    updateValidUrl,
    setLinkFormErrors,
    contactsValues,
    replaceObjEditForm,
    addContactValues,
    pendingListObject,
    tabForm,
    loadingForm,
    updateLoadingForm,
    updateClaimId,
  } = useLinkForm();
  const { formSidebar, updateSidebar, updateAvailable, available } =
    useSidebar();
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const { getUserEmailAlias } = useUserProfile();
  const [loadingUrl, setLoadingUrl] = useState(false);

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [linkStatus, setLinkStatus] = useState<
    { id: number; name: string; value: string }[]
  >([]);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const [closeValid, setCloseValid] = useState(false);

  const [openModalEmail, setOpenModalEmail] = useState(false);
  const handleOpenModalEmail = (value: boolean) => {
    setOpenModalEmail(value);
    updateIsOpenModalEmailOpen(value);
  };

  const [openFormContact, setOpenFormContact] = useState(false);
  const handleOpenFormContact = (value: boolean) => {
    setOpenFormContact(value);
  };
  const [contactForm, setContactForm] = useState({
    id: 0,
    name: '',
    contactName: '',
  });
  const [siteForm, setSiteForm] = useState(0);

  const [emailSave, setEmailSave] = useState<{
    site: number;
    contacts: { name: string; id: number; email: string; default: boolean }[];
    url: string;
    linkId: number;
    userEmail: string | null;
  }>({ site: 0, contacts: [], url: '', linkId: 0, userEmail: null });

  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([
    { id: 0, label: 'Details', disabled: true },
    { id: 1, label: 'Contacts', disabled: true },
    { id: 2, label: 'Notes', disabled: true },
    { id: 3, label: 'History', disabled: true },
  ]);

  useEffect(() => {
    if ((linkForm.id && linkForm.id !== 0) || formSidebar.type === 'edit') {
      setTabs([
        { id: 0, label: 'Details', disabled: false },
        { id: 1, label: 'Contacts', disabled: false },
        { id: 2, label: 'Notes', disabled: false },
        { id: 3, label: 'History', disabled: false },
      ]);
    } else {
      setTabs([
        { id: 0, label: 'Details', disabled: true },
        { id: 1, label: 'Contacts', disabled: true },
        { id: 2, label: 'Notes', disabled: true },
        { id: 3, label: 'History', disabled: true },
      ]);
    }
  }, [linkForm, formSidebar]);

  useEffect(() => {
    if (tabForm !== 0) setTabValue(tabForm);
  }, [tabForm]);

  const validateField = <K extends keyof ObjectLinks>(
    value: ObjectLinks[K],
  ) => {
    setLinkFormErrors({
      site: value ? undefined : 'Site Selection is required',
    });
  };

  const handleInputChange = <K extends keyof ObjectLinks>(
    name: K,
    value: ObjectLinks[K],
  ) => {
    if (name === 'site')
      updateLinkForm(
        { [name]: value, audience: 0, affinity: 0, template: 0 },
        true,
      );
    else updateLinkForm({ [name]: value });

    validateLinkFormField(name);
  };
  const validateUrl = async (value: string, link_id: number) => {
    let errorUrl: string | undefined;
    if (value === '') {
      errorUrl = 'URL is required';
      updateValidUrl(false);
      setLinkFormErrors({ url: errorUrl });
      return;
    }
    try {
      setLoadingUrl(true);
      const resp = await validateLink(encodeURIComponent(value), link_id);
      if (resp.valid) {
        if (IS_USER_ADMIN.includes(UserContext.profile.role)) {
          errorUrl = resp.message;
        } else errorUrl = undefined;
        updateValidUrl(true);
      } else {
        errorUrl = resp.message;
        updateValidUrl(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUrl(false);
    }
  };

  const isBlock = () => {
    if (IS_USER_ADMIN.includes(UserContext.profile.role)) return true;

    if (
      linkForm.owner === UserContext.id &&
      (linkForm.status === 'pending' ||
        linkForm.status === 'open' ||
        linkForm.status === 'in_review' ||
        linkForm.status === 'approved') &&
      !editLinkForm.is_older_than_ninety_days
    )
      return false;

    if (linkForm.owner === UserContext.id && linkForm.status === 'to_do')
      return true;

    const dateNow = dayjs();
    const modDate = dayjs(editLinkForm.modified_date);
    const is30DaysAfter = dateNow.isAfter(modDate.add(30, 'day'));
    return is30DaysAfter;
  };

  const checkStatusReadOnly = () => {
    if (IS_USER_ADMIN.includes(UserContext.profile.role)) return false;

    if (
      linkForm.owner !== UserContext.id &&
      !editLinkForm.is_older_than_ninety_days
    )
      return true;

    if (linkForm.status === 'to_do') return false;

    const dateNow = dayjs();
    const modDate = dayjs(editLinkForm.modified_date);
    const is30DaysAfter = dateNow.isAfter(modDate.add(30, 'day'));
    if (is30DaysAfter) return available.disabled;
    return false;
  };

  const statusOptionsInput = () => {
    const copyStatus = [...linkStatus];
    if (copyStatus.length === 0) return linkStatus;

    if (
      (editLinkForm.status === 'pending' ||
        editLinkForm.status === 'open' ||
        editLinkForm.status === 'approved' ||
        editLinkForm.status === 'in_review') &&
      !editLinkForm.is_older_than_ninety_days &&
      !IS_USER_ADMIN.includes(UserContext.profile.role)
    ) {
      return copyStatus.filter(item => item.value !== 'to_do');
    }

    return linkStatus;
  };

  const validationOfGlobalClosed = () => {
    if (IS_USER_ADMIN.includes(UserContext.profile.role)) return false;

    if (linkForm.contacts.find(item => item.status === 'global_closed'))
      return true;

    return false;
  };

  const inputsLinkSection: FormInputsProps[] = [
    {
      id: 0,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <InputLabel
            htmlFor="url"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
            }}
          >
            URL <span className="required-fields_dot">*</span>
          </InputLabel>
          <TextInputField
            onBlur={e => {
              let copyURL = e.target.value;
              if (e.target.value === '') return;

              if (!/(http(s?)):\/\//i.test(copyURL)) {
                copyURL = `https://${copyURL}`;
                handleInputChange('url', copyURL);
              }

              if (isURL(copyURL)) validateUrl(copyURL, linkForm.id ?? 0);
            }}
            value={linkForm.url}
            name="url"
            disabled={
              isLoadingContext.loading ||
              loadingUrl ||
              loadingForm ||
              available.disabled ||
              formSidebar.type === 'read'
            }
            error={!!linkFormErrors.url && !validUrl}
            onChange={e => handleInputChange('url', e.target.value)}
            size="small"
            placeholder="URL"
            id="url"
            helperText={linkFormErrors.url}
            fullWidth
            InputProps={
              loadingUrl
                ? {
                    endAdornment: (
                      <InputAdornment position="start">
                        <CircularProgress sx={{ color: '#ABABAB' }} size={16} />
                      </InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          {validUrl === true && (
                            <CheckCircleIcon fontSize="inherit" />
                          )}
                          {validUrl === false && (
                            <CancelIcon fontSize="inherit" />
                          )}
                        </>
                      </InputAdornment>
                    ),
                  }
            }
          />
        </>
      ),
    },
    {
      id: 1,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            value={
              sitesField.sitesOptions.find(
                item => item.id === linkForm.site,
              ) ?? { id: 0, name: '' }
            }
            onBlur={(value: string) => {
              if (
                sitesField.sitesOptions.find(
                  item => item.name.toLowerCase() === value.toLowerCase(),
                )
              )
                validateField(1);
              if (
                value === '' ||
                !sitesField.sitesOptions.find(
                  item => item.name.toLowerCase() === value.toLowerCase(),
                )
              )
                validateField(0);
            }}
            options={sitesWithAll}
            disabled={
              sitesField.loadingSites ||
              isLoadingContext.loading ||
              available.disabled ||
              formSidebar.type === 'read'
            }
            disabledId={undefined}
            placeholder="Sites"
            error={!!linkFormErrors.site}
            errorText={linkFormErrors.site}
            loading={sitesField.loadingSites}
            required
            onChange={(value: BasicArray | null) => {
              const idSite = value ? (value.id as number) : 0;

              handleInputChange('site', idSite);
            }}
          />
          {pendingListObject && pendingListObject.site.id !== linkForm.site && (
            <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
              Previous site: {pendingListObject?.site.name}
            </FormHelperText>
          )}
        </>
      ),
    },
    {
      id: 2,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <NewAutoSelectFormInput
            clearIcon
            value={
              statusOptionsInput().find(
                item => item.value === linkForm.status,
              ) ?? {
                id: 0,
                name: '',
                value: '',
              }
            }
            disabledStatus={
              IS_USER_ADMIN.includes(UserContext.profile.role)
                ? undefined
                : !allowInReviewOption.includes(editLinkForm.status)
                ? ['in_review', 'active']
                : ['active']
            }
            options={statusOptionsInput()}
            disabled={
              statusField.loadingStatusOptions ||
              isLoadingContext.loading ||
              checkStatusReadOnly() ||
              (editLinkForm.status === 'to_do' &&
                !IS_USER_ADMIN.includes(UserContext.profile.role)) ||
              disabledStatus ||
              formSidebar.type === 'read' ||
              validationOfGlobalClosed()
            }
            disabledId={undefined}
            placeholder="Status"
            error={!!linkFormErrors.status}
            errorText={linkFormErrors.site}
            loading={statusField.loadingStatusOptions}
            required
            onChange={(
              value: { id: number; name: string; value: string } | null,
            ) => {
              if (value) {
                if (value.value !== editLinkForm.status) {
                  setNewStatus(value.value);
                  setOpenStatusModal(true);
                } else handleInputChange('status', value.value);
              }
            }}
          />
          {editLinkForm && editLinkForm.status !== linkForm.status && (
            <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
              Previous Status:{' '}
              {
                statusOptionsInput().find(
                  item => item.value === editLinkForm.status,
                )?.name
              }
            </FormHelperText>
          )}
          {/* <StatusSelectLinkFormField
            value={linkForm.status}
            loading={statusField.loadingStatusOptions}
            error={!!linkFormErrors.status}
            onChangeFunction={(value: string) => {
              if (value !== editLinkForm.status) {
                setNewStatus(value);
                setOpenStatusModal(true);
              } else handleInputChange('status', value);
            }}
            required
            nameInput="status"
            options={linkStatus}
            label="Status"
            disabled={
              statusField.loadingStatusOptions ||
              isLoadingContext.loading ||
              available.disabled ||
              disabledStatus ||
              formSidebar.type === 'read'
            }
          /> */}
        </>
      ),
    },
    {
      id: 3,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <AutoOwnerSelectFormInput
            clearIcon
            value={
              ownersField.owners.find(item => item.id === linkForm.owner) ?? {
                id: 0,
                name: '',
              }
            }
            // onBlur={(value: string) => {
            //   if (
            //     ownersField.owners.find(
            //       item => item.name.toLowerCase() === value.toLowerCase(),
            //     )
            //   )
            //     validateField(1);
            //   if (
            //     value === '' ||
            //     !sitesField.sitesOptions.find(
            //       item => item.name.toLowerCase() === value.toLowerCase(),
            //     )
            //   )
            //     validateField(0);
            // }}
            options={ownersField.owners}
            disabled={
              ownersField.owners.length === 0 ||
              isLoadingContext.loading ||
              ownersField.loadingOwners ||
              available.disabled ||
              formSidebar.type === 'read'
            }
            disabledId={undefined}
            placeholder="Owner"
            error={!!linkFormErrors.owner}
            errorText=""
            loading={ownersField.loadingOwners}
            required
            onChange={(value: BasicArray | null) => {
              const idOwner = value ? (value.id as number) : 0;

              handleInputChange('owner', idOwner);
            }}
          />
          {pendingListObject &&
            pendingListObject.id !== 0 &&
            pendingListObject.owner.id !== linkForm.owner && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous Owner: {pendingListObject.owner.username}
              </FormHelperText>
            )}

          {editLinkForm &&
            editLinkForm.owner !== linkForm.owner &&
            !pendingListObject && (
              <FormHelperText error={false} sx={{ marginLeft: '14px' }}>
                Previous Owner:{' '}
                {
                  ownersField.owners.find(
                    item => item.id === editLinkForm.owner,
                  )?.name
                }
              </FormHelperText>
            )}
          {/* <OwnersSelectLinkFormField
            required
            loading={ownersField.loadingOwners}
            error={!!linkFormErrors.owner}
            errorText={
              pendingListObject &&
              pendingListObject.id !== 0 &&
              pendingListObject.owner.id !== linkForm.owner
                ? `Previous owner: ${pendingListObject.owner.username}`
                : editLinkForm && editLinkForm.owner !== linkForm.owner
                ? `Previous owner: ${
                    ownersField.owners.find(
                      item => item.id === editLinkForm.owner,
                    )?.username
                  }`
                : ''
            }
            value={linkForm.owner}
            onChangeFunction={(value: number) =>
              handleInputChange('owner', value)
            }
            options={ownersField.owners}
            nameInput="owner"
            label="Owner"
            disabled={
              ownersField.owners.length === 0 ||
              isLoadingContext.loading ||
              ownersField.loadingOwners ||
              available.disabled ||
              formSidebar.type === 'read'
            }
          /> */}
        </>
      ),
    },
  ];

  const cleanAll = () => {
    updateNotes([]);
    updateHistory([]);

    setOpenNewContact(false);
    setLoading(false);
    setAllowOpenNewModalContact(false);
    setDoNotDeleteContact('');
    setNewStatus('');
    setSitesWithAll(sitesField.sitesOptions);
  };

  const getContactsFromLink = async (id: number) => {
    try {
      const contactsLink = await fetchAllContacts([id]);
      return contactsLink[0].contacts_links.length === 0
        ? []
        : contactsLink[0].contacts_links;
    } catch (err) {
      return [];
    }
  };

  const saveLink = async (email?: boolean, copyLinkForm?: ObjectLinks) => {
    setLoading(true);
    const obj = copyLinkForm ? { ...copyLinkForm } : { ...linkForm };
    if (copyLinkForm) setSiteForm(0);
    obj.send_email = !!(
      email && linkForm.contacts.find(item => item.is_default && !item.is_form)
    );

    if (obj.contacts.find(item => item.status === 'global_closed')) {
      obj.status = 'global_closed';
    }

    try {
      let linkID: number = 0;

      if (obj.status === 'contact_not_found' && email) {
        obj.status = 'pending';
      }
      const resp = await updateLink(obj, obj.id ?? 0);
      linkID = resp.id;

      handleFeedbackMessage(`Link updated successfully`, 'success');
      setGlobalContext({
        ...GlobalContext,
        links: { ...GlobalContext.links, page: GlobalContext.links.page },
      });
      // const copyArr = [...linksList];
      // updateLinksList(
      //   copyArr.map(item =>
      //     item.id === response.id ? { ...item, ...response } : item,
      //   ),
      // );
      const contactsFromLink = await getContactsFromLink(resp.id);
      if (email) {
        if (linkForm.contacts.find(item => item.is_default && item.is_form)) {
          const findContact = contactsFromLink.find(
            item => item.is_default && item.is_form,
          );
          if (findContact) {
            setContactForm({
              id: findContact.id,
              name: findContact.form_url ?? findContact.name,
              contactName: findContact.name ?? '',
            });
            setSiteForm(obj.site);
          }
          handleOpenFormContact(true);
        } else {
          const listContacts = linkForm.contacts.filter(
            user => user.email !== '' && !user.is_form,
          );
          const findContacts = contactsFromLink.filter(contact =>
            listContacts.some(item => item.email === contact.email),
          );

          const listOfContacts = findContacts.map(item => {
            return {
              name: item.name,
              email: item.email,
              id: item.id ?? 0,
              default: item.is_default ?? false,
            };
          });

          const respAlias = await getUserEmailAlias(obj.site);
          setEmailSave({
            site: obj.site,
            contacts: listOfContacts,
            url: obj.url,
            linkId: obj.id ?? 0,
            userEmail: respAlias,
          });

          handleOpenModalEmail(true);
        }
      }
      updateSidebar({ type: 'read' });
      const objectlink: ObjectLinks = {
        id: resp.id,
        url: resp.url,
        site: linkForm.site,
        found_date: resp.found,
        is_assigned_by_manager: resp.is_assigned_by_manager ?? false,
        audience: obj.audience,
        affinity: obj.affinity,
        contacts: contactsFromLink,
        status: resp.status,
        is_cannibalization: resp.is_cannibalization,
        owner: linkForm.owner,
        template: obj.template,
        assignee: linkForm.owner,
      };
      replaceObjEditForm(
        {
          ...obj,
          site_name: resp.site.name,
          is_older_than_ninety_days: resp.is_older_than_ninety_days,
          is_older_than_thirty_days: resp.is_older_than_thirty_days,
          is_unsubscribed: resp.is_unsubscribed,
          unsubscribed_date: resp.unsubscribed_date,
          added_date: resp.created,
          modified_date: resp.modified,
        },
        objectlink,
        IS_USER_ADMIN.includes(UserContext.profile.role),
      );
    } catch (err) {
      const error = err as any;
      const url = `PUT ${process.env.REACT_APP_API_BASE_URL}/api/v2/links/${
        obj.id ?? 0
      }}`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      handleFeedbackMessage(`Error updating link`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const validateOnEdit = async (value: string, link_id: number) => {
    setLoadingUrl(true);
    let errorUrl: string | undefined;
    try {
      const resp = await validateLink(encodeURIComponent(value), link_id);
      if (!resp.valid) {
        errorUrl = resp.message;
        updateValidUrl(false);
      } else {
        updateValidUrl(true);
        if (IS_USER_ADMIN.includes(UserContext.profile.role))
          errorUrl = resp.message;
        else errorUrl = undefined;
      }
      setLinkFormErrors({ url: errorUrl });

      return resp.valid;
    } catch (err) {
      return true;
    } finally {
      setLoadingUrl(false);
    }
  };

  const validateEditLink = async () => {
    const {
      is_older_than_ninety_days,
      is_older_than_thirty_days,
      is_unsubscribed,
      unsubscribed_date,
      status,
      owner,
      url,
      id,
      modified_date,
      contacts,
    } = editLinkForm;

    const objValid = {
      disabled: false,
      disabledSaveEmail: false,
    };
    const canEditLinks = [
      'administrator',
      'dev admin',
      'strategist',
      'manager',
      'account manager',
    ];

    const objNotValid = {
      disabled: true,
      disabledSaveEmail: true,
    };
    if (IS_USER_ADMIN.includes(UserContext.profile.role)) return objValid;

    const RoleEdit = sendBackObject(UserContext.profile.role, ROLES_LINKS).edit;

    if (!RoleEdit) return objNotValid;

    const instantDisabled = ['project_closed', 'site_closed', 'global_closed'];

    if (status === 'pending') {
      if (is_older_than_ninety_days) {
        updateLinkForm({ owner: UserContext.id });
        return objValid;
      }

      if (linkForm.owner === UserContext.id)
        return { disabled: false, disabledSaveEmail: true };
      return objNotValid;
    }

    if (linkForm.owner === UserContext.id && status === 'to_do')
      return objValid;

    const findContactsGlobalClosed = contacts.find(
      contact => contact.status === 'global_closed',
    );

    if (
      (instantDisabled.includes(status) || findContactsGlobalClosed) &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      setCloseValid(true);
      return objNotValid;
    }

    const dateNow = dayjs();
    const modDate = dayjs(modified_date);
    if (is_unsubscribed && unsubscribed_date) {
      const dateUnsub = dayjs(unsubscribed_date);
      const is15DaysAfter = dateNow.isAfter(dateUnsub.add(15, 'day'));

      if (!is15DaysAfter) {
        objValid.disabledSaveEmail = true;
        handleFeedbackMessage(
          'Save+Email function currently disabled due to a recent Unsubscribe',
          'error',
        );
      }

      if (status === 'contact_not_found' && is15DaysAfter) return objValid;
    }

    if (status === 'contact_not_found' && !is_older_than_ninety_days)
      return objNotValid;

    if (status === 'hold') {
      if (UserContext.id !== owner) return objNotValid;
      const differenceInDays = dateNow.diff(modDate, 'day');
      if (differenceInDays < 60) return objNotValid;
      return objValid;
    }

    // if (
    //   status === 'invalid_contact' &&
    //   !is_older_than_thirty_days &&
    //   !canEditLinks.includes(UserContext.profile.role)
    // )
    //   return objNotValid;

    // if (
    //   status === 'invalid_contact' &&
    //   is_older_than_thirty_days &&
    //   !canEditLinks.includes(UserContext.profile.role)
    // ) {
    //   setAllowOpenNewModalContact(true);
    //   return objNotValid;
    // }

    if (status === 'invalid_contact') {
      const differenceInDays = dateNow.diff(modDate, 'day');
      if (differenceInDays > 7) {
        setAllowOpenNewModalContact(true);
        return objValid;
      }

      return objNotValid;
    }

    if (!is_older_than_ninety_days) {
      if (owner === UserContext.id) {
        const is30DaysAfter = dateNow.isAfter(modDate.add(30, 'day'));
        if (!is30DaysAfter) return objNotValid;
      }

      if (
        owner !== UserContext.id &&
        !canEditLinks.includes(UserContext.profile.role)
      )
        return objNotValid;
    } else if (!IS_USER_ADMIN.includes(UserContext.profile.role)) {
      if (UserContext.id !== owner) {
        const resp = await validateOnEdit(url, id ?? 0);
        if (resp) {
          updateLinkForm({ owner: UserContext.id });
        } else return objNotValid;
      }
    }

    return objValid;
  };

  const handleSiteFromObj = () => {
    const copyArr: BasicArray[] = [...sitesField.sitesOptions];
    if (!sitesField.sitesOptions.find(item => item.id === linkForm.site)) {
      copyArr.push({
        id: ObjEditFormLink.site,
        name: ObjEditFormLink.site_name,
      });
    }
    setSitesWithAll(copyArr);
  };

  const validationOfForm = async () => {
    const objValid = await validateEditLink();
    handleSiteFromObj();
    updateAvailable(objValid);
    if (
      objValid.disabled === false &&
      !linkFormErrors.url &&
      validUrl === false
    )
      updateValidUrl(true);
  };

  const getPromises = async () => {
    if (linkForm.status === 'active') getValidationOfLinkClaim();
    await Promise.all([validationOfForm(), getNotes(), getHistory()]);
    if (formSidebar.openClaim) setOpenDrawer(true);
  };

  const getNotesAndHistory = async () =>
    Promise.all([getNotes(), getHistory()]);

  useEffect(() => {
    if (editLinkForm.id && editLinkForm.id !== 0) {
      cleanAll();
      setTimeout(() => {
        getPromises();
      }, 500);
    }
  }, [editLinkForm]);

  const isExistingContact = (
    contact: ContactsFormLink,
    originalArray: ContactsFormLink[],
  ): boolean => {
    return originalArray.some(orig => {
      if (contact.is_form) {
        return (
          orig.is_form &&
          orig.name === contact.name &&
          orig.form_url === contact.form_url
        );
      }
      return !orig.is_form && orig.email === contact.email;
    });
  };

  const getNewContacts = (
    originalArray: ContactsFormLink[],
    updatedArray: ContactsFormLink[],
  ) => {
    const newContacts: ContactsFormLink[] = [];
    const newIndices: number[] = [];

    updatedArray.forEach((contact, index) => {
      if (!isExistingContact(contact, originalArray)) {
        newContacts.push(contact);
        newIndices.push(index);
      }
    });

    return { newContacts, newIndices };
  };

  const validateContacts = (
    arrayContacts: ContactsFormLink[],
    contactsRelatedInfo: ContactsValuesType[],
  ): boolean => {
    return arrayContacts.every((contact, index) => {
      const isForm = contact.is_form;
      return (
        (isForm
          ? contact.name && contact.form_url
          : contact.email && contactsRelatedInfo[index]?.validEmail === true) &&
        contact.status
      );
    });
  };

  const newContactValidation = () => {
    const originalArray = [...editLinkForm.contacts];
    const updatedArray = [...linkForm.contacts];
    const valueContactsErrors = [...contactsValues];

    const { newContacts, newIndices } = getNewContacts(
      originalArray,
      updatedArray,
    );

    if (newContacts.length > 0) {
      const newContactsValues = newIndices.map(
        index => valueContactsErrors[index],
      );

      return validateContacts(newContacts, newContactsValues);
    }

    const findItemInvalid = updatedArray.find(
      (item, index) =>
        !item.is_form && valueContactsErrors[index].validEmail === false,
    );

    const findItemForm = updatedArray.find((item, index) => item.is_form);

    const findItemValid = updatedArray.find(
      (item, index) =>
        !item.is_form && valueContactsErrors[index].validEmail === true,
    );

    if (findItemInvalid && (findItemForm || findItemValid)) return true;

    return validateContacts(updatedArray, valueContactsErrors);
  };

  const isFormValid = (): boolean => {
    const linkFormFieldsValid = !(
      linkFormErrors.site ||
      linkFormErrors.status ||
      linkFormErrors.owner
    );

    const hasValidValues = !!(
      linkForm.site !== 0 &&
      linkForm.status &&
      linkForm.owner
    );

    const contactsLoading = contactsValues.some(contact => contact.loading);

    const contactsErrors = linkFormErrors.contacts.some(contactError =>
      Object.values(contactError).some(error => error),
    );

    const contactsValid = newContactValidation();

    return (
      linkFormFieldsValid &&
      hasValidValues &&
      validUrl &&
      !loadingUrl &&
      contactsValid &&
      !contactsErrors &&
      !contactsLoading
    );
  };

  // FUNCTIONALITY OF STATUS

  const getStatusFromLink = () => {
    if (linkForm.text_note) {
      const findIndex = linkForm.text_note.indexOf('Note: ');
      if (findIndex !== -1)
        return linkForm.text_note.slice(
          findIndex + 5,
          linkForm.text_note.length,
        );
      return '';
    }
    return '';
  };

  const updateStatus = (note: string, openClaim?: boolean) => {
    if (linkForm.text_note) {
      const linkClosedIndex = linkForm.text_note.indexOf('Link Close');
      const contactClosedIndex = linkForm.text_note.indexOf('Contact Close');
      const noteIndex = linkForm.text_note.indexOf('Note:');
      if (
        (linkClosedIndex !== -1 && contactClosedIndex !== 1) ||
        (linkClosedIndex === -1 && contactClosedIndex !== -1)
      ) {
        const newNote = `${linkForm.text_note.slice(
          contactClosedIndex,
          linkClosedIndex === -1 ? noteIndex : linkClosedIndex,
        )}\nNote: ${note}`;

        updateLinkForm({ text_note: newNote, status: newStatus });
        setOpenStatusModal(false);
        return;
      }
      if (linkClosedIndex !== -1 && contactClosedIndex === -1) {
        const newNote = `Note: ${note}`;
        updateLinkForm({ text_note: newNote, status: newStatus });
        setOpenStatusModal(false);
        return;
      }
      if (linkClosedIndex === -1 && contactClosedIndex === -1) {
        updateLinkForm({ text_note: note, status: newStatus });
        setOpenStatusModal(false);
        return;
      }
    }
    updateLinkForm({ text_note: note, status: newStatus });
    if (openClaim) setOpenDrawer(true);
    setOpenStatusModal(false);
  };

  const avoidStatus = [
    'project_closed',
    'site_closed',
    'global_closed',
    'invalid_contact',
    'unsubscribed',
  ];
  const statusExcluded = {
    project_closed: 'Project Closed',
    site_closed: 'Site Closed',
    global_closed: 'Global Closed',
    invalid_contact: 'Invalid Contact',
    unsubscribed: 'Unsubscribed',
  };

  useEffect(() => {
    if (statusField.statusOptions.length > 0) {
      const removedStatus = statusField.statusOptions.filter(
        item => !avoidStatus.includes(item.value),
      );

      if (!removedStatus.find(item => item.value === linkForm.status)) {
        const key = linkForm.status as keyof typeof statusExcluded;
        removedStatus.push({
          group: 'Enabled',
          value: key,
          name: statusExcluded[key],
        });
      }

      setLinkStatus(
        removedStatus.map((item, index) => {
          return { id: index, name: item.name, value: item.value };
        }),
      );
    }
  }, [linkForm.status, statusField.statusOptions]);

  const updateNewContactAndAllowEdit = (newContact: ContactsFormLink) => {
    updateLinkForm({
      status: 'pending',
      owner: UserContext.id,
      contacts: [...linkForm.contacts, newContact],
    });
    setLinkFormErrors({
      ...linkFormErrors,
      contacts: [...linkFormErrors.contacts, {}],
    });
    addContactValues({
      loading: false,
      validEmail: true,
      message: '',
      previousChanged: false,
      contact: null,
    });
    setDoNotDeleteContact(newContact.email);
    closeNewContact();
  };

  const updateNewContact = (newContact: ContactsFormLink) => {
    updateLinkForm({
      contacts: [...linkForm.contacts, newContact],
    });
    setLinkFormErrors({
      ...linkFormErrors,
      contacts: [...linkFormErrors.contacts, {}],
    });
    addContactValues({
      loading: false,
      validEmail: true,
      message: '',
      previousChanged: false,
      contact: null,
    });
  };

  const closeButtonContacts = () => {
    const indexesOfContacts = linkForm.contacts
      .map((item, index) => {
        return { ...item, id: index };
      })
      .filter(item => item.status === 'open');

    return indexesOfContacts.filter(item => item.email !== '' || item.is_form);
  };

  const updateContactsClosed = (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => {
    const copyContacts = [...linkForm.contacts].map((item, index) => {
      if (contactIds.includes(index)) {
        if (item.is_form && status === 'invalid_contact')
          return {
            ...item,
            status,
            close_reason: close_reason ?? '',
            name: item.name === '' ? 'Invalid Legacy Form Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
          };
        return {
          ...item,
          status,
          close_reason: close_reason ?? '',
          is_default:
            status === 'site_closed' ? false : item.is_default ?? false,
        };
      }
      return item;
    });
    const copyObject = { ...linkForm };
    if (editLinkToo) {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
      if (
        !copyContacts.find(item => item.status === 'open' && !item.is_form) &&
        status === 'invalid_contact'
      )
        copyObject.status = 'invalid_contact';

      if (status === 'global_closed' && linkForm.status !== 'global_closed') {
        copyObject.status = 'global_closed';
        handleFeedbackMessage(
          'Global closed status enforced due to contact status',
          'info',
        );
      }

      copyObject.close_reason = 'All Contacts Closed';
      updateLinkForm(copyObject);
    } else {
      if (status === 'global_closed' && linkForm.status !== 'global_closed') {
        updateLinkForm({
          ...linkForm,
          contacts: copyContacts,
          text_note: newNote,
          status: 'global_closed',
        });
        handleFeedbackMessage(
          'Global closed status enforced due to contact status',
          'info',
        );
        return;
      }
      updateLinkForm({
        ...linkForm,
        contacts: copyContacts,
        text_note: newNote,
      });
    }
  };

  const updateContactsClosedAndSave = (
    close_reason: string,
    contactIds: number[],
    newNote: string,
    status: string,
    editLinkToo?: boolean,
  ) => {
    setLoading(true);
    const copyContacts = [...linkForm.contacts].map((item, index) => {
      if (contactIds.includes(index)) {
        if (item.is_form && status === 'invalid_contact')
          return {
            ...item,
            status,
            close_reason: close_reason ?? '',
            name: item.name === '' ? 'Invalid Legacy Form Contact' : item.name,
            form_url: isURL(item.form_url ?? '')
              ? item.form_url
              : 'https://www.invalidlegacyformcontact.com/',
            form_text:
              item.form_text === ''
                ? 'Invalid Legacy Form Contact'
                : item.form_text,
          };
        return {
          ...item,
          status,
          close_reason: close_reason ?? '',
          is_default:
            status === 'site_closed' ? false : item.is_default ?? false,
        };
      }
      return item;
    });
    const copyObject = { ...linkForm };
    if (editLinkToo) {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
      if (
        !copyContacts.find(item => item.status === 'open' && !item.is_form) &&
        status === 'invalid_contact'
      )
        copyObject.status = 'invalid_contact';
      copyObject.close_reason = 'All Contacts Closed';
    } else {
      copyObject.contacts = copyContacts;
      copyObject.text_note = newNote;
    }

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);

    updateLinkForm(copyObject);

    saveLink(undefined, copyObject);
  };

  const updateLinkClosed = (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => {
    updateLinkForm({
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    });

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);
  };

  const updateLinkClosedAndSave = (
    close_reason: string,
    status: string,
    newNote: string,
    relevance_close?: boolean,
  ) => {
    setLoading(true);

    const copyObj = {
      ...linkForm,
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    };

    if (!IS_USER_ADMIN.includes(UserContext.profile.role))
      setDisabledStatus(true);

    updateLinkForm({
      relevance_close: relevance_close ?? false,
      close_reason: close_reason ?? '',
      status,
      text_note: newNote,
    });

    saveLink(undefined, copyObj);
  };

  const handleEmailSent = () => {
    if (linkForm.contacts.filter(item => item.is_default).length === 0) {
      handleFeedbackMessage(
        'At least one contact included in template is required',
        'error',
      );

      return;
    }
    if (linkForm.contacts.filter(item => item.is_default).length > 1) {
      handleFeedbackMessage(
        'You can only have one contact included in template',
        'error',
      );

      return;
    }
    saveLink(true);
  };

  const checkHold = () => {
    if (linkForm.status !== 'hold') return false;
    const dateNow = dayjs();
    const modDate = dayjs(editLinkForm.modified_date);
    const differenceInDays = dateNow.diff(modDate, 'day');
    if (differenceInDays < 60) return true;
    return false;
  };

  const findSiteForm = () => {
    // if (IS_USER_ADMIN.includes(UserContext.profile.role)) return true;
    const siteInfo = sitesField.sitesOptions.find(
      item => item.id === linkForm.site,
    );
    if (siteInfo) return siteInfo.form_contacts_allowed;

    return false;
  };

  const isCloseBlock = () => {
    if (!IS_USER_ADMIN.includes(UserContext.profile.role)) {
      if (linkForm.owner === UserContext.id) return false;

      return true;
    }

    return false;
  };

  return (
    <>
      {formSidebar.type === 'read' && tabView === 1 && (
        <FlexBox justifyContent="end" mb={1.5}>
          <FormButtons
            loading={false}
            variant="contained"
            sx={{
              background: '#84D0F0',
              color: 'black',
              '&:hover': {
                backgroundColor: '#84D0F0',
              },
            }}
            disabled={
              !linkForm.id ||
              linkForm.id === 0 ||
              !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
              loading ||
              loadingForm
            }
            onClick={() => {
              updateSidebar({ type: 'claim' });
              updateClaimId(linkForm.id ?? 0);
            }}
          >
            Claim Link
          </FormButtons>
        </FlexBox>
      )}
      {(formSidebar.type === 'read' || formSidebar.type === 'load') &&
        tabView !== 1 && (
          <FlexBox justifyContent="end" mb={1.5}>
            <FormButtons
              loading={loading}
              variant="contained"
              sx={{
                background: '#84D0F0',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#84D0F0',
                },
              }}
              disabled={
                !linkForm.id ||
                linkForm.id === 0 ||
                !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
                loading ||
                loadingForm
              }
              onClick={() => {
                if (
                  validationOfGlobalClosed() &&
                  editLinkForm.status !== 'global_closed'
                ) {
                  updateLinkForm({ status: 'global_closed' });
                  handleFeedbackMessage(
                    'Global closed status enforced due to contact status',
                    'info',
                  );
                }
                updateSidebar({ type: 'edit' });
                if (
                  !loadingForm &&
                  editLinkForm.id &&
                  editLinkForm.id !== 0 &&
                  !available.disabled &&
                  editLinkForm.openNewContactModal
                )
                  openNewContactModal();
              }}
            >
              Edit Link
            </FormButtons>
          </FlexBox>
        )}
      {formSidebar.type === 'edit' && (
        <FlexBoxBetween marginBottom={1.5}>
          <>
            <FlexBox>
              {!isOpenModalEmailOpen && (
                <>
                  <MainButton
                    onClick={() => closeWarning(true)}
                    disabled={loading}
                    variant="contained"
                    sx={{
                      marginRight: 1,

                      fontSize: 12,
                      backgroundColor: '#FFFFFF',
                      color: 'black',
                      border: '1px solid grey',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                      },
                    }}
                  >
                    Cancel
                  </MainButton>
                  <StatusClosed
                    updateNewContact={updateNewContact}
                    disabled={
                      closeValid
                        ? isCloseBlock()
                        : isCloseBlock() || !isFormValid()
                    }
                    allContacts={linkForm.contacts}
                    contactsValidations={contactsValues}
                    site={linkForm.site}
                    contacts={closeButtonContacts()}
                    updateContactsClosed={updateContactsClosed}
                    updateLinkClosed={updateLinkClosed}
                    updateLinkClosedAndSave={updateLinkClosedAndSave}
                    updateContactsClosedAndSave={updateContactsClosedAndSave}
                    disabledSave={
                      closeValid
                        ? isCloseBlock()
                        : isCloseBlock() || !isFormValid()
                    }
                  />
                </>
              )}
            </FlexBox>
            <FlexBox>
              <MainButton
                loading={loading}
                variant="contained"
                sx={{
                  marginX: 1,
                  background: '#BAF372',
                  fontSize: 12,
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#BAF372',
                  },
                }}
                disabled={
                  !isFormValid() ||
                  checkStatusReadOnly() ||
                  isLoadingContext.loading ||
                  linkForm.id === 0 ||
                  !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
                  loading
                }
                onClick={() => saveLink()}
              >
                Save
              </MainButton>
              {!isOpenModalEmailOpen && (
                <FormButtons
                  variant="contained"
                  disabled={
                    !isFormValid() ||
                    !isBlock() ||
                    isLoadingContext.loading ||
                    available.disabledSaveEmail ||
                    loading ||
                    checkHold() ||
                    linkForm.status === 'invalid_contact' ||
                    linkForm.id === 0 ||
                    !sendBackObject(UserContext.profile.role, ROLES_LINKS)
                      .edit ||
                    linkForm.contacts.filter(item => item.is_default).length ===
                      0 ||
                    linkForm.contacts.filter(
                      item => item.status !== 'invalid_contact',
                    ).length === 0
                  }
                  loading={false}
                  sx={{
                    fontSize: 12,
                    backgroundColor: '#84D0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#84D0F0',
                    },
                  }}
                  onClick={() => handleEmailSent()}
                >
                  Save + Email
                </FormButtons>
              )}
            </FlexBox>
          </>
        </FlexBoxBetween>
      )}
      <Divider />

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: 1,
        }}
      >
        <Box className="select-tabs">
          <Select
            value={tabValue}
            name="tabs-value"
            id="tabs-value"
            size="small"
            disabled={false}
            displayEmpty
            fullWidth
            onChange={e => setTabValue(e.target.value as number)}
            sx={{ fontSize: 12, mb: 1 }}
            placeholder="Select Tab"
            IconComponent={KeyboardArrowDownIcon}
            input={<SelectInput />}
          >
            <MenuItem value="" disabled>
              None
            </MenuItem>
            {tabs.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="tabs-responsive">
          <TabParent
            valueTab={tabValue}
            smaller
            setTab={setTabValue}
            tabs={tabs}
            style
            center
          />
        </Box>
      </Box>
      <Box display={tabValue === 0 ? '' : 'none'}>
        <BoxAddSettings title="Link" pl={0} pr={0} mb={1} pt={1.5}>
          <div className="form-grids">
            {inputsLinkSection.map(input => (
              <div
                key={input.id}
                className={input.fullSpace ? 'whole-grid' : ''}
              >
                {input.body}
              </div>
            ))}
          </div>
          <Box mt={5}>
            <FormControlLabel
              label="Assigned by Manager"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 13,
                  color: '#000000',
                },
              }}
              control={
                <DefaultCheckbox
                  disabled={
                    !IS_USER_ADMIN.includes(UserContext.profile.role) ||
                    isLoadingContext.loading ||
                    loadingUrl ||
                    loadingForm ||
                    available.disabled ||
                    formSidebar.type === 'read'
                  }
                  checked={linkForm.is_assigned_by_manager ?? false}
                  onChange={e =>
                    updateLinkForm({ is_assigned_by_manager: e.target.checked })
                  }
                />
              }
            />
          </Box>
          {(editLinkForm.added_date !== '' ||
            editLinkForm.modified_date !== '') && (
            <div className="form-grids" style={{ marginTop: '30px' }}>
              <Box>
                <InputLabel
                  htmlFor="created_date"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  Created Date
                </InputLabel>
                <DateInput
                  value={
                    pendingListObject
                      ? pendingListObject.created
                      : editLinkForm.added_date
                  }
                  fullWidth
                  disabled
                  handleChangeDate={(date: string) => {}}
                />
              </Box>

              <Box>
                <InputLabel
                  htmlFor="modified_date"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  Updated Date
                </InputLabel>
                <DateInput
                  value={
                    pendingListObject
                      ? pendingListObject.modified
                      : editLinkForm.modified_date
                  }
                  fullWidth
                  disabled
                  handleChangeDate={(date: string) => {}}
                />
              </Box>
            </div>
          )}
        </BoxAddSettings>
      </Box>
      <Box mt={2} display={tabValue === 1 ? '' : 'none'}>
        {linkForm.contacts.length > 0 && (
          <ContactsEdit
            handleFeedbackMessage={handleFeedbackMessage}
            allowOpenNewModalContact={allowOpenNewModalContact}
            openNewContactModal={openNewContactModal}
            doNotDeleteContact={doNotDeleteContact}
            form_contacts_allowed={findSiteForm()}
            getNotes={getNotes}
          />
        )}
      </Box>
      <Box display={tabValue === 2 ? '' : 'none'}>
        <LinkNotes
          handleFeedbackMessage={handleFeedbackMessage}
          loadingNotes={loadingNotes}
          notes={notes}
          getNotes={getNotes}
        />
      </Box>
      <Box display={tabValue === 3 ? '' : 'none'}>
        <LinkHistory loadingHistory={loadingHistory} history={history} />
      </Box>

      <StatusModal
        open={openStatusModal}
        closeModal={setOpenStatusModal}
        linkNote={getStatusFromLink()}
        updateStatus={updateStatus}
        newValue={newStatus}
        validUpdate={
          !isFormValid() ||
          checkStatusReadOnly() ||
          linkForm.id === 0 ||
          !sendBackObject(UserContext.profile.role, ROLES_LINKS).edit ||
          loading
        }
      />

      <AddNewContactModal
        contacts={linkForm.contacts.filter(item => item.email !== '')}
        open={openNewContact}
        updateNewContactAndAllowEdit={updateNewContactAndAllowEdit}
        closeModal={closeNewContact}
        site={linkForm.site}
      />

      <LinkEmailSave
        open={openModalEmail}
        closeModal={() => handleOpenModalEmail(false)}
        contacts={emailSave.contacts}
        site={emailSave.site}
        url={emailSave.url}
        getNotesAndHistory={getNotesAndHistory}
        linkId={emailSave.linkId}
        handleFeedbackMessage={handleFeedbackMessage}
        userEmail={emailSave.userEmail}
        saveLink={saveLink}
      />

      <FormContactModal
        open={openFormContact}
        closeModal={() => handleOpenFormContact(false)}
        contactForm={contactForm}
        site={siteForm}
        saveLink={saveLink}
        handleFeedbackMessage={handleFeedbackMessage}
      />

      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        <LinkFormClaim
          setOpenDrawer={setOpenDrawer}
          handleMessage={handleFeedbackMessage}
          disabledAll={UserContext.profile.role === 'trainer'}
          claimLink={editLinkForm.id ?? 0}
          saveLink={saveLink}
          sites={sitesField.sitesOptions}
          warningModal={closeWarning}
        />
      </MainDrawer>

      <AddWarningLink
        open={openWarning}
        closeModal={closeWarning}
        body="If you decide to continue, your updates will not be saved"
        continueModal={() => {
          closeWarning(false);
          setOpenDrawer(false);
          setTabValue(0);
          const objCopy: ObjectLinks = { ...editLinkForm };

          replaceObjEditForm(
            editLinkForm,
            objCopy,
            IS_USER_ADMIN.includes(UserContext.profile.role),
          );
          updateSidebar({ type: 'read' });
        }}
      />
    </>
  );
};

export default MainForm;
