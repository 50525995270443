/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FlexBox } from '../../../components/tableItems';
import { MainButton } from '../../../components/buttons';
import { SelectTime } from '../../../components/inputs';
import WarningClose from './WarningClose';

const calendarIcon = () => {
  return (
    <CalendarMonthIcon
      sx={{
        fontSize: '25px',
        color: 'rgba(0, 0, 0, 0.2)',
        '&:hover': { color: 'rgba(0, 0, 0, 0.4)' },
        marginRight: 1,
      }}
    />
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface ScheduleSendProps {
  open: boolean;
  closeModal: (value: boolean) => void;
  send: (scheduled_mail?: boolean, cancel_mail?: boolean) => Promise<void>;
  selectedDate: string;
  updateSelectedDate: (value: string) => void;
  selectedTimeBlock: string;
  loading: boolean;
  updateSelectedTime: (value: string) => void;
  cancelEmail?: boolean;
  timeFrames: {
    block_title: string;
    block_start: string;
    scheduled_messages: number;
    is_full: false;
  }[];
}

const ScheduleSend: React.FC<ScheduleSendProps> = ({
  open,
  closeModal,
  send,
  selectedDate,
  selectedTimeBlock,
  updateSelectedDate,
  updateSelectedTime,
  loading,
  timeFrames,
  cancelEmail,
}) => {
  const [openWarning, setOpenWarning] = useState(false);
  const closeWarning = () => setOpenWarning(false);

  const continueClosing = async () => {
    await send(undefined, true);
    closeWarning();
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <>
            <Box justifyContent="end" display="flex">
              <IconButton
                onClick={() => closeModal(false)}
                sx={{ fontSize: 17 }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Typography
              fontWeight={700}
              fontSize={17}
              textAlign="center"
              textTransform="capitalize"
            >
              Schedule Send
            </Typography>
            <Box marginTop={2}>
              <Typography fontWeight={500} mb={1}>
                Select a Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={selectedDate}
                  minDate={dayjs()}
                  disabled={false}
                  components={{ OpenPickerIcon: calendarIcon }}
                  InputProps={{
                    sx: {
                      fontSize: '13px',
                      width: '100%',
                      pl: 2,
                    },
                    readOnly: true,
                  }}
                  renderInput={params => (
                    <Box width="100%">
                      <TextField
                        fullWidth
                        onKeyDown={e => e.preventDefault()}
                        {...params}
                        sx={{
                          '& .MuiInputBase-input': {
                            paddingY: '15px',
                            paddingRight: '5px',
                            paddingLeft: '7px',
                            fontSize: '15px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: '1px solid #D7D7D7',
                              borderRadius: '6px',
                            },
                            '&:hover fieldset': {
                              borderColor: '#ABABAB',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#ABABAB',
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                  onChange={newValue =>
                    updateSelectedDate(dayjs(newValue).format('MM/DD/YYYY'))
                  }
                />
              </LocalizationProvider>
              <Typography fontWeight={500} mt={3} mb={1}>
                Select a Time Frame
              </Typography>

              <Select
                value={selectedTimeBlock}
                name="bulk"
                id="bulk"
                disabled={timeFrames.length === 0}
                displayEmpty
                placeholder="Select time frame"
                fullWidth
                onChange={e => {
                  updateSelectedTime(e.target.value);
                }}
                sx={{ fontSize: 12 }}
                IconComponent={KeyboardArrowDownIcon}
                size="small"
                input={<SelectTime />}
              >
                <MenuItem value="" disabled sx={{ fontSize: 12 }}>
                  Time Frame
                </MenuItem>

                {timeFrames.length > 0 &&
                  timeFrames.map((block, index) => (
                    <MenuItem
                      key={index}
                      value={block.block_start}
                      disabled={block.is_full}
                    >
                      {block.block_title}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            <Box marginTop={3.5}>
              <FlexBox marginTop={3} justifyContent="center">
                {cancelEmail && (
                  <MainButton
                    onClick={() => setOpenWarning(true)}
                    variant="contained"
                    disabled={loading}
                    sx={{
                      backgroundColor: '#F0F0F0',
                      color: 'black',
                      marginRight: 1,
                      '&:hover': {
                        backgroundColor: '#F2F2F2',
                      },
                    }}
                  >
                    Cancel Email
                  </MainButton>
                )}
                {!cancelEmail && (
                  <MainButton
                    onClick={() => closeModal(false)}
                    disabled={loading}
                    variant="contained"
                    sx={{
                      backgroundColor: '#F0F0F0',
                      color: 'black',
                      marginRight: 1,
                      '&:hover': {
                        backgroundColor: '#F2F2F2',
                      },
                    }}
                  >
                    Close
                  </MainButton>
                )}

                <MainButton
                  variant="contained"
                  onClick={() => send(true)}
                  disabled={
                    selectedDate === '' || selectedTimeBlock === '' || loading
                  }
                  loading={loading}
                  sx={{ marginLeft: 1 }}
                  endIcon={<SendIcon />}
                >
                  Schedule Send
                </MainButton>
              </FlexBox>
            </Box>
          </>
        </Box>
      </Modal>

      <WarningClose
        open={openWarning}
        closeModal={closeWarning}
        continueModal={continueClosing}
        loading={loading}
        title="Cancel Email"
        body="Are you sure you want to cancel this email?"
        buttonText="Confirm"
      />
    </>
  );
};

export default ScheduleSend;
